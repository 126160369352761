import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Dialog } from 'react-native-paper';
import { Steps } from './NpsSurvey';
import { translate } from '../../services/service-translate';

interface StepProps {
    setStep: React.Dispatch<React.SetStateAction<Steps>>;
    sendScore: () => Promise<void>;
}

export default function StepOne({ setStep, sendScore }: StepProps) {
    return (
        <>
            <Dialog.Title style={styles.title} onPressIn={{}} onPressOut={{}}>{translate('NPS_SURVEY_TITLE_STEP_1')}</Dialog.Title>
            <Dialog.Content style={styles.content}>
                <Text style={styles.subtitle}>{translate('NPS_SURVEY_SUBTITLE_STEP_1')}</Text>
            </Dialog.Content>
            <Dialog.Actions style={styles.actions}>
                <TouchableOpacity onPress={() => setStep(2)}>
                    <View style={styles.buttons}>
                        <Text style={styles.textButton}>{translate('NPS_SURVEY_PARTICIPATE')}</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={sendScore}>
                    <View style={styles.buttons}>
                        <Text style={styles.textButton}>{translate('NPS_SURVEY_REFUSE_PARTICIPATION')}</Text>
                    </View>
                </TouchableOpacity>
            </Dialog.Actions>
        </>
    );
}
const styles = StyleSheet.create({
    title: {
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        textAlign: 'center',
        color: '#0071bc',
        fontWeight: 'bold'
    },
    content: {
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
    },
    subtitle: {
        color: "#a5a5a5"
    },
    actions: {
        width: '100%',
        padding: 10,
        justifyContent: 'space-evenly'
    },
    buttons: {
        padding: 15,
        borderRadius: 10,
        backgroundColor: '#0071bc',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.29,
        shadowRadius: 4.65,

        elevation: 7,
    },
    textButton: {
        color: '#fff',
        fontWeight: 'bold'
    }
});
