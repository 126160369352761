import React, { useEffect, useState } from 'react';
import { translate } from '../../services/service-translate';
import {
    StyleSheet,
    View,
    Text,
    TextInput,
    TouchableOpacity,
    Dimensions,
} from "react-native";
import { List } from "react-native-paper";
import { CameraSteps } from '../../pages/crm/camera/Register';
import '../../styles/tooltips.css';
import { Image } from 'react-native-elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface CamUniqueProps {
    setStep: React.Dispatch<React.SetStateAction<CameraSteps>>;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    setImage: React.Dispatch<React.SetStateAction<string>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    image: string;
}

const headerSize = 60;
const footerSize = 30;
export default function RtspImage({ setTitle, title, setStep, image, setImage, setLoading }: CamUniqueProps) {
    const insets = useSafeAreaInsets();
    const [viewHeight, setViewHeight] = useState(0);

    useEffect(() => {
        const { height } = Dimensions.get('window');
        setViewHeight(height - headerSize - insets.bottom - insets.top - 220);
    }, [insets]);

    function goBack() {
        setLoading(false);
        setStep('rtsp_link');
        setImage('');
    }

    return (
        <>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                        onPress={() => goBack()}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("cameraRTSP")}</Text>
                    </View>
                </View>
            </View>
            {image !== '' &&
                <>
                    {setLoading(false)}
                    <Image source={{ uri: image }} style={[styles.imageRtsp, { maxHeight: viewHeight }]} />
                </>
            }
            <View style={styles.viewInputs}>
                <Text style={styles.labelText}>{translate("camName")}</Text>
                <TextInput
                    style={styles.titleInput}
                    onChangeText={(value) => setTitle(value)}
                    value={title}
                />
            </View>
            <View style={styles.footer}>
                {title !== '' && image !== '' ?
                    <TouchableOpacity style={styles.buttonNext} onPress={() => setStep('map')}>
                        <Text style={styles.buttonNextText}>{translate('continue')}</Text>
                    </TouchableOpacity>
                    :
                    <TouchableOpacity style={styles.invalidButtonNext} disabled={true}>
                        <Text style={styles.buttonNextText}>{translate('continue')}</Text>
                    </TouchableOpacity>
                }
            </View>
        </>
    );
}
const styles = StyleSheet.create({
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    viewInputs: {
        flexDirection: 'column',
        width: '100%',
    },
    titleInput: {
        backgroundColor: '#fff',
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
    },
    labelText: {
        marginTop: 25,
        marginLeft: 10,
        fontSize: 16,
    },
    footer: {
        position: "absolute",
        bottom: 25,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    buttonNextText: {
        margin: "auto",
        fontSize: 12,
        color: "#FFF"
    },
    buttonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "#0071bc",
    },
    invalidButtonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "rgba(0, 113, 188, 0.5)",
    },
    imageRtsp: {
        marginHorizontal: 'auto',
        marginTop: 20,
        height: 'auto',
        width: '98%',
        aspectRatio: 135 / 76,
        resizeMode: 'stretch'
    },
});

export { RtspImage };
