import React, { useState } from 'react';
import { translate } from '../../services/service-translate';
import {
    StyleSheet,
    View,
    Text,
    TextInput,
    TouchableOpacity,
} from "react-native";
import { List } from "react-native-paper";
import { CameraBrands, CameraSteps, Model } from '../../pages/crm/camera/Register';
import { ClientError, platformAPI, UnexpectedError } from '../../services/platform-api';
import BarcodeScanner from './bar-code-scanner';
import '../../styles/tooltips.css';
import Toast from 'react-native-toast-message';

interface CamUniqueProps {
    setSerialNumber: React.Dispatch<React.SetStateAction<string>>;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    setStep: React.Dispatch<React.SetStateAction<CameraSteps>>;
    setBrand: React.Dispatch<React.SetStateAction<CameraBrands>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    brand: CameraBrands;
    serialNumber: string;
    model: Model | undefined;
    title: string;
}

const headerSize = 60;
const footerSize = 30;
export default function HikvisionCamSerial({ model, setSerialNumber, setStep, setTitle, brand, serialNumber, setLoading, title, setBrand, loading }: CamUniqueProps) {

    const [stopStream, setStopStream] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    async function validCamera() {
        setLoading(true);
        try {
            await platformAPI.validP2P({
                factory: brand,
                rtsp: 'RTMP',
                id: serialNumber,
            });
            setLoading(false);
            return setStep('map');
        } catch (error) {
            setLoading(false);
            console.error(error);
            if (error instanceof ClientError) {
                Toast.show({
                    type: 'error',
                    text1: translate(error.message),
                });
            } else if (error instanceof UnexpectedError) {
                Toast.show({
                    type: 'error',
                    text1: translate("UNEXPECTED_ERROR"),
                });
            }
        }
    }

    if (!model) {
        return (<>{setStep('model')}{setLoading(false)}</>);
    }

    function goBack() {
        setStep('model');
        setLoading(false);
        setTitle('');
        setSerialNumber('');
        setBrand('HIKVISION');
    }

    return (
        <>
            <BarcodeScanner
                maxLength={model.maxLength}
                setData={setSerialNumber}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                setStopStream={setStopStream}
                stopStream={stopStream}
                loading={loading}
                setLoading={setLoading}
                model={model}
            />
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                        onPress={() => goBack()}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("cameraHik")}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.viewInputs}>
                <Text style={styles.labelText}>{translate("camName")}</Text>
                <TextInput
                    style={styles.titleInput}
                    onChangeText={(value) => setTitle(value)}
                    value={title}
                />
                <Text style={styles.labelText}>{translate("serialNumber")}</Text>
                <View style={styles.serialNumber}>
                    <TextInput
                        style={styles.textInputs}
                        onChangeText={(value) => setSerialNumber(value.toUpperCase())}
                        value={serialNumber}
                        maxLength={model.maxLength}
                    />
                    <TouchableOpacity
                        onPress={() => {
                            setModalVisible(true);
                            setLoading(true);
                        }}>
                        <List.Icon
                            style={styles.qrCode}
                            icon="qrcode"
                            color="#000"
                        />
                    </TouchableOpacity>
                </View>
            </View>
            <View style={styles.footer}>
                {title && serialNumber !== '' ?
                    <TouchableOpacity style={styles.buttonNext} onPress={() => validCamera()}>
                        <Text style={styles.buttonNextText}>{translate('continue')}</Text>
                    </TouchableOpacity>
                    :
                    <TouchableOpacity style={styles.invalidButtonNext} disabled={true}>
                        <Text style={styles.buttonNextText}>{translate('continue')}</Text>
                    </TouchableOpacity>
                }
            </View>
        </>
    );
}
const styles = StyleSheet.create({

    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    title: {
        textTransform: "capitalize",
    },
    viewInputs: {
        height: 300,
        flexDirection: 'column',
        width: '100%',
    },
    titleInput: {
        backgroundColor: '#fff',
        width: "95%",
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
    },
    labelText: {
        marginTop: 25,
        marginLeft: 10,
        fontSize: 16,
    },
    footer: {
        position: "absolute",
        bottom: 25,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    buttonNextText: {
        margin: "auto",
        fontSize: 12,
        color: "#FFF"
    },
    buttonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "#0071bc",
    },
    invalidButtonNext: {
        borderRadius: 4,
        height: 45,
        width: "95%",
        margin: "auto",
        justifyContent: "flex-end",
        backgroundColor: "rgba(0, 113, 188, 0.5)",
    },
    textInputs: {
        backgroundColor: '#fff',
        width: "100%",
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
    },
    serialNumber: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '95%',
        marginLeft: 10,
        marginBottom: 10,
    },
    qrCode: {
        alignItems: 'center',
        margin: 0,
        height: 45,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        padding: 2,
        marginLeft: 10,
    },
    disaledQrCode: {
        alignItems: 'center',
        margin: 0,
        height: 45,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        padding: 2,
        backgroundColor: "rgba(0, 113, 188, 0.2)",
        marginLeft: 10,
    },
});

export { HikvisionCamSerial };
