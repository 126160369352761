import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, TextInput, ActivityIndicator } from "react-native";
import { List } from "react-native-paper";
import { translate } from "../../../services/service-translate";
import {
    UserData, Camera, CameraConfig, CameraEditProps, ClientError, invalidBearerToken, platformAPI, UnexpectedError
} from "../../../services/platform-api";
import { Switch } from 'react-native-elements';
import DefaultPageContainer from "../../../components/DefaultPageContainer";
import { TextInputMask } from "react-native-masked-text";
import Toast from "react-native-toast-message";
import DropDownPicker from "react-native-dropdown-picker";

interface CrmCameraEditProps {
    navigation: Navigation;
    route: {
        params: {
            id: number;
        };
    };
    authenticatedUser?: UserData;
}

const headerSize = 60;

export default function CrmCameraEdit({ navigation, route, authenticatedUser }: CrmCameraEditProps) {
    const param = route.params;
    const [camera, setCamera] = useState<Camera>();
    const [cameraConfig, setCameraConfig] = useState<CameraConfig>();
    const [dropDown, setDropDown] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [invalidRtsp, setInvalidRtsp] = useState(false);
    const [invalidTitle, setInvalidTitle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState<CameraEditProps>({
        title: '',
        rtsp_address: '',
        override: false,
        port: 80,
        username: '',
        password: '',
        type: "UNKNOWN",
        pub: false,
        priv: false,
    });

    function goBack() {
        resetStates();
        navigation.navigate("CrmCameraList");
    }

    useEffect(() => {
        document.body.style.position = "fixed";
    }, []);

    useEffect(() => {
        checkPermissions();
    }, [authenticatedUser]);

    async function updateCameraConfig() {
        setLoading(true);
        try {
            await platformAPI.updateCameraConfig(param.id, inputs);
            setLoading(false);
            goBack();
        } catch (err) {
            setLoading(false);
            if (err instanceof ClientError) {
                Toast.show({
                    type: 'error',
                    text1: translate(err.message),
                });
            }
            if (err instanceof UnexpectedError) {
                Toast.show({
                    type: 'error',
                    text1: translate("UNEXPECTED_ERROR"),
                });
            }
            console.error(err);
        }
    }

    async function loadCrmCamera(camera: Camera) {
        try {
            const camConfig = await platformAPI.getCameraConfig(param.id);

            setCamera(camera);
            setCameraConfig(camConfig);
            setInputs({
                title: camera.title,
                rtsp_address: camConfig.rtsp_address,
                override: false,
                username: camConfig.camera_username || null,
                password: camConfig.camera_password || null,
                port: camConfig.port || 80,
                type: camera.type,
                pub: camConfig.public,
                priv: camConfig.private
            });
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const camera = await platformAPI.getCameraForUpdate(param.id);
            const wl = await platformAPI.getWhitelabel();

            if ((!authenticatedUser.permissions.can_see_camera_options && authenticatedUser.id != camera.UserId) || wl.id === 1) {
                return navigation.navigate("Camera", { screen: "More" });
            }

            loadCrmCamera(camera);

            if (!authenticatedUser.permissions.can_edit_camera_options && authenticatedUser.id !== camera.UserId) {
                return setReadOnly(true);
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    function handleValidRtsp(val: string) {
        if (camera?.factory == "UNKNOWN" && !val) {
            return setInvalidRtsp(true);
        }
        setInvalidRtsp(false);
    }

    function handleValidTitle(val: string) {
        if (!val) {
            return setInvalidTitle(true);
        }
        setInvalidTitle(false);
    }

    function resetStates() {
        setInvalidRtsp(false);
        setInvalidTitle(false);
        setDirty(false);
        setInputs({
            title: '',
            rtsp_address: '',
            override: false,
            port: 80,
            username: '',
            password: '',
            type: "UNKNOWN",
            pub: false,
            priv: false,
        });
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff" />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("cameraEditTitle")}</Text>
                    </View>
                </View>
                {!readOnly && !loading ?
                    <View style={styles.registerView}>
                        {dirty && (!invalidRtsp) && !invalidTitle ?
                            <TouchableOpacity onPress={() => updateCameraConfig()}>
                                <Text style={styles.register}>{translate("save")}</Text>
                            </TouchableOpacity> :
                            <Text style={styles.registerInvalid}>{translate("save")}</Text>}
                    </View>
                    : null}
            </View>
            {loading === true ? (
                <View style={styles.loadingView}>
                    <Text style={styles.loadingText}>{translate("updatingCamera")}</Text>
                    <ActivityIndicator
                        animating={loading}
                        style={styles.loading}
                        size="large"
                        color="#0071bc"
                    >
                    </ActivityIndicator>
                </View>
            ) :
                <ScrollView
                    bounces={false}
                    style={[styles.scrollView, { height: 1 }]}
                >
                    <View style={styles.infosView}>
                        <View style={styles.viewInputs}>
                            <Text style={[styles.labelText, { marginTop: 18 }]}>{translate('cameraTitleLabel')}</Text>
                            <TextInput
                                value={inputs.title}
                                onChangeText={(text) => {
                                    setInputs({ ...inputs, title: text }); setDirty(true); handleValidTitle(text);
                                }}
                                style={!readOnly ?
                                    invalidTitle ? styles.invalidTextInputs : styles.textInputs
                                    : styles.disabledTextInputs}
                                editable={!readOnly} />
                            <Text style={[styles.labelText, { marginTop: 18 }]}>{translate('cameraTypeLabel')}</Text>
                            <DropDownPicker
                                open={dropDown}
                                value={inputs.type}
                                setValue={(value) => {
                                    setInputs({ ...inputs, type: value(inputs.type) });
                                    setDirty(true);
                                }}
                                items={[
                                    { label: translate("UNKNOWN"), value: 'UNKNOWN' },
                                    { label: translate("INTERNAL"), value: 'INTERNAL' },
                                    { label: translate("EXTERNAL"), value: 'EXTERNAL' }
                                ]}
                                style={styles.dropDown}
                                placeholder={translate("UNKNOWN")}
                                dropDownContainerStyle={styles.dropDownContainerStyle}
                                listItemContainerStyle={styles.drops}
                                selectedItemContainerStyle={{ backgroundColor: '#F3F4F5'}}
                                showTickIcon={false}
                                setOpen={setDropDown}
                            />
                            {cameraConfig?.rtsp_address != "" ?
                                <>
                                    <Text style={[styles.labelText, { marginTop: 18 }]}>{translate('cameraRtspLabel')}</Text>
                                    <TextInput
                                        style={!readOnly ?
                                            invalidRtsp ? styles.invalidTextInputs : styles.textInputs
                                            : styles.disabledTextInputs}
                                        editable={!readOnly}
                                        value={inputs.rtsp_address}
                                        onChangeText={(text) => {
                                            setInputs({ ...inputs, rtsp_address: text }); setDirty(true); handleValidRtsp(text);
                                        }} />
                                </>
                                :
                                null}
                            {camera?.factory === "INTELBRAS" ?
                                <>
                                    <View style={styles.switchItem}>
                                        <Text style={styles.switchItemText}>{translate("overrideSwitchLabel")}</Text>
                                        <Switch
                                            trackColor={{ false: '#767577', true: '#ADBCF9' }}
                                            color={inputs.override ? '#5a7bf4' : '#f4f3f4'}
                                            onValueChange={() => {
                                                setInputs({ ...inputs, override: !inputs.override }); setDirty(true);
                                            }}
                                            value={inputs.override}
                                            disabled={readOnly} />
                                    </View>
                                    {inputs.override ?
                                        <View>
                                            <Text style={[styles.labelText, { marginTop: 18 }]}>{translate('port')}</Text>
                                            <TextInputMask
                                                style={!readOnly ? styles.textInputs : styles.disabledTextInputs}
                                                editable={!readOnly}
                                                value={String(inputs.port)}
                                                type='only-numbers'
                                                onChangeText={(text) => {
                                                    setInputs({ ...inputs, port: Number(text || '0') });
                                                    setDirty(true);
                                                }}
                                                maxLength={6}
                                                keyboardType='numeric'
                                            />
                                            <Text style={[styles.labelText, { marginTop: 18 }]}>{translate('user')}</Text>
                                            <TextInput
                                                style={!readOnly ? styles.textInputs : styles.disabledTextInputs}
                                                editable={!readOnly}
                                                value={inputs.username || ''}
                                                onChangeText={(text) => {
                                                    setInputs({ ...inputs, username: text }); setDirty(true);
                                                }} />
                                            <Text style={[styles.labelText, { marginTop: 18 }]}>{translate('passwordLabel')}</Text>
                                            <TextInput
                                                style={[!readOnly ? styles.textInputs : styles.disabledTextInputs, { marginBottom: 20 }]}
                                                editable={!readOnly}
                                                value={inputs.password || ''}
                                                secureTextEntry={true}
                                                onChangeText={(text) => {
                                                    setInputs({ ...inputs, password: text }); setDirty(true);
                                                }} />
                                        </View>
                                        :
                                        null}
                                </>
                                :
                                null}
                            <Text style={[styles.labelText, { marginTop: 18 }]}>{translate('cameraPublicLabel')}</Text>
                            <Text style={[styles.subtitleText, { marginTop: 5 }]}>{translate("cameraPublicSubtitle")}</Text>
                            <Switch
                                trackColor={{ false: '#767577', true: '#ADBCF9' }}
                                color={inputs.pub ? '#5a7bf4' : '#f4f3f4'}
                                style={{ marginTop: 5 }}
                                onValueChange={() => {
                                    setInputs({ ...inputs, pub: !inputs.pub, priv: !inputs.pub ? false : !inputs.pub }); setDirty(true);
                                }}
                                value={inputs.pub}
                                disabled={readOnly} />
                            <Text style={[styles.labelText, { marginTop: 25 }]}>{translate('cameraPrivateLabel')}</Text>
                            <Text style={[styles.subtitleText, { marginTop: 5 }]}>{translate("cameraPrivateSubtitle")}</Text>
                            <Switch
                                trackColor={{ false: '#767577', true: '#ADBCF9' }}
                                color={inputs.priv ? '#5a7bf4' : '#f4f3f4'}
                                style={{ marginTop: 5, marginBottom: 30 }}
                                onValueChange={() => {
                                    setInputs({ ...inputs, priv: !inputs.priv, pub: !inputs.priv ? false : !inputs.priv }); setDirty(true);
                                }}
                                value={inputs.priv}
                                disabled={readOnly} />
                        </View>
                    </View>
                </ScrollView>}
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: 'center'
    },
    titleHeader: {
        alignSelf: 'flex-start',
        fontSize: 26,
        color: '#fff',
        marginLeft: 15,
    },
    registerView: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    register: {
        alignSelf: "flex-end",
        color: "#fff",
        fontSize: 20,
        marginRight: 15,
    },
    registerInvalid: {
        alignSelf: "flex-end",
        color: "rgba(255, 255, 255, 0.5)",
        fontSize: 20,
        marginRight: 15,
        cursor: "default",
        userSelect: "none",
    },
    scrollView: {
        width: "100%",
        height: "60%",
    },
    infosView: {
        alignItems: 'center'
    },
    viewInputs: {
        flexDirection: 'column',
        paddingHorizontal: 20,
        width: '100%',
    },
    labelText: {
        fontWeight: "bold",
        fontSize: 16,
    },
    subtitleText: {
        color: "#404040",
        fontSize: 12
    },
    textInputs: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#fff',
        width: '100%',
        alignSelf: 'center',
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    invalidTextInputs: {
        borderColor: "red",
        backgroundColor: '#fff',
        width: '100%',
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    disabledTextInputs: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        width: '100%',
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
        cursor: "not-allowed"
    },
    switchItem: {
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingBottom: "15px",
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    switchItemText: {
        fontSize: 14,
        paddingEnd: 20,
        color: "#404040"
    },
    dropDown: {
        borderColor: "#9a9a9a4f",
        flexDirection: "row",
        width: '100%',
        borderWidth: 1,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 12,
        marginBottom: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center'
    },
    dropDownContainerStyle: {
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        width: "100%",
        alignSelf: 'center',
        backgroundColor: '#F9FAFC'
    },
    disabledDropDown: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        flexDirection: "row",
        width: '100%',
        borderTopWidth: 1,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 12,
        marginBottom: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: "not-allowed"
    },
    drops: {
        borderColor: "#9a9a9a4f",
        width: "100%",
        borderWidth: 1,
        borderTopWidth: 0,
        color: '#000',
        fontSize: 16,
        paddingVertical: 15,
        paddingHorizontal: 15
    },
    dropDownInput: {
        color: '#000',
        fontSize: 16,
    },
    dropDownIcon: {
        height: 7,
        width: 1
    },
    sugestionView: {
        flex: 1,
        position: 'absolute',
        width: "96%",
        backgroundColor: '#fff',
        alignSelf: 'center',
        zIndex: 100,
        marginTop: 176,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowRadius: 5,
    },
    loading: {
        marginHorizontal: 'auto',
        transform: [{ scaleX: 2 }, { scaleY: 2 }],
        width: '20%',
        height: '20%',
    },
    loadingText: {
        color: 'black',
        textAlign: 'center',
        fontSize: 16
    },
    loadingView: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        marginVertical: 'auto',
        justifyContent: 'space-between',
        height: 150
    }
});
