import BarcodeScannerComponent from "react-qr-barcode-scanner";
import React, { useState } from 'react';
import { StyleSheet, Modal, View, Text, TouchableOpacity, Image } from "react-native";
import { ActivityIndicator, List } from "react-native-paper";
import { translate } from "../../services/service-translate";
import { Model } from "../../pages/crm/camera/Register";
import Toast from "react-native-toast-message";


interface Barcode {
    setData: React.Dispatch<React.SetStateAction<string>>;
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setStopStream: React.Dispatch<React.SetStateAction<boolean>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    stopStream: boolean;
    modalVisible: boolean;
    maxLength: number;
    model: Model;
}


const headerSize = 60;

export default function BarcodeScanner({ setData, modalVisible, setModalVisible, maxLength, setStopStream, stopStream, loading, setLoading, model }: Barcode) {

    const [text, setText] = useState(false);

    const dimissQrReader = () => {
        setStopStream(true);
        setTimeout(() => setModalVisible(!modalVisible), 0);
        setText(false);
    };

    return (
        <Modal
            animationType='fade'
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
                setModalVisible(!modalVisible);
            }}
        >
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={dimissQrReader}>
                        <List.Icon
                            style={styles.backArrow}
                            icon='close'
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader} numberOfLines={2} adjustsFontSizeToFit>{'Code scanner'}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.fadeView}>
                <View style={styles.codeReader}>
                    {loading === true ? (
                        <ActivityIndicator
                            animating={loading}
                            style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                            size="small"
                            color="#0071bc"
                        />
                    ) : (null)}
                    <BarcodeScannerComponent
                        width={'100%'}
                        height={'100%'}
                        onUpdate={(err, result) => {
                            if (err) {
                                return setLoading(false);
                            }
                            if (!result) {
                                return setLoading(false);
                            }

                            if (result.getText().length <= maxLength) {
                                setText(false);
                                setData(result.getText());
                                setModalVisible(!modalVisible);
                                setLoading(false);
                            } else {
                                return setText(true), setLoading(false);
                            }
                        }}
                        onError={(error) => {
                            if (typeof error == 'string') {
                                console.error(error);
                                return;
                            }

                            if (error.name === 'NotAllowedError') {
                                Toast.show({
                                    type: 'error',
                                    text1: translate("notAllowedCameraPermission"),
                                });
                                setData("");
                            } else {
                                Toast.show({
                                    type: 'error',
                                    text1: translate('cameraInvalidAccess'),
                                });
                                console.error(error);
                            }
                        }}
                        stopStream={stopStream}

                    />
                    {text === true ?
                        <Text style={styles.errorText} >{translate("invalidBarcode")}</Text>
                        : null
                    }
                </View>
                {
                    model.title == 'VIP 1230 B' ?
                        <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fintelbras_barcode.png' }}
                            style={styles.image} /> : null
                }
                {
                    model.title == 'DS-2CD1023G0E-I' ?
                        <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fhikvision_barcode.png' }}
                            style={styles.image} /> : null
                }
            </View>
        </Modal>
    );


}
const styles = StyleSheet.create({
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    fadeView: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'rgb(255,255,255)',
        opacity: 1
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
    },
    headerModal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '95%',
        alignItems: 'center',
        marginHorizontal: 25,
        marginTop: 5,
    },
    containerModal: {
        backgroundColor: "#fff",
        borderRadius: 20,
        shadowColor: "#000",
        opacity: 1,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        width: '100%',
        minHeight: '100%',
    },
    titleModal: {
        numberOfLines: 1,
        fontSize: 17,
        maxWidth: "80%",
        textAlign: 'center'
    },
    codeReader: {
        borderRadius: 4,
        width: '100%',
        maxHeight: '85%',
    },
    textButtonHelpModal: {
        color: '#fff'
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        marginVertical: 'auto',
        marginHorizontal: 'auto',
        width: '10%',
        height: '10%',
        borderRadius: 10,
    },
    errorText: {
        marginTop: 15,
        fontSize: 24,
        textAlign: 'center',
        width: '100%',
        color: 'red',
    },
    image: {
        width: '100%',
        resizeMode: 'contain',
        height: '45%',
        marginHorizontal: 'auto',
        marginTop: 10,
    },
});

export { BarcodeScanner };
