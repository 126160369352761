import React from 'react';
import MainRoutes from './src/Routes';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import * as serviceWorkerRegistration from "./src/services/serviceWorkerRegistration";
import { notificationService } from "./src/services/notification-service";
import './src/styles/global.css';
import ChangeLanguage, { getDefaultLanguage } from './src/services/service-translate';
import Toast from 'react-native-toast-message';
import { toastConfig } from './src/services/toast-config';

export default function App() {

    return (
        <>
            <link rel="stylesheet" href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css"
                integrity="sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ=="
                crossOrigin="" />
            <script src="https://unpkg.com/leaflet@1.8.0/dist/leaflet.js"
                integrity="sha512-BB3hKbKWOc9Ez/TAwyWxNXeoV9c1v6FIeYiBieIWkpLjauysF18NzgR1MBNBXf8/KABdlkX68nAhlwcDFLGPCQ=="
                crossOrigin=""></script>
            <script
                type="text/javascript"
                src="https://maps.googleapis.com/maps/api/js?key=AIzaSyATThzieYphmKnlRyELRUHs3NCLgAU9LrQ&libraries=places"
            />
            <ActionSheetProvider>
                <>
                    <MainRoutes />
                    <Toast config={toastConfig} position='top' visibilityTime={3000} />
                </>
            </ActionSheetProvider>
        </>
    );
}

(async () => {
    try {
        await serviceWorkerRegistration.register();
        console.info("service worker registered.");
        await notificationService.registerToken();
        console.info("firebase token registered.");
    } catch (err) {
        console.error(err);
    }
})();

(async () => {
    try {
        const lang = await getDefaultLanguage();
        await ChangeLanguage(lang);
    } catch (err) {
        console.error(err);
    }
})();

