import React, { useCallback, useEffect, useState } from 'react';
import Maps from '../components/Maps';
import { View, Text, StyleSheet, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { CameraList, invalidBearerToken, platformAPI } from '../services/platform-api';
import DefaultPageContainer from '../components/DefaultPageContainer';
import { translate } from '../services/service-translate';

interface MapComponentProps {
    navigation: Navigation;
}

const headerSize = 60;

export default function Map({ navigation }: MapComponentProps) {
    const [loading, setLoading] = useState(true);
    const [cameras, setCameras] = useState<CameraList[]>([]);
    const [emptyCameras, setEmptyCameras] = useState(false);

    useFocusEffect(
        useCallback(() => {
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
        }, [navigation])
    );

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            getCameras();
        });
        return unsubscribe;
    }, [navigation]);


    async function getCameras() {
        try {
            setLoading(true);

            const cams = await platformAPI.getCameras();

            if (cams.length == 0) {
                setEmptyCameras(true);
            }

            setCameras(cams);

            setTimeout(() => setLoading(false), 1000);
        } catch (err) {

            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);

            setEmptyCameras(true);
            setTimeout(() => setLoading(false), 1000);
        }
    }

    async function goBack() {
        navigation.navigate('Camera', { screen: 'Cameras' });
    }

    return (
        <DefaultPageContainer excludeBottomPadding={true}>
            <View style={styles.header}>
                <View style={styles.closeView}>
                    <TouchableOpacity style={styles.closeIcon} onPress={goBack}>
                        <Image style={styles.closeIcon} source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_close.png' }} />
                    </TouchableOpacity>
                </View>
                <View style={styles.logoView}>
                    <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fcamerite-topo.png' }} style={styles.logo} />
                </View>
                <View style={styles.emptyView} />
            </View>
            <>
                {!loading ? (
                    <>
                        {emptyCameras ?
                            <View style={[styles.contentView, { flex: 1, paddingBottom: headerSize, justifyContent: 'center', alignItems: 'center' }]}>
                                <Text style={styles.textInvalidAcess}>{translate('emptyMapCameras')}</Text>
                            </View>
                            :
                            <Maps cameras={cameras} navigation={navigation} />
                        }
                    </>
                ) :
                    <View style={[styles.contentView, { flex: 1, paddingBottom: headerSize, justifyContent: 'center', alignItems: 'center' }]}>
                        <ActivityIndicator size={50} style={{ padding: 10 }} />
                        <Text style={{ padding: 5 }}>{translate('loading')}...</Text>
                    </View>
                }
            </>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    header: {
        backgroundColor: '#0071bc',
        width: '100%',
        height: headerSize,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    closeView: {
        height: headerSize,
        width: '33%',
        alignSelf: 'flex-start',
        justifyContent: 'center',
        padding: 15
    },
    closeIcon: {
        width: 30,
        height: 30
    },
    logoView: {
        width: '33%',
        alignContent: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        height: headerSize
    },
    logo: {
        width: 166,
        height: 32,
        alignSelf: 'center',
        justifyContent: 'center'
    },
    emptyView: {
        width: '33%',
        alignItems: 'flex-end'
    },
    contentView: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center'
    },
    textInvalidAcess: {
        fontSize: 16,
        fontWeight: '500',
        color: '#aeaeae',
        alignSelf: 'center',
        justifyContent: 'center',
        textAlignVertical: 'center',
        textAlign: 'center'
    },
});
