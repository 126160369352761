import React from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    View,
    GestureResponderEvent,
    Text,
    FlatList,
} from "react-native";
import { List } from "react-native-paper";
import { CameraBrands, CameraSteps, Model } from '../../pages/crm/camera/Register';
import { translate } from '../../services/service-translate';

interface CamModels {
    camBrand: CameraBrands,
    setModel: React.Dispatch<React.SetStateAction<Model | undefined>>;
    setBrand: React.Dispatch<React.SetStateAction<CameraBrands>>;
    setStep: React.Dispatch<React.SetStateAction<CameraSteps>>;
    setIsRtmpCamera: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ListProps {
    title: string;
    onPress: ((event: GestureResponderEvent) => void);

}

const headerSize = 60;

export default function CamModel({ camBrand, setModel, setStep, setBrand, setIsRtmpCamera }: CamModels) {

    const hikvisionOptions = [
        {
            title: 'DS-2CD2T45FWD-I5/I8',
            onPress: () => {
                setModel({ title: 'DS-2CD2T45FWD-I5/I8', maxLength: 13 });
                setStep('serial_number_hik');
            }
        },
        {
            title: 'DS-2CD2023G0-I',
            onPress: () => {
                setModel({ title: 'DS-2CD2023G0-I', maxLength: 13 });
                setStep('serial_number_hik');
            }
        },
        {
            title: 'DS-2CD1023G0E-I',
            onPress: () => {
                setModel({ title: 'DS-2CD1023G0E-I', maxLength: 9 });
                setStep('serial_number_hik'); setBrand('HIK_PRO_CONNECT');
            }
        },
        {
            title: 'DS-2CD1043G0E-I',
            onPress: () => {
                setModel({ title: 'DS-2CD1043G0E-I', maxLength: 9 });
                setStep('serial_number_hik');
                setBrand('HIK_PRO_CONNECT');
            }
        },
        {
            title: 'DS-2CD1043G1-I',
            onPress: () => {
                setModel({ title: 'DS-2CD1043G1-I', maxLength: 9 });
                setStep('serial_number_hik');
                setBrand('HIK_PRO_CONNECT');
            }
        },
        {
            title: translate('others'),
            onPress: () => {
                setBrand('UNKNOWN');
                setStep('rtsp_link');
                setIsRtmpCamera(false);
            }
        }
    ];
    const intelbrasOptions = [
        {
            title: 'VIP 1230 B',
            onPress: () => {
                setModel({ title: 'VIP 1230 B', maxLength: 13 });
                setStep('serial_number_intelbras');
            }
        },
        {
            title: translate('others'),
            onPress: () => {
                setBrand('UNKNOWN');
                setStep('rtsp_link');
                setIsRtmpCamera(false);
            }
        }
    ];

    const Item = ({ title, onPress }: ListProps) => (
        <>
            <View style={styles.viewButton}>
                <TouchableOpacity style={styles.button} onPress={onPress}>
                    <View style={styles.button}>
                        <Text style={styles.textButton}>{title}</Text>
                    </View>
                </TouchableOpacity>
            </View>
            <ItemSeparator />
        </>
    );

    const renderItem = ({ item }: { item: ListProps; }) => (
        <Item title={item.title} onPress={item.onPress} />
    );

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    return (
        <>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={() => setStep('brand')}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("cameraName")}{` ${camBrand.charAt(0).toUpperCase() + camBrand.slice(1).toLowerCase()}`}</Text>
                    </View>
                </View>
            </View>
            {camBrand === 'HIKVISION' ?
                <FlatList
                    bounces={false}
                    data={hikvisionOptions}
                    renderItem={renderItem}
                    keyExtractor={(item: ListProps) => item.title}
                />
                : null
            }
            {camBrand === 'INTELBRAS' ?
                <FlatList
                    bounces={false}
                    data={intelbrasOptions}
                    renderItem={renderItem}
                    keyExtractor={(item: ListProps) => item.title}
                />
                : null
            }


        </>
    );
}
const styles = StyleSheet.create({
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    title: {
        textTransform: "capitalize",
        fontSize: 16,
    },
    textButton: {
        fontSize: 26,
        color: 'black',
        textAlign: 'center',
        justifyContent: 'center',
    },
    button: {
        flexDirection: 'row',
        width: "100%",
        height: 85,
        justifyContent: 'center',
        alignItems: 'center',
    },
    viewButton: {
        borderTopColor: '#cecece',
        flexDirection: 'row',
    },
    buttonActive: {
        flexDirection: 'row',
        width: "100%",
        height: 45,
        alignItems: 'center',
        cursor: 'Pointer',
        borderWidth: 1,
        borderColor: "rgba(0, 113, 188, 0.5)",
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
});

export { CamModel };
