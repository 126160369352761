import React from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets, SafeAreaProvider } from 'react-native-safe-area-context';

interface DefaultPageContainerProps {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
    customBackgroundColor?: string;
    customTopInsetColor?: string;
    excludeBottomPadding?: boolean;
}

export default function DefaultPageContainer(props: DefaultPageContainerProps) {
    const insets = useSafeAreaInsets();

    return (
        <>
            <SafeAreaProvider
                initialSafeAreaInsets={insets}
                style={{ flex: 0, backgroundColor: props.customTopInsetColor || "#0071bc", paddingTop: insets.top }}
            />
            <SafeAreaProvider
                initialSafeAreaInsets={insets}
                style={{
                    flex: 1,
                    backgroundColor: "#000000",
                    paddingBottom: props.excludeBottomPadding ? 0 : insets.bottom,
                }}
            >
                <View style={{ flex: 1, backgroundColor: props.customBackgroundColor || '#fff' }}>
                    <meta name="theme-color" content={props.customTopInsetColor || "#0071bc"} />
                    <meta name="apple-mobile-web-app-status-bar-style" content={props.customTopInsetColor || "#0071bc"} />
                    {props.children}
                </View>
            </SafeAreaProvider>
        </>
    );
}

