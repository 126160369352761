import React, { useEffect, useState } from 'react';
import {
    Text,
    View,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    TextInput,
} from 'react-native';
import { List } from 'react-native-paper';
import { useNavigationState } from '@react-navigation/native';
import { translate } from '../../../services/service-translate';
import { forbiddenAccess, invalidBearerToken, platformAPI } from '../../../services/platform-api';
import { Switch } from 'react-native-elements';
import DefaultPageContainer from '../../../components/DefaultPageContainer';

interface LprGroupFormComponentProps {
    navigation: Navigation;
    route: {
        params?: { id: string; };
    };
}

const headerSize = 60;

export default function LprGroupForm({ navigation, route }: LprGroupFormComponentProps) {
    const lprGroupId = route.params?.id ? route.params.id : undefined;
    const index = useNavigationState(state => state);

    const [title, setTitle] = useState<string>('');
    const [isWhitelist, setIsWhitelist] = useState(false);
    const [isUserManaged, setIsUserManaged] = useState(false);
    const [description, setDescription] = useState<string>('');


    async function getLprGroup(id: string) {
        const lprGroup = await platformAPI.getBlacklistGroup(id);
        setTitle(lprGroup.title);
        setIsWhitelist(lprGroup.whitelist);
        setIsUserManaged(lprGroup.userManaged);
        setDescription(lprGroup.description);
    }

    useEffect(() => {
        if (lprGroupId) {
            getLprGroup(lprGroupId);
        }
    }, []);

    function goBack() {
        if (index.routes.length > 1) {
            navigation.navigate("LprGroups");
            return;
        }
        window.location.href = `/crm/lpr/groups`;
    }

    function validate() {
        if (!title) {
            return false;
        }
        return true;
    }

    async function save() {
        try {
            const payload = {
                title: title,
                description: description,
                whitelist: isWhitelist,
                userManaged: isUserManaged
            };

            if (lprGroupId) {
                await platformAPI.updateLprGroup({ ...payload, id: lprGroupId });
            } else {
                await platformAPI.createLprGroup(payload);
            }
            goBack();

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            if (err instanceof forbiddenAccess) {
                goBack();
                return;
            }
            console.error(err);
        }
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("LprGroups")}</Text>
                    </View>
                </View>
                <View style={styles.registerView}>
                    {validate() ?
                        <TouchableOpacity onPress={() => save()}>
                            <Text style={styles.register}>{translate("save")}</Text>
                        </TouchableOpacity>
                        : <Text style={styles.registerInvalid}>{translate("save")}</Text>
                    }
                </View>

            </View>
            <ScrollView style={styles.scrollContainer}>
                <View style={[styles.inputTextView, styles.titleInput]}>
                    <Text style={styles.inputTextTitle}>{translate("textTitle")}</Text>
                    <TextInput
                        style={[styles.textInputs, styles.textInputsTitle]}
                        value={title}
                        onChangeText={(text) => setTitle(text)}
                    />
                </View>
                <View style={styles.switchItem}>
                    <Text style={styles.switchItemText}>{translate('whitelist')}</Text>
                    <Switch
                        trackColor={{ false: '#767577', true: '#ADBCF9' }}
                        color={isWhitelist ? '#5a7bf4' : '#f4f3f4'}
                        onValueChange={() => setIsWhitelist(!isWhitelist)}
                        value={isWhitelist}
                    />
                </View>
                <View style={styles.switchItem}>
                    <Text style={styles.switchItemText}>{translate('userManaged')}</Text>
                    <Switch
                        trackColor={{ false: '#767577', true: '#ADBCF9' }}
                        color={isUserManaged ? '#5a7bf4' : '#f4f3f4'}
                        onValueChange={() => setIsUserManaged(!isUserManaged)}
                        value={isUserManaged}
                    />
                </View>
                <View style={styles.inputTextView}>
                    <Text style={styles.inputTextTitle}>{translate("description")}</Text>
                    <TextInput
                        style={[styles.textInputs, styles.description]}
                        value={description}
                        onChangeText={(text) => setDescription(text)}
                        multiline={true}
                    />
                </View>
            </ScrollView>
        </DefaultPageContainer>
    );

}

const styles = StyleSheet.create({
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    backArrow: {
        alignSelf: "flex-start"
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    scrollContainer: {
        backgroundColor: '#fff',
        height: 'calc(100vh - 60px)',
    },
    inputTextView: {
        marginTop: 15,
        width: '100%',
    },
    titleInput: {
        marginBottom: 10,
    },
    inputTextTitle: {
        marginLeft: 10,
        marginBottom: 3,
    },
    textInputs: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#fff',
        color: '#000',
    },
    textInputsTitle: {
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        height: 45,
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    registerView: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    register: {
        alignSelf: "flex-end",
        color: "#fff",
        fontSize: 20,
        marginRight: 15,
    },
    registerInvalid: {
        alignSelf: "flex-end",
        color: "rgba(255, 255, 255, 0.5)",
        fontSize: 20,
        marginRight: 15,
        cursor: "default",
        userSelect: "none",
    },
    inputsDisabled: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
        cursor: 'default'
    },
    switchItemText: {
        fontSize: 14,
        paddingEnd: 20,
    },
    switchItem: {
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingBottom: "15px",
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    description: {
        textAlignVertical: "top",
        height: 120,
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginBottom: 10,
    },
});
