import {
    View, Text
} from 'react-native';
import React, { useEffect } from 'react';
import { translate } from '../services/service-translate';

import "../styles/custom.css";
import VideoPlayer from './VideoPlayer';
import TimelineShot from '../services/timeline-shot';

const HOUR_IN_SECONDS = 60 * 60;
const HOUR_IN_MILISSECONDS = 1000 * HOUR_IN_SECONDS;
const HOUR_IN_PIXELS = 400;
const MINUTE_IN_MILISSECONDS = 60 * 1000;

const GAP_TO_START_IN_PIXELS = 65;
const GAP_BETWEEN_SHOTS = 10 * MINUTE_IN_MILISSECONDS;

interface Shot {
    moment: number;
    url: string;
}

interface TimelineRecord {
    start: number;
    finish: number;
    videoUrl: string;
    shots: Shot[];
}

interface specialHTMLDivElement extends HTMLDivElement {
    ignoreEvent: boolean | undefined;
}

type CameraTimelineProps = {
    readonly timelineRecords: TimelineRecord[];
    readonly planAmount: number;
    readonly isLive: boolean;
    readonly setIsLive: React.Dispatch<React.SetStateAction<boolean>>;
    readonly timelineAt: string;
    readonly setTimelineAt: React.Dispatch<React.SetStateAction<string>>;
    readonly setDay: React.Dispatch<React.SetStateAction<string>>;
    readonly timelineHeight: number;
    readonly timelineWidth: number;
    readonly setTimelineShot: React.Dispatch<React.SetStateAction<string>>;
    readonly videoPlayer: VideoPlayer | undefined;
    readonly currentRecord: TimelineRecord | undefined;
    readonly setCurrentTime: React.Dispatch<React.SetStateAction<Date>>;
    readonly currentDate: Date;
    readonly setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    readonly loading: boolean;
};

async function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function CameraTimeline({ timelineRecords, setIsLive, isLive, setTimelineAt, setDay, planAmount, timelineHeight, timelineWidth, setTimelineShot, videoPlayer, currentRecord, timelineAt, setCurrentTime, currentDate, loading, setLoading }: CameraTimelineProps) {

    let now = new Date();
    let internalIsLive = true;

    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const viewRef = React.useRef<specialHTMLDivElement>(null);
    const bigViewRef = React.useRef<HTMLDivElement>(null);

    const [time, setTime] = React.useState(formatHourFromDate(now));
    const [timelineShots, setTimelineShots] = React.useState<{ [shotTime: string]: TimelineShot; }>({});

    function getCurrentDate(view: HTMLDivElement) {
        const now = new Date();
        return new Date(now.getTime() - (view.scrollTop / HOUR_IN_PIXELS) * HOUR_IN_MILISSECONDS);
    }

    function getPositionFromDate(timestamp: number, considerGap = true) {
        const now = new Date();
        return (considerGap ? GAP_TO_START_IN_PIXELS : 0) + (((now.getTime() - timestamp) / HOUR_IN_MILISSECONDS) * HOUR_IN_PIXELS);
    }

    useEffect(() => {
        const view = viewRef.current;
        const canvas = canvasRef.current;
        const bigView = bigViewRef.current;

        if (!view || !canvas || !bigView) {
            return;
        }

        if (timelineHeight) {
            view.style.height = timelineHeight.toString() + "px";
            view.style.maxHeight = view.style.height;
            canvas.height = timelineHeight;
        }

        if (timelineWidth) {
            view.style.width = timelineWidth.toString() + "px";
            bigView.style.width = timelineWidth.toString() + "px";
            canvas.width = timelineWidth;
        }

    }, [timelineHeight, timelineWidth]);

    useEffect(() => {
        if (isLive) {
            const view = viewRef.current;

            if (view) {
                view.style.overflowY = 'hidden';
                view.scrollTop = 0;
                setTimeout(function () {
                    view.style.overflowY = 'scroll';
                }, 10);
            }
        }
    }, [isLive]);

    useEffect(() => {
        const view = viewRef.current;
        if (view) {
            view.scrollTop = getPositionFromDate(currentDate.getTime(), false);
        }
    }, [currentDate]);


    function draw(canvas: HTMLCanvasElement, view: HTMLDivElement) {
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return;
        }

        const scrollTop = view.scrollTop;

        // clean canvas
        ctx.fillStyle = '#3c4049';
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.fill();

        const currentDate = getCurrentDate(view).getTime();
        ctx.fillStyle = "#4d515a";

        const twelve_hours = (1000 * 60 * 60 * 12);

        // draw timeline
        for (const timelineRecord of timelineRecords) {

            if (timelineRecord.start < currentDate - twelve_hours) {
                continue;
            }
            if (timelineRecord.finish > currentDate + twelve_hours) {
                continue;
            }
            const finishPos = getPositionFromDate(timelineRecord.finish);
            const startPos = getPositionFromDate(timelineRecord.start);

            ctx.fillRect(0, finishPos - scrollTop, 100, startPos - finishPos);
            ctx.fillRect(120, finishPos - scrollTop, canvas.width - 120, startPos - finishPos);
        }

        // draw hours mark
        let initOn = GAP_TO_START_IN_PIXELS + ((now.getMinutes() * 60) + now.getSeconds()) / HOUR_IN_SECONDS * HOUR_IN_PIXELS;

        let hours = now.getHours();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        if (initOn > HOUR_IN_PIXELS) {
            initOn -= HOUR_IN_PIXELS;
            hours += 1;
        }
        ctx.font = '14p -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif';

        for (let y = initOn; y < scrollTop + canvas.height; y = y + HOUR_IN_PIXELS) {
            ctx.beginPath();
            ctx.fillStyle = '#000';
            ctx.moveTo(0, y - scrollTop);
            ctx.lineTo(10, y - scrollTop);
            ctx.fillStyle = "#fff";
            ctx.fillText(hours + " " + ampm, 1, y + 14 - scrollTop);
            hours -= 1;
            if (hours == 0) {
                hours = 12;
            }
            if (hours == 11) {
                if (ampm == "AM") {
                    ampm = "PM";
                } else {
                    ampm = "AM";
                }
            }
            ctx.stroke();
            ctx.closePath();
        }

        // draw timeline shots
        for (const shotTime of Object.keys(timelineShots)) {
            const time = Number(shotTime);

            if (time < currentDate - twelve_hours) {
                continue;
            }
            if (time > currentDate + twelve_hours) {
                continue;
            }

            const yPosition = getPositionFromDate(time);

            const timelineShot = timelineShots[shotTime];
            timelineShot.load();
            timelineShot.draw(ctx, canvas.width, yPosition - scrollTop);
        }

    }

    async function handleScroll() {
        const view = viewRef.current;
        const canvas = canvasRef.current;

        if (!view || !canvas) {
            return;
        }

        if (view.ignoreEvent) {
            view.ignoreEvent = false;
            return;
        }

        const scrollTop = view.scrollTop;

        canvas.style.marginTop = scrollTop.toString() + "px";
        draw(canvas, view);

        if (scrollTop == 0 && !internalIsLive) {
            internalIsLive = true;
            setIsLive(true);
        }

        if (scrollTop != 0 && internalIsLive) {
            internalIsLive = false;
            setIsLive(false);
        }

        const currentDate = getCurrentDate(view);

        setTime(formatHourFromDate(currentDate));
        setDay(formatDayFromDate(currentDate, now));

        const time = currentDate.getTime();

        for (const video of timelineRecords) {
            if (time >= video.start && time < video.finish) {
                let currentShot: Shot | undefined;
                for (const shot of video.shots) {
                    if (time < shot.moment) {
                        break;
                    }
                    currentShot = shot;
                }
                if (currentShot) {
                    setTimelineShot(currentShot.url);
                }
                break;
            }
        }

        await sleep(500);

        if (scrollTop != view.scrollTop) {
            return;
        }

        if (scrollTop == 0) {
            setTimelineAt("[live]");
            return;
        }

        const currentTime = getCurrentDate(view).getTime();
        let currentVideo: TimelineRecord | undefined;
        let currentShot: Shot | undefined;

        for (const video of timelineRecords) {
            if (currentTime >= video.start && currentTime < video.finish) {

                currentVideo = video;

                for (const shot of video.shots) {
                    if (currentTime < shot.moment) {
                        break;
                    }
                    currentShot = shot;
                }

                break;
            }
        }

        if (!currentVideo) {
            setTimelineAt(getCurrentDate(view).getTime().toString());
            return;
        }

        if (!currentShot) {
            setTimelineAt(currentVideo.start.toString());
            return;
        }

        setTimelineAt(currentShot.moment.toString());
    }

    useEffect(() => {
        const canvas = canvasRef.current;
        const view = viewRef.current;
        const bigView = bigViewRef.current;

        if (!canvas || !view || !bigView) {
            return;
        }

        canvas.width = view.offsetWidth;

        let viewHeight = Number(view.style.height.replace("px", ""));

        if (viewHeight == 0) {
            viewHeight = view.offsetHeight;
        }

        bigView.style.height = ((HOUR_IN_PIXELS * 24 * planAmount) + viewHeight).toString() + "px";
        view.style.maxHeight = viewHeight + "px";
        canvas.height = viewHeight;

        view.onscroll = handleScroll;

        draggableOnWeb(view);

    }, [timelineRecords, planAmount]);

    function getVideo(time: number): TimelineRecord | undefined {
        let closestVideo: TimelineRecord | undefined;
        const THIRTY_MINUTES = 1000 * 60 * 30;

        for (const video of timelineRecords) {
            if (!closestVideo && time >= video.start - THIRTY_MINUTES && time < video.finish) {
                closestVideo = video;
            }

            if (time >= video.start && time < video.finish) {
                return video;
            }
        }

        if (closestVideo) {
            return closestVideo;
        }
    }

    useEffect(() => {
        const view = viewRef.current;

        if (!view) {
            return;
        }

        let accumulatedOffset = 0;
        let updateTimer: number | NodeJS.Timeout | null = null;
        const overlayElement = document.createElement('div');
        const textElement = document.createElement('div');
        if (timelineAt == "[live]") {
            videoPlayer?.setDoubleClickHandler((ev: HammerInput) => {
                const mouseX = ev.center.x - (window.innerWidth / 2);
                const direction = mouseX < 0 ? -1 : 1;
                if (direction === 1) return;

                if (accumulatedOffset >= 20000 && direction === -1) {
                    return;
                }

                accumulatedOffset += 20000 * direction;

                const addedSeconds = Math.abs(accumulatedOffset / 1000);
                textElement.innerHTML = 'Retrocedendo<br>' + addedSeconds + ' Segundos';
                overlayElement.classList.add('left');

                overlayElement.classList.add('overlay');

                textElement.classList.add('text');

                document.body.appendChild(overlayElement);
                overlayElement.appendChild(textElement);

                const rippleX = ev.center.x - overlayElement.getBoundingClientRect().left;
                const rippleY = ev.center.y - overlayElement.getBoundingClientRect().top;

                overlayElement.style.setProperty('--ripple-x', rippleX + 'px');
                overlayElement.style.setProperty('--ripple-y', rippleY + 'px');
                overlayElement.classList.add('rippling');

                const controlBar = document.querySelector('.vjs-control-bar');

                controlBar?.parentNode?.insertBefore(overlayElement, controlBar);

                setTimeout(() => {
                    overlayElement.style.opacity = '1';
                }, 100);

                if (updateTimer !== null && typeof updateTimer === 'number') {
                    clearTimeout(updateTimer);
                }

                updateTimer = setTimeout(() => {
                    updateDisplay(accumulatedOffset);
                }, 500);
            });
            return;
        } else {
            const time = Number(timelineAt);
            const internalCurrentRecord = getVideo(time);

            if (!internalCurrentRecord) {
                return;
            }
            view.ignoreEvent = true;
            setLoading(true);

            if (time > internalCurrentRecord.start && time < internalCurrentRecord.finish) {
                view.scrollTop = getPositionFromDate(time, false);
            } else {
                view.scrollTop = getPositionFromDate(internalCurrentRecord.start, false);
            }

            videoPlayer?.setDoubleClickHandler((ev: HammerInput) => {
                const mouseX = ev.center.x - (window.innerWidth / 2);
                const direction = mouseX < 0 ? -1 : 1;

                if (accumulatedOffset >= 20000 && direction === -1) {
                    return;
                }
                if (accumulatedOffset <= -20000 && direction === 1) {
                    return;
                }

                accumulatedOffset += 20000 * direction;

                if (mouseX < 0) {
                    const addedSeconds = Math.abs(accumulatedOffset / 1000);
                    textElement.innerHTML = 'Retrocedendo<br>' + addedSeconds + ' Segundos';
                    overlayElement.classList.add('left');
                } else {
                    const addedSeconds = Math.abs(accumulatedOffset / 1000);
                    textElement.innerHTML = 'Avançando<br>' + addedSeconds + ' Segundos';
                    overlayElement.classList.add('right');
                }

                overlayElement.classList.add('overlay');

                textElement.classList.add('text');

                document.body.appendChild(overlayElement);
                overlayElement.appendChild(textElement);

                const rippleX = ev.center.x - overlayElement.getBoundingClientRect().left;
                const rippleY = ev.center.y - overlayElement.getBoundingClientRect().top;

                overlayElement.style.setProperty('--ripple-x', rippleX + 'px');
                overlayElement.style.setProperty('--ripple-y', rippleY + 'px');
                overlayElement.classList.add('rippling');

                const controlBar = document.querySelector('.vjs-control-bar');

                controlBar?.parentNode?.insertBefore(overlayElement, controlBar);

                setTimeout(() => {
                    overlayElement.style.opacity = '1';
                }, 100);

                if (updateTimer !== null && typeof updateTimer === 'number') {
                    clearTimeout(updateTimer);
                }

                updateTimer = setTimeout(() => {
                    updateDisplay(accumulatedOffset);
                }, 500);
            });
            setLoading(false);
        }
        function updateDisplay(accumulatedOffset: number) {
            if (timelineAt === "[live]") {
                setTimelineAt(String(Number(new Date().getTime()) + accumulatedOffset));
                setIsLive(false);
                overlayElement.style.opacity = '0';
                textElement.style.opacity = '0';
                return;
            }
            if (Number(timelineAt) < Number(new Date().getTime()) + accumulatedOffset) {
                setIsLive(true);
                overlayElement.style.opacity = '0';
                textElement.style.opacity = '0';
                return;
            }
            setTimelineAt(String(Number(timelineAt) + accumulatedOffset));
            overlayElement.style.opacity = '0';
            textElement.style.opacity = '0';

            setTimeout(() => {
                overlayElement.remove();
                textElement.remove();
            }, 100);
        }
    }, [timelineAt]);

    useEffect(() => {
        const shotsList: Shot[] = [];

        for (const timelineRecord of timelineRecords) {
            for (const shot of timelineRecord.shots) {
                const lastShot = shotsList.at(-1);
                if (!lastShot || shot.moment - lastShot.moment >= GAP_BETWEEN_SHOTS) {
                    shotsList.push(shot);
                }
            }
        }

        for (const shot of shotsList) {
            timelineShots[shot.moment] = new TimelineShot(shot.url);
        }

        setTimelineShots(timelineShots);
    }, [timelineRecords]);

    useEffect(() => {
        try {
            const canvas = canvasRef.current;
            const view = viewRef.current;

            if (!canvas || !view) {
                return;
            }

            const interval = setInterval(async () => {
                try {
                    now = new Date();
                    if (isLive && !loading && !videoPlayer?.player?.paused()) {
                        setTime(formatHourFromDate(now));
                        setCurrentTime(now);
                    } else {
                        if (videoPlayer && currentRecord && !videoPlayer?.player?.paused() && !loading) {
                            const time = await videoPlayer.getTime();
                            const date = new Date((time * 1000) + currentRecord.start);
                            setTime(formatHourFromDate(getCurrentDate(view)));
                            setCurrentTime(date);
                        }
                    }
                    draw(canvas, view);

                    // lazyLoad();
                } catch (err) {
                    console.error(err);
                }
            }, 1000);

            return () => clearInterval(interval);
        } catch (err) {
            console.error(err);
        }
    }, [videoPlayer, currentRecord, timelineRecords, planAmount, isLive]);

    return (
        <>
            <div style={{
                flex: 1,
                overflowX: 'hidden',
                overflowY: 'scroll',
                cursor: 'grab'
            }} ref={viewRef}>
                <div ref={bigViewRef}>
                    <canvas ref={canvasRef} />
                </div>
            </div>
            <Text style={{ position: 'absolute', width: '100% ', marginTop: 60, color: '#fff', paddingLeft: 20 }}>
                <span className="not-selectable">{time}</span>
            </Text>
            <View style={{ position: 'absolute', width: '100% ', marginTop: 65, paddingLeft: 100, zIndex: 6 }}>
                <View style={{ width: '100%', borderTopWidth: 2, borderColor: '#0071bc' }} ></View>
            </View>
        </>
    );
}

function pad_with_zeroes(number: number, length: number) {

    let my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }

    return my_string;

}

function formatHourFromDate(date: Date) {
    return pad_with_zeroes(date.getHours(), 2) + ":" + pad_with_zeroes(date.getMinutes(), 2) + ":" + pad_with_zeroes(date.getSeconds(), 2);
}

function formatDayFromDate(date: Date, now: Date) {
    if (date.getDate() == now.getDate() && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
        return translate("TODAY");
    }
    if (date.getDate() == now.getDate() - 1 && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
        return translate("YESTERDAY");
    }
    return pad_with_zeroes(date.getDate(), 2) + "/" + pad_with_zeroes(date.getMonth() + 1, 2) + "/" + pad_with_zeroes(date.getFullYear(), 2);
}

function draggableOnWeb(ele: HTMLDivElement) {
    ele.style.cursor = 'grab';

    let pos = { top: 0, left: 0, x: 0, y: 0 };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mouseDownHandler = function (e: any) {
        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';

        pos = {
            left: ele.scrollLeft,
            top: ele.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
        };

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mouseMoveHandler = function (e: any) {
        // How far the mouse has been moved
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
    };

    const mouseUpHandler = function () {
        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    // Attach the handler
    ele.addEventListener('mousedown', mouseDownHandler);
}

export { TimelineRecord, Shot };
