import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    TextInput,
} from "react-native";
import {
    UserData,
    ClientError,
    invalidBearerToken,
    platformAPI,
} from "../../services/platform-api";
import { translate } from "../../services/service-translate";
import DefaultPageContainer from "../../components/DefaultPageContainer";
import Toast from "react-native-toast-message";
import { ActivityIndicator } from "react-native-paper";

interface CrmUserFormComponentProps {
    navigation: Navigation;
    route: {
        params: {
            token: string,
        };
    };
    authenticatedUser?: UserData;
}

const headerSize = 60;
const footerSize = 30;

export default function RecoveryPassword({ navigation, route }: CrmUserFormComponentProps) {
    const userParam = route.params;
    const [passwordValidError, setPasswordValidError] = useState(false);
    const [passwordConfirmationValidError, setPasswordConfirmationValidError] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        canSave();
    }, [password, passwordConfirmation]);

    async function changePassword() {
        try {
            if (canSave()) {
                setLoading(true);
                await platformAPI.newPassword(
                    route.params.token,
                    password
                );
                Toast.show({
                    type: 'success',
                    text1: translate("PasswordUpdatedSuccessfully"),
                });
                console.log(`navigating to ${process.env.PUBLIC_URL}/cameras`);
                setTimeout(() => {
                    window.location.href = `${process.env.PUBLIC_URL}/cameras`;
                }, 3000);
                return;
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            if (err instanceof ClientError) {
                Toast.show({
                    type: 'error',
                    text1: translate("UNEXPECTED_ERROR"),
                });
                return;
            }
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    function canSave() {
        if (password && passwordConfirmation && !passwordValidError && !passwordConfirmationValidError) {
            return true;
        }
        return false;
    }

    function checkValidPassword(text?: string) {
        const inputPassword = text ? text : password;
        const regex = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z]).*$/;

        if (inputPassword && regex.test(inputPassword)) {
            setPasswordValidError(false);
            return true;
        } else {
            if (!text) {
                Toast.show({
                    type: 'longError',
                    text1: translate('invalidPassword'),
                });
            }
            setPasswordValidError(true);
            return false;
        }
    }

    function checkValidPasswordConfirmation(text?: string) {
        const inputPasswordConfirmation = text ? text : passwordConfirmation;

        if (inputPasswordConfirmation && inputPasswordConfirmation === password) {
            setPasswordConfirmationValidError(false);
            return true;
        } else {
            if (!text) {
                Toast.show({
                    type: 'error',
                    text1: translate('invalidPasswordConfirmation')
                });
            }
            setPasswordConfirmationValidError(true);
            return false;
        }
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={styles.titleHeaderView}>
                    <Text style={styles.titleHeader}>{userParam ? translate("RecoverPassword") : translate("registerUser")}</Text>
                </View>
                <View style={styles.registerView}>
                    {canSave() ?
                        <TouchableOpacity onPress={changePassword}>
                            <Text style={styles.register}>{translate("save")}</Text>
                        </TouchableOpacity>
                        : null}
                </View>
            </View>
            <View style={styles.viewInputs}>
                <Text style={styles.labelText}>{translate('NewPass')}</Text>
                <TextInput
                    style={
                        !passwordValidError ? styles.textInputs : styles.emptyTextInputs
                    }
                    secureTextEntry
                    onBlur={() => checkValidPassword()}
                    value={password}
                    onChangeText={(text) => {
                        setPassword(text);
                        checkValidPassword(text);
                    }}
                />
                <Text style={styles.labelText}>{translate('passwordConfirm')}</Text>
                <TextInput
                    style={!passwordConfirmationValidError ? styles.textInputs : styles.emptyTextInputs}
                    onBlur={() => checkValidPasswordConfirmation()}
                    secureTextEntry
                    value={passwordConfirmation}
                    onChangeText={(text) => {
                        setPasswordConfirmation(text);
                        checkValidPasswordConfirmation(text);
                    }}
                />
            </View>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    titleHeaderView: {
        flexDirection: "row",
        justifyContent: "center",
        marginLeft: "20px"
    },
    registerView: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    register: {
        alignSelf: "flex-end",
        color: "#fff",
        fontSize: 20,
        marginRight: 15,
    },
    registerInvalid: {
        alignSelf: "flex-end",
        color: "rgba(255, 255, 255, 0.5)",
        fontSize: 20,
        marginRight: 15,
        cursor: "default",
        userSelect: "none",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    title: {
        textTransform: "capitalize",
        fontSize: 16,
    },
    viewInputs: {
        marginTop: "15px",
        flexDirection: 'column',
        width: '100%',
        overflow: "hidden",
    },
    textInputs: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#fff',
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    emptyTextInputs: {
        borderColor: "red",
        backgroundColor: '#fff',
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        height: 45,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    labelText: {
        marginLeft: 10,
        fontSize: 16,
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
});
