import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    FlatList,
} from "react-native";
import { ActivityIndicator, List } from "react-native-paper";
import {
    UserData,
    platformAPI,
    Plan,
    Camera,
    invalidBearerToken,
    UnexpectedError,
} from "../../../services/platform-api";
import { translate } from "../../../services/service-translate";
import DefaultPageContainer from "../../../components/DefaultPageContainer";
import Toast from "react-native-toast-message";

const headerSize = 60;
const footerSize = 40;

interface CamPlans {
    navigation: Navigation;
    route: {
        params: {
            id: number;
        };
    };
    authenticatedUser?: UserData;
}

export default function CameraPlan({ route, navigation, authenticatedUser }: CamPlans) {
    const [plans, setPlans] = useState<Plan[]>([]);
    const [loading, setLoading] = useState(true);
    const [camera, setCamera] = useState<Camera>();
    const [camPlanItem, setCamPlanItem] = useState<Plan>();
    const param = route.params;

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    useEffect(() => {
        checkPermissions();
    }, [authenticatedUser]);

    function goBack() {
        navigation.navigate("CrmCameraList");
    }

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            const camera = await platformAPI.getCameraForUpdate(param.id);
            const wl = await platformAPI.getWhitelabel();
            if (!authenticatedUser.permissions.can_edit_camera_options && !(authenticatedUser.id === camera.UserId || wl.id === 1)) {
                return goBack();
            }
            getPlans();
            setCamPlanItem(camera?.Plan);
            setCamera(camera);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    async function getPlans() {
        setLoading(true);
        try {
            const res = await platformAPI.getPlans();
            setLoading(false);
            const sortedPlans = res
                .sort((p1, p2) => p1.value - p2.value)
                .filter((plan) => plan.slug !== "aovivo");
            setPlans(sortedPlans);
        } catch (err) {
            setLoading(false);
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    async function updateCameraPlan() {
        setLoading(true);
        if (!camPlanItem || !camera) {
            setLoading(false);
            return;
        }
        try {
            await platformAPI.updateCameraPlan(camera.id, camPlanItem.id);
            setLoading(false);
            return goBack();
        } catch (err) {
            setLoading(false);
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            if (err instanceof UnexpectedError) {
                Toast.show({
                    type: 'error',
                    text1: translate("UNEXPECTED_ERROR"),
                });
                return;
            }
        }
    }

    const renderItem = ({ item }: { item: Plan; }) => (
        <>
            <View style={styles.viewButton}>
                {camPlanItem?.slug === item.slug ? (
                    <TouchableOpacity
                        style={styles.buttonActive}
                        onPress={() => setCamPlanItem(item)}
                        disabled
                    >
                        <View style={styles.button}>
                            <Text style={styles.textButton}>{translate(item.slug)}</Text>
                        </View>
                    </TouchableOpacity>
                ) : (
                    <TouchableOpacity
                        style={styles.button}
                        onPress={() => setCamPlanItem(item)}
                    >
                        <View style={styles.button}>
                            <Text style={styles.textButton}>{translate(item.slug)}</Text>
                        </View>
                    </TouchableOpacity>
                )}
            </View>
            <ItemSeparator />
        </>
    );

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={() => goBack()}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>
                            {translate("recordingPlans")}
                        </Text>
                    </View>
                </View>
                <View style={styles.saveView}>
                    {camPlanItem?.slug != camera?.Plan?.slug ? (
                        <TouchableOpacity onPress={() => updateCameraPlan()}>
                            <Text style={styles.save}>{translate("save")}</Text>
                        </TouchableOpacity>
                    ) : (
                        <Text style={styles.saveInvalid}>{translate("save")}</Text>
                    )}
                </View>
            </View>
            <FlatList
                bounces={false}
                data={plans}
                renderItem={renderItem}
                keyExtractor={(item: Plan) => item.slug}
            />
            <View style={styles.footerButton}>
                <ActivityIndicator
                    animating={loading}
                    style={[
                        styles.loading,
                        loading && { backgroundColor: "#FFFFFFF2" },
                    ]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer>

    );
}

const styles = StyleSheet.create({
    loading: {
        paddingVertical: 5,
        width: "10%",
        borderRadius: 10,
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    title: {
        textTransform: "capitalize",
        fontSize: 16,
    },
    footerButton: {
        position: "absolute",
        bottom: 50,
        left: 0,
        right: 0,
        justifyContent: "center",
        alignItems: "center",
        height: footerSize,
    },
    buttonNext: {
        backgroundColor: "red",
        borderRadius: 4,
        height: 45,
        width: "100%",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "row",
    },
    textButton: {
        fontSize: 26,
        color: "black",
        textAlign: "center",
        justifyContent: "center",
    },
    button: {
        flexDirection: "row",
        width: "100%",
        height: 85,
        justifyContent: "center",
        alignItems: "center",
    },
    viewButton: {
        flexDirection: "row",
    },
    buttonActive: {
        backgroundColor: "#9a9a9a4f",
        flexDirection: "row",
        width: "100%",
        height: 85,
        justifyContent: "center",
        alignItems: "center",
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    saveInvalid: {
        alignSelf: "flex-end",
        color: "rgba(255, 255, 255, 0.5)",
        fontSize: 20,
        marginRight: 15,
        cursor: "default",
        userSelect: "none",
    },
    save: {
        alignSelf: "flex-end",
        color: "#fff",
        fontSize: 20,
        marginRight: 15,
    },
    disabledIcon: {
        opacity: 0.4,
    },
    iconView: {
        alignItems: "flex-end",
        justifyContent: "center",
        alignContent: "flex-end",
    },
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    saveView: {
        alignItems: "flex-end",
        justifyContent: "center",
        alignContent: "flex-end",
    },
});
