import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    ListRenderItemInfo,
    FlatList,
    ScrollView,
} from "react-native";
import { List } from "react-native-paper";
import {
    platformAPI,
    GetUserAsaasPayments,
    invalidBearerToken,
} from "../../services/platform-api";
import { translate } from "../../services/service-translate";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { showActionSheet, Option } from "../../components/ActionSheet";
import moment from "moment";
import DefaultPageContainer from "../../components/DefaultPageContainer";


const headerSize = 60;
interface CrmUserListComponentProps {
    navigation: Navigation;
}

export default function HistoryPayments({
    navigation,
}: CrmUserListComponentProps) {
    const { showActionSheetWithOptions } = useActionSheet();
    const [payments, setPayments] = useState<GetUserAsaasPayments[]>([]);
    const [empty, setEmpty] = useState<boolean>(false);

    useEffect(() => {
        document.body.style.position = 'fixed';
    }, []);

    useEffect(() => {
        getPayments();
    }, []);

    async function getPayments() {
        try {
            const userPayments = await platformAPI.getUserPayments();
            setPayments(userPayments);

            if (userPayments.length === 0) {
                setEmpty(true);
            }
        } catch (err) {

            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }

            setEmpty(true);

            console.error(err);
        }
    }

    function goBack() {
        navigation.navigate("Camera", { screen: "More" });
    }

    function actionSheet(payment: GetUserAsaasPayments) {
        const options: Option[] = [];

        options.push({
            title: translate("chargeInvoice"), onClick: () => {
                window.open(payment.invoiceUrl, "_blank");
            }
        });

        if (payment.status.includes("PENDING") || payment.status.includes("OVERDUE")) {
            options.push({
                title: translate("invoice"),
                subtitle: translate("chargeNotYetReceived"),
                disabled: true,
                onClick: () => {
                    window.open(payment.invoice?.pdfUrl, "_blank");
                }
            });

        }

        if (payment.status.includes("RECEIVED")) {
            if (payment.invoice?.status.includes("AUTHORIZED")) {
                options.push({
                    title: translate("invoice"),
                    onClick: () => {
                        window.open(payment.invoice?.pdfUrl, "_blank");
                    }
                });
            } else {
                options.push({
                    title: translate("invoice"),
                    subtitle: translate("chargeNotYetReceived"),
                    disabled: true,
                    onClick: () => {
                        window.open(payment.invoice?.pdfUrl, "_blank");
                    }
                });
            }
        }

        showActionSheet(showActionSheetWithOptions, options);
    }

    const renderItem = ({ item }: ListRenderItemInfo<GetUserAsaasPayments>) => {

        return (
            <TouchableOpacity onPress={() => actionSheet(item)}>
                <View style={styles.ListInfo}>
                    <View style={styles.contentList}>
                        <Text style={[styles.propList, { justifyContent: "center" }]}>{moment(item.dueDate).format("DD/MM/YYYY")}</Text>
                    </View>
                    <View style={styles.contentList}>
                        <Text style={[styles.propList, { justifyContent: "center" }]}>{translate(item.status)}</Text>
                    </View>
                    <View style={styles.contentList}>
                        <Text style={[styles.propList, { justifyContent: "center" }]}>{item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                    </View>
                </View>
                <View style={styles.itemSeparator} />
            </TouchableOpacity>
        );
    };
    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("paymentsHistory")}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.topInformation}>
                <View style={styles.contentView}>
                    <Text style={[styles.propText, { justifyContent: "center" }]}>{translate("dueDate")}</Text>
                </View>
                <View style={styles.contentView}>
                    <Text style={[styles.propText, { justifyContent: "center" }]}>{translate("status")}</Text>
                </View>
                <View style={styles.contentView}>
                    <Text style={[styles.propText, { justifyContent: "center" }]}>{translate("value")}</Text>
                </View>
            </View>

            <View style={styles.itemSeparator} />

            <ScrollView
                bounces={false}
                style={{ height: 1 }}>
                <FlatList
                    data={Object(payments)}
                    renderItem={renderItem}
                />
                {empty ?
                    <View style={styles.noPaymentsView}>
                        <Text style={styles.messageText}>{translate("noPayments")}</Text>
                    </View>
                    : null}
            </ScrollView>
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    topInformation: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100",
        height: 50
    },
    contentView: {
        width: "33%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
    },
    propText: {
        fontSize: 14,
        fontWeight: "bold"
    },
    mainContainer: {
        flex: 1,
    },
    viewList: {
        width: "100%",
        height: '90%'
    },
    ListInfo: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100",
        height: 50
    },
    contentList: {
        width: "33%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
    },
    propList: {
        fontSize: 14,
    },
    itemSeparator: {
        height: 2,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    messageText: {
        fontSize: 16,
        fontWeight: '500',
        color: '#aeaeae',
        alignSelf: 'center',
        justifyContent: "center",
        alignItems: "center"

    },
    noPaymentsView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
});
