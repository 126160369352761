import React, { CSSProperties, useEffect, useRef } from 'react';
import { View } from 'react-native';

interface PlateImageProps {
    src: string | null;
    platePosition: string | null;
    style?: CSSProperties | undefined;
}

export default function PlateImage({ style, src, platePosition }: PlateImageProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const width = 100;
    const height = 50;
    const plateInfo = platePosition ? JSON.parse(platePosition) : null;

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        if (!src || !platePosition) {
            ctx.beginPath();
            ctx.setLineDash([]);
            ctx.moveTo(0, 0);
            ctx.lineTo(width, height);
            ctx.moveTo(0, height);
            ctx.lineTo(width, 0);
            ctx.strokeStyle = "red";
            ctx.lineWidth = 3;
            ctx.stroke();
            return;
        }

        const image = new Image();

        const rect = {
            minX: Infinity,
            maxX: 0,
            minY: Infinity,
            maxY: 0
        };

        for (const position of plateInfo) {
            if (position.x < rect.minX) {
                rect.minX = position.x;
            }

            if (position.x > rect.maxX) {
                rect.maxX = position.x;
            }

            if (position.y < rect.minY) {
                rect.minY = position.y;
            }

            if (position.y > rect.maxY) {
                rect.maxY = position.y;
            }
        }

        image.onload = () => {
            const resizeImageBy = Math.min(width / (rect.maxX - rect.minX), height / (rect.maxY - rect.minY));
            image.width *= resizeImageBy;
            image.height *= resizeImageBy;

            const xRatio = image.width / image.naturalWidth;
            const yRatio = image.height / image.naturalHeight;

            ctx.drawImage(
                image,
                rect.minX * xRatio * -1,
                rect.minY * yRatio * -1,
                image.width,
                image.height
            );
        };

        image.src = src;
    });

    return (
        <View>
            <canvas style={style} width={width} height={height} ref={canvasRef}></canvas>
        </View>
    );
}
