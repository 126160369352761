import React, { useEffect, useState } from 'react';
import { Image, StyleProp, ImageStyle } from 'react-native';

interface ImageProps {
    status?: number | undefined;
    src?: string | null;
    style?: StyleProp<ImageStyle> | undefined;
}

export default function VideoImage({ status, src, style }: ImageProps) {

    const FALLBACK_IMAGE = 'https://d1w4uh7ghtlh1q.cloudfront.net/public/images/home/placeholder_thumb.jpg';
    const [source, setSource] = useState('');

    function loadFallback() {
        setSource(FALLBACK_IMAGE);
    }

    useEffect(() => {
        if (src) {
            setSource(src);
            return;
        }
        setSource(FALLBACK_IMAGE);
    }, [src]);

    useEffect(() => {
        if (status != undefined && src) {
            if (status == 1) {
                setSource(src);
            } else {
                setSource(FALLBACK_IMAGE);
            }
        }
    }, [status]);

    return (
        <Image source={{ uri: source }} style={style} onError={loadFallback} resizeMode={'stretch'} />
    );
}


