import React, { useEffect, useState } from "react";
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    FlatList,
    Image,
    Modal,
} from "react-native";
import { List } from "react-native-paper";
import { translate } from "../../services/service-translate";
import { KitMarketing, platformAPI } from "../../services/platform-api";
import { invalidBearerToken } from "../../services/platform-api";
import moment from "moment";
import DefaultPageContainer from "../../components/DefaultPageContainer";

const headerSize = 60;

const paddingHorizontal = 16;
const maxImageWidth = 500;
const imageWidthHeightProportion = 1.728571429;

interface CrmUserListComponentProps {
    navigation: Navigation;
}

export default function MarketingKit({
    navigation,
}: CrmUserListComponentProps) {
    const [files, setFiles] = useState<KitMarketing[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<KitMarketing[]>([]);
    const [selectedSegment, setSelectedSegment] = useState<string>("");
    const [showSegments, setShowSegments] = useState<boolean>(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [itemHeight, setItemHeight] = useState(calculateItemHeight());

    useEffect(() => {

        document.body.style.position = "fixed";

        getFile();

        function handleResize() {
            setItemHeight(calculateItemHeight());
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function calculateItemHeight() {
        // remove left and right padding
        let imageWidth = window.innerWidth - (2 * paddingHorizontal);

        // check if value is bigger than max image width
        if (imageWidth > maxImageWidth) {
            imageWidth = maxImageWidth;
        }

        const imageHeight = imageWidth / imageWidthHeightProportion;

        // image height must respect the width proportion
        return imageHeight;
    }

    async function getFile() {
        try {
            const fileMarketing = await platformAPI.getMarketingKit();
            setFiles(fileMarketing);
            setFilteredFiles(fileMarketing);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }

            console.error(err);
        }
    }

    function goBack() {
        navigation.navigate("Camera", { screen: "More" });
    }

    const renderItem = ({ item }: { item: KitMarketing; }) => {
        return (
            <TouchableOpacity onPress={() => window.open(item.url, "_blank")}>
                <View style={[styles.item]}>
                    <Image
                        style={[styles.itemImage, { height: itemHeight }]}
                        source={{ uri: item.thumbnail }}
                    />
                    <View style={styles.itemContent}>
                        <Text style={styles.itemTitle}>{item.title}</Text>
                        <View style={styles.information}>
                            <View style={styles.itemDetails}>
                                <Text style={styles.itemDate}>
                                    {moment(item.createdAt).format("DD/MM/YYYY HH:mm")}
                                </Text>
                                <Text style={styles.itemSize}>{`(${item.size})`}</Text>
                            </View>
                            <View>
                                <Text style={styles.itemFormat}>{`${translate("Format")}: ${item.format}`}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </TouchableOpacity >
        );
    };

    const filterFiles = (segment: string) => {
        setSelectedSegment(segment);

        if (segment === "") {
            setFilteredFiles(files);
        } else {
            const filtered = files.filter((file) =>
                file.segments.includes(segment)
            );
            setFilteredFiles(filtered);
        }
    };

    const toggleSegments = () => {
        setShowSegments(!showSegments);
    };

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <Text style={styles.titleHeader}>{translate("marketingKit")}</Text>
                </View>
                <TouchableOpacity
                    style={styles.filterView}
                    onPress={toggleSegments}
                >
                    <Image
                        style={styles.filterImage}
                        source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_filter.png' }}
                    />
                </TouchableOpacity>
            </View>
            <Modal
                animationType="fade"
                transparent={true}
                visible={showSegments && files.length > 0}
                onRequestClose={() => {
                    setDeleteModalVisible(!deleteModalVisible);
                }}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.titleModal}>{translate("selectSegment")}</Text>
                        <ItemSeparator />
                        <FlatList
                            data={[
                                { label: translate("allCategories"), value: "" },
                                ...Array.from(new Set(files.flatMap((file) => file.segments))).map(
                                    (segment) => ({
                                        label: translate(segment),
                                        value: segment,
                                    })
                                ),
                            ]}
                            keyExtractor={(item) => item.value}
                            renderItem={({ item }) => (
                                <TouchableOpacity
                                    style={[
                                        styles.segmentItem,
                                        {
                                            backgroundColor:
                                                item.value === selectedSegment ? "#0071bc" : "#fff",
                                        },
                                    ]}
                                    onPress={() => filterFiles(item.value)}
                                >
                                    <Text
                                        style={[
                                            styles.segmentItemText,
                                            {
                                                color: item.value === selectedSegment ? "#fff" : "#0071bc",
                                            },
                                        ]}
                                    >
                                        {item.label}
                                    </Text>
                                </TouchableOpacity>
                            )}
                        />
                        <TouchableOpacity
                            style={styles.confirmButton}
                            onPress={toggleSegments}
                        >
                            <Text style={styles.confirmButtonText}>{translate("confirmCategories")}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
            <FlatList
                numColumns={1}
                data={filteredFiles}
                renderItem={renderItem}
                contentContainerStyle={styles.flatListContainer}
                style={[styles.scrollView, { height: 1 }]}
            />
        </DefaultPageContainer >

    );
}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: 15,
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    scrollView: {
        width: "100%",
        display: "flex",
    },
    titleHeader: {
        fontSize: 26,
        color: "#fff",
        marginLeft: 15,
    },
    item: {
        width: "100%",
        maxWidth: maxImageWidth,
        backgroundColor: "#9a9a9a4f",
        alignItems: "center",
        paddingBottom: 10,
        borderRadius: 14,
        justifyContent: "center",
        marginBottom: 16,
        borderWidth: 1,
        borderColor: "#333333",
        marginTop: 10
    },
    itemImage: {
        width: "100%",
        height: "100%",
        borderTopLeftRadius: 14,
        borderTopRightRadius: 14,
        resizeMode: "stretch",
    },
    itemContent: {
        width: "100%",
        alignItems: "flex-start",
        justifyContent: "space-between",
        paddingHorizontal: 10,
    },
    itemTitle: {
        fontSize: 16,
        fontWeight: "500",
        textAlign: "left",
        marginTop: 8,
        marginBottom: 5,
        color: "#101010",
    },
    information: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    itemDetails: {
        flexDirection: "row",
        alignItems: "flex-end",
        flex: 1,
    },
    itemDate: {
        fontSize: 14,
        textAlign: "left",
    },
    itemSize: {
        fontSize: 14,
        textAlign: "left",
        marginLeft: 5,
    },
    itemFormat: {
        fontSize: 14,
        textAlign: "right",
        marginLeft: 10,
    },
    emptyContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    emptyText: {
        fontSize: 16,
        color: "#666",
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    modalView: {
        backgroundColor: "#fff",
        width: 350,
        borderRadius: 8,
        paddingVertical: 20,
        paddingHorizontal: 30,
        marginHorizontal: 20,
        alignItems: "center",
    },
    titleModal: {
        fontSize: 20,
        fontWeight: "bold",
        marginBottom: 20,
    },
    segmentItem: {
        marginTop: 10,
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 8,
        marginBottom: 10,
        width: 250
    },
    segmentItemText: {
        fontSize: 16,
        textAlign: 'center'
    },
    confirmButton: {
        marginTop: 20,
        width: 250,
        textAlign: 'center',
        backgroundColor: "red",
        borderRadius: 8,
        paddingVertical: 10,
        paddingHorizontal: 30,
    },
    confirmButtonText: {
        fontSize: 16,
        color: '#fff',
        textAlign: 'center'
    },
    filterView: {
        width: 40,
        height: 40,
        alignItems: "center",
        justifyContent: "center",
    },
    filterImage: {
        width: 25,
        height: 25,
        tintColor: "#fff",
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#ccc",
        marginVertical: 10,
    },
    flatListContainer: {
        paddingHorizontal: paddingHorizontal,
        paddingBottom: 20
    }
});
