import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, SafeAreaView, Image, TouchableOpacity } from 'react-native';
import { CameraList, platformAPI, invalidBearerToken } from '../services/platform-api';
import { Switch } from 'react-native-elements';
import { translate } from '../services/service-translate';

type Navigation = {
    navigation: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        navigate: (page: string, params?: any) => void;
        goBack: () => void;
    };
    route: {
        params: CameraList;
    };
};

export default function CameraNotification({ navigation, route }: Navigation) {

    const [allNotifications, setAllNotifications] = useState(false);
    const [movement, setMovement] = useState(false);
    const [status, setStatus] = useState(false);
    const cameraParam = route.params;

    async function getNotifications() {
        try{
            const res = await platformAPI.getCameraNotification(cameraParam.id);

            setAllNotifications(res.all);
            setMovement(res.movement);
            setStatus(res.status);
        } catch(err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    async function setNotifications() {
        await platformAPI.setCameraNotification({
            all: allNotifications,
            movement: movement,
            status: status,
            camId: cameraParam.id
        });

        getNotifications();
        navigation.goBack();
    }

    useEffect(() => {
        getNotifications();

        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
    }, []);

    return(
        <SafeAreaView style={styles.container}>
            <View style={styles.backgroundView}>
                <View style={styles.header}>
                    <View style={styles.headerTitle}>
                        <TouchableOpacity style={styles.cancelButton} onPress={() => navigation.goBack()}>
                            <Image style={styles.cancelButtonText} source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_close.png' }} />
                        </TouchableOpacity>
                        <Text style={styles.textTitle}>{cameraParam.title}</Text>
                    </View>
                    <View style={styles.saveButtonView}>
                        <TouchableOpacity style={styles.saveButton} onPress={setNotifications}>
                            <Text style={styles.saveButtonText}>{translate('save_caps')}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.contentView}>
                    <Text style={styles.contentText}>{translate('allNotifications')}</Text>
                    <Switch
                        color='#263483'
                        style={styles.switches}
                        value={allNotifications}
                        onValueChange={(value) => setAllNotifications(value)}
                    />
                </View>
                <View style={styles.contentView}>
                    <Text style={styles.contentText}>{translate('moveNotification')}</Text>
                    <Switch
                        color='#263483'
                        style={styles.switches}
                        value={movement}
                        onValueChange={(value) => setMovement(value)}
                    />
                </View>
                <View style={styles.contentView}>
                    <Text style={styles.contentText}>{translate('statusNotification')}</Text>
                    <Switch
                        color='#263483'
                        style={styles.switches}
                        value={status}
                        onValueChange={(value) => setStatus(value)}
                    />
                </View>

            </View>
        </SafeAreaView>
    );
}
const styles = StyleSheet.create({
    container: {
        backgroundColor: "#0071bc",
        flex: 1
    },
    backgroundView: {
        flex: 1,
        backgroundColor: '#fff'
    },
    header: {
        height: 60,
        backgroundColor: '#0071bc',
        width: '100%',
        flexDirection: 'row',
    },
    headerTitle: {
        width: '80%',
        height: 60,
        justifyContent: 'flex-start',
        flexDirection: 'row',
    },
    cancelButton: {
        justifyContent: 'center'
    },
    cancelButtonText: {
        marginLeft: 15,
        width: 30,
        height: 30,
        alignSelf: 'center'
    },
    textTitle: {
        alignSelf: 'center',
        color: "#fff",
        fontSize: 26,
        marginLeft: 25
    },
    saveButtonView: {
        width: "20%",
        height: 60,
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    saveButton: {
        width: 40
    },
    saveButtonText: {
        color: "#fff",
        fontSize: 16,
        alignSelf: 'flex-end',
        marginRight: 15,
        fontWeight: 'bold'
    },
    contentView: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 20,
        marginBottom: 20
    },
    contentText: {
        marginLeft: 20,
        fontSize: 16
    },
    switches: {
        alignSelf: 'flex-end',
        marginRight: 20
    },
});
