import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View, Text, StyleSheet, TextInput, useWindowDimensions, NativeSyntheticEvent, TextInputKeyPressEventData, FlatList, LayoutChangeEvent, RefreshControl, ListRenderItemInfo, Modal } from 'react-native';
import { List } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { translate } from '../../../services/service-translate';
import { UserData, BlacklistGroup, PageParam, forbiddenAccess, invalidBearerToken, platformAPI } from '../../../services/platform-api';
import { showActionSheet, Option } from '../../../components/ActionSheet';
import { useActionSheet } from '@expo/react-native-action-sheet';
import DefaultPageContainer from '../../../components/DefaultPageContainer';

const headerSize = 60;
const searchBarButtons = 56;

interface LprGroupProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}

export default function LprGroups({ navigation, authenticatedUser }: LprGroupProps) {
    const insets = useSafeAreaInsets();
    const { showActionSheetWithOptions } = useActionSheet();

    const [showSearchBar, setShowSearchBar] = useState(false);
    const [blacklistGroups, setBlacklistGroups] = useState<BlacklistGroup[]>([]);
    const [lprGroupToBeDeleted, setLprGroupToBeDeleted] = useState<BlacklistGroup>();
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emptyList, setEmptyList] = useState(false);
    const [search, setSearch] = useState('');
    const [onChangeSearch, setOnChangeSearch] = useState(false);
    const [viewHeight, setViewHeight] = useState(0);
    const windowWidth = useWindowDimensions().width;
    const [contentHeight, setContentHeight] = useState(0);
    const [renderItemHeight, setRenderItemHeight] = useState(0);
    const [canEditLprBlacklist, setCanEditLprBlacklist] = useState(false);
    const [searchParams, setSearchParams] = useState<PageParam>({
        page: 1,
        filter: search
    });

    async function loadCrmBlacklistGroups() {
        if (!searchParams.page || !authenticatedUser) return;
        try {
            setLoading(true);
            if (searchParams.page === 1) {
                setRefreshing(true);
            }
            const lprGroups = await platformAPI.getBlacklistGroups(searchParams);

            if (lprGroups.length === 0 && searchParams.page === 1) {

                if (!authenticatedUser.permissions.can_see_lpr_blacklist) {
                    navigation.navigate("Camera", { screen: "More" });
                }

                setBlacklistGroups([]);
                setEmptyList(true);
                setLoading(false);
                setRefreshing(false);
                return;
            }

            if (searchParams.page === 1) {
                setBlacklistGroups(lprGroups);
            } else {
                setBlacklistGroups([...blacklistGroups, ...lprGroups]);
            }

            setEmptyList(false);
            setLoading(false);
            setRefreshing(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Camera", { screen: "More" });
                return;
            }
            console.error(err);
        }
    }

    async function checkPermissions() {
        if (!searchParams.page || !authenticatedUser) return;
        try {
            const privatePreview = await platformAPI.getPrivatePreview();
            const blacklist_by_group = Object.hasOwn(privatePreview, 'BLACKLIST_BY_GROUP');

            if (!blacklist_by_group) {
                navigation.navigate("Camera", { screen: "More" });
            }
            setCanEditLprBlacklist(authenticatedUser.permissions.can_edit_lpr_blacklist);

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            setSearchParams({
                page: 1,
                filter: search
            });
        });
        return unsubscribe;
    }, [navigation]);

    useEffect(() => {

        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
        document.body.style.position = "fixed";
        checkPermissions();

        function handleResize() {
            setViewHeight(
                window.innerHeight - headerSize - insets.bottom - insets.top
            );
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [authenticatedUser]);

    useEffect(() => {
        loadCrmBlacklistGroups();
    }, [searchParams, authenticatedUser]);

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
    }, [insets]);

    useEffect(() => {
        if (contentHeight === 0 || contentHeight > viewHeight) return;

        if (renderItemHeight === 0 || !blacklistGroups.length || blacklistGroups.length * renderItemHeight > contentHeight) return;

        handleLoadMore();
    }, [contentHeight, renderItemHeight, viewHeight]);

    const onChangeSearchBar = (val: string) => {
        setSearch(val);
        if (val != '') {
            return setOnChangeSearch(true);
        }
        setOnChangeSearch(false);
    };

    function cleanSearchBar() {
        setSearchParams({
            page: 1,
            filter: ''
        });
        setSearch('');
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            setSearchParams({
                page: 1,
                filter: search
            });
        }
    }

    function handleLoadMore() {
        if (!searchParams.page) {
            return;
        }
        if (loading) return;

        if (emptyList) {
            setLoading(false);
        } else {
            setSearchParams({
                ...searchParams,
                page: searchParams.page + 1,
            });
        }
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    function goBack() {
        navigation.navigate("Camera", { screen: "More" });
    }

    async function handleRefresh() {
        setSearchParams({
            ...searchParams,
            page: 1,
        });
    }

    function openActionSheet(lprGroup: BlacklistGroup) {
        setLprGroupToBeDeleted(lprGroup);
        const options: Option[] = [];

        options.push({
            title: translate("seeBlacklist"),
            onClick: () => navigation.navigate('Blacklist', { id: lprGroup.id })
        });

        options.push({
            title: translate("editRegistration"),
            onClick: () => navigation.navigate('LprGroupForm', { id: lprGroup.id })
        });

        options.push({
            title: translate("delete"),
            destructive: true,
            onClick: () => {
                setDeleteModalVisible(true);
            }
        });

        showActionSheet(showActionSheetWithOptions, options);
    }

    async function deleteLprGroup() {
        if (!lprGroupToBeDeleted) {
            return;
        }
        setLoading(true);
        try {
            await platformAPI.deleteLprGroup(lprGroupToBeDeleted.id);
            setDeleteModalVisible(false);
            handleRefresh();
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    const renderItem = ({ item }: ListRenderItemInfo<BlacklistGroup>) => {
        return (
            <>
                <TouchableOpacity onPress={() => canEditLprBlacklist ? openActionSheet(item) : navigation.navigate('Blacklist', { id: item.id })}>
                    <View
                        style={styles.itemContainer}
                        onLayout={(e: LayoutChangeEvent) => {
                            if (renderItemHeight === 0) setRenderItemHeight(e.nativeEvent.layout.height);
                        }}
                    >
                        <Text style={styles.title} numberOfLines={1} adjustsFontSizeToFit>
                            {item.title}
                        </Text>
                        <Text numberOfLines={1} adjustsFontSizeToFit style={styles.subTitle}>
                            {translate('platesOnList', {
                                plates: item.blacklists,
                                listType: translate(item.whitelist ? 'whitelist' : 'blacklist')
                            })}
                        </Text>
                    </View>
                </TouchableOpacity>
            </>
        );
    };

    return (
        <DefaultPageContainer>
            {showSearchBar ?
                <View style={styles.header}>
                    <View style={styles.bothSides}>
                        <TouchableOpacity
                            style={styles.leftButton}
                            onPress={() => {
                                setShowSearchBar(false);
                                cleanSearchBar();
                            }}
                        >
                            <List.Icon
                                style={styles.backArrow}
                                icon="arrow-left"
                                color="#fff"
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.searchInput}>
                        <TextInput
                            selectionColor='#0071bc'
                            style={[styles.searchInput, { width: windowWidth - (searchBarButtons * 3) }]}
                            value={search}
                            onKeyPress={handleKeyDown}
                            onChangeText={text => {
                                setSearch(text);
                                onChangeSearchBar(text);
                            }}
                            autoFocus
                        />
                    </View>
                    <View style={styles.bothSides}>
                        {onChangeSearch ?
                            <TouchableOpacity style={styles.closeButton} onPress={cleanSearchBar}>
                                <List.Icon
                                    icon='close'
                                    color='#fff' />
                            </TouchableOpacity>
                            : null}
                        <TouchableOpacity
                            style={styles.searchButton}
                            onPress={() => loadCrmBlacklistGroups()}
                        >
                            <List.Icon
                                style={styles.backArrow}
                                icon="magnify"
                                color="#fff"
                            />
                        </TouchableOpacity>
                    </View>
                </View>
                :
                <View style={styles.header}>
                    <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity style={styles.leftButton} onPress={goBack}>
                            <List.Icon
                                style={styles.backArrow}
                                icon="arrow-left"
                                color="#fff"
                            />
                        </TouchableOpacity>
                        <View style={styles.titleHeaderView}>
                            <Text style={styles.titleHeader}>{translate('LprGroups')}</Text>
                        </View>
                    </View>
                    <View style={styles.registerView}>
                        <TouchableOpacity style={styles.searchButton} onPress={() => setShowSearchBar(true)}>
                            {showSearchBar ? null : <List.Icon
                                icon="magnify"
                                style={styles.searchIcon}
                                color='#fff'
                            />}
                        </TouchableOpacity>
                        {canEditLprBlacklist ?
                            <TouchableOpacity style={styles.addButton} onPress={() => navigation.navigate("LprGroupForm")}>
                                <List.Icon
                                    style={styles.register}
                                    icon="plus"
                                    color="#fff"
                                />
                            </TouchableOpacity>
                            : null
                        }
                    </View>
                </View>}

            <Modal
                animationType='fade'
                transparent={true}
                visible={deleteModalVisible}
                onRequestClose={() => {
                    setDeleteModalVisible(!deleteModalVisible);
                }}>
                <View style={styles.fadeView} />
                <View style={styles.centeredView}>
                    <View style={[styles.containerModal, { marginTop: viewHeight / 2.2 }]}>
                        <Text style={styles.titleModal} numberOfLines={2} adjustsFontSizeToFit>{translate("deleteGroupConfirmation")}{"\n"}{lprGroupToBeDeleted?.title}</Text>
                        <View style={styles.viewButtonsModal}>
                            <TouchableOpacity style={styles.buttonCloseModal} onPress={() => setDeleteModalVisible(!deleteModalVisible)}>
                                <Text style={styles.textButtonCloseModal}>{translate('no')}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.buttonDeleteModal} onPress={() => deleteLprGroup()}>
                                <Text style={styles.textButtonModal}>{translate('yes')}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>


            {!blacklistGroups.length ? (
                !loading ? (
                    <View style={styles.noResults}>
                        <Text>{translate("noResults")}</Text>
                    </View>
                ) : <></>
            ) : (
                <FlatList
                    style={[styles.scrollView, { height: 1 }]}
                    bounces={false}
                    data={blacklistGroups}
                    keyExtractor={(item) => item.id.toString()}
                    renderItem={renderItem}
                    removeClippedSubviews={true}
                    updateCellsBatchingPeriod={100}
                    windowSize={12}
                    ItemSeparatorComponent={ItemSeparator}
                    nestedScrollEnabled
                    onEndReached={() => {
                        handleLoadMore();
                    }}
                    onEndReachedThreshold={0.03}
                    refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={handleRefresh}
                        />
                    }
                    onLayout={(e: LayoutChangeEvent) => {
                        setContentHeight(e.nativeEvent.layout.height);
                    }}
                />
            )}
        </DefaultPageContainer>
    );
}
const styles = StyleSheet.create({
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    bothSides: {
        flexDirection: "row",
        alignItems: "center"
    },
    leftButton: {
        alignSelf: 'center',
        width: searchBarButtons
    },
    closeButton: {
        alignSelf: 'center',
        width: searchBarButtons
    },
    searchButton: {
        alignSelf: 'center',
        width: searchBarButtons
    },
    addButton: {
        alignSelf: 'center',
        width: searchBarButtons
    },
    backArrow: {
        alignSelf: "flex-start"
    },
    searchInput: {
        height: '100%',
        color: '#fff',
        fontSize: 20,
        flexGrow: 1
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    registerView: {
        flexDirection: "row",
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    searchIcon: {
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: "center"
    },
    register: {
        alignSelf: "flex-end",
    },
    listColumns: {
        height: 60,
        borderBottomColor: '#9a9a9a4f',
        borderBottomWidth: 2,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    columnTitle: {
        fontWeight: "bold"
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    scrollView: {
        width: "100%",
        height: "100%",
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    itemContainer: {
        flexDirection: 'column',
        justifyContent: "space-evenly",
        alignItems: "flex-start",
        height: 60,
        gap: 5,
        paddingVertical: 10,
        paddingLeft: 20,
        width: "100%"
    },
    title: {
        fontSize: 16,
    },
    subTitle: {
        fontSize: 12,
    },
    fadeView: {
        flex: 1,
        position: 'relative',
        backgroundColor: 'black',
        opacity: 0.25
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
    },
    containerModal: {
        backgroundColor: "#fff",
        borderRadius: 20,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 400,
        height: 140,
        justifyContent: 'center',
        justifyItems: 'center',
        gap: 15
    },
    titleModal: {
        numberOfLines: 1,
        fontSize: 17,
        maxWidth: "80%",
        textAlign: 'center'
    },
    viewButtonsModal: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonCloseModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: '#fff'
    },
    buttonDeleteModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: 'red'
    },
    textButtonModal: {
        color: '#fff'
    },
    textButtonCloseModal: {
        color: "#000",
    },
});
