import React, { useEffect, useState } from 'react';
import {
    TouchableOpacity,
    View,
    Text,
    StyleSheet,
    TextInput,
    useWindowDimensions,
    NativeSyntheticEvent,
    TextInputKeyPressEventData,
    FlatList,
    RefreshControl,
    ListRenderItemInfo,
    LayoutChangeEvent
} from 'react-native';
import { List } from 'react-native-paper';
import { useNavigationState } from '@react-navigation/native';
import { UserData, BlacklistItem, BlacklistParams, forbiddenAccess, invalidBearerToken, platformAPI } from '../../../services/platform-api';
import { translate } from '../../../services/service-translate';
import { showActionSheet, Option } from '../../../components/ActionSheet';
import { useActionSheet } from '@expo/react-native-action-sheet';
import DefaultPageContainer from '../../../components/DefaultPageContainer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const headerSize = 60;
const searchBarButtons = 56;

interface BlacklistComponentProps {
    navigation: Navigation;
    route: {
        params?: { id: string; };
    };
    authenticatedUser?: UserData;
}


export default function Blacklist({ navigation, route, authenticatedUser }: BlacklistComponentProps) {
    const cameraGroupId = route.params?.id;
    const windowWidth = useWindowDimensions().width;
    const index = useNavigationState(state => state);
    const { showActionSheetWithOptions } = useActionSheet();
    const insets = useSafeAreaInsets();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [search, setSearch] = useState('');
    const [onChangeSearch, setOnChangeSearch] = useState(false);
    const [blacklist, setBlacklist] = useState<BlacklistItem[]>([]);
    const [refreshing, setRefreshing] = useState(false);
    const [privatePreviewLprGroups, setPrivatePreviewLprGroups] = useState(false);
    const [searchParams, setSearchParams] = useState<BlacklistParams>({
        page: 1,
        filter: search,
        cameraGroupId: cameraGroupId
    });
    const [whitelist, setWhitelist] = useState(false);
    const [userManaged, setUserManaged] = useState(false);
    const [canEditBlacklist, setCanEditBlacklist] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emptyList, setEmptyList] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [renderItemHeight, setRenderItemHeight] = useState(0);
    const [viewHeight, setViewHeight] = useState(0);

    async function getBlacklistGroup(id: string) {
        if (!authenticatedUser) return;

        const lprGroup = await platformAPI.getBlacklistGroup(id);
        setWhitelist(lprGroup.whitelist);
        setUserManaged(lprGroup.userManaged);

        if (authenticatedUser.permissions.can_edit_lpr_blacklist) {
            setCanEditBlacklist(true);
        } else {
            for (const user of lprGroup.Users) {
                if (user.id == authenticatedUser.id) {
                    setCanEditBlacklist(user.LprGroupUser.canEdit);
                }
            }
        }

    }

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
        document.body.style.position = "fixed";
        checkPermissions();
        if (cameraGroupId) {
            getBlacklistGroup(cameraGroupId);
        }

        function handleResize() {
            setViewHeight(
                window.innerHeight - headerSize - insets.bottom - insets.top
            );
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [authenticatedUser]);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            setSearchParams({
                page: 1,
                filter: search,
                cameraGroupId: cameraGroupId
            });
        });
        return unsubscribe;
    }, [navigation]);


    useEffect(() => {
        loadCrmBlacklist();
    }, [searchParams]);

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
    }, [insets]);

    useEffect(() => {
        if (contentHeight === 0 || contentHeight > viewHeight) return;

        if (renderItemHeight === 0 || !blacklist.length || blacklist.length * renderItemHeight > contentHeight) return;

        handleLoadMore();
    }, [contentHeight, renderItemHeight, viewHeight]);

    async function checkPermissions() {
        try {
            const privatePreview = await platformAPI.getPrivatePreview();
            const blacklist_by_group = Object.hasOwn(privatePreview, 'BLACKLIST_BY_GROUP');

            setPrivatePreviewLprGroups(blacklist_by_group);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    function handleLoadMore() {
        if (!searchParams.page) {
            return;
        }
        if (loading) return;

        if (emptyList) {
            setLoading(false);
        } else {
            setSearchParams({
                ...searchParams,
                page: searchParams.page + 1,
            });
        }
    }

    async function filteredBlacklist() {
        try {
            setLoading(true);

            if (search === searchParams.filter) {
                return setLoading(false);
            }

            if (search === '') {
                return cleanSearchBar();
            }

            setRefreshing(true);
            setSearchParams({
                page: 1,
                filter: search,
                cameraGroupId: cameraGroupId
            });

            const _blacklist = await platformAPI.getBlacklist(searchParams);

            if (_blacklist.length === 0) {
                setEmptyList(true);
                setLoading(false);
                setRefreshing(false);
                return;
            }

            if (searchParams.page === 1) {
                setBlacklist(_blacklist);
            } else {
                setBlacklist([...blacklist, ..._blacklist]);
            }

            setEmptyList(false);
            setLoading(false);
            setRefreshing(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Camera", { screen: "More" });
                return;
            }
            console.error(err);
        }
    }

    function cleanSearchBar() {
        setSearchParams({
            page: 1,
            filter: '',
            cameraGroupId: cameraGroupId
        });
        setSearch('');
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            filteredBlacklist();
        }
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    const onChangeSearchBar = (val: string) => {
        setSearch(val);
        if (val != '') {
            return setOnChangeSearch(true);
        }
        setOnChangeSearch(false);
    };

    function goBack() {
        if (index.routes.length > 1 && privatePreviewLprGroups) {
            navigation.navigate("LprGroups");
            return;
        }
        if (privatePreviewLprGroups) {
            window.location.href = '/crm/lpr/groups';
            return;
        }
        navigation.navigate("Camera", { screen: "More" });
    }

    async function handleRefresh() {
        setSearchParams({
            ...searchParams
        });
    }

    async function loadCrmBlacklist() {
        if (!searchParams.page) {
            return;
        }
        try {
            setLoading(true);
            if (searchParams.page === 1) {
                setRefreshing(true);
            }
            const _blacklist = await platformAPI.getBlacklist(searchParams);

            if (_blacklist.length === 0 && searchParams.page === 1) {
                setBlacklist([]);
                setEmptyList(true);
                setLoading(false);
                setRefreshing(false);
                return;
            }

            if (searchParams.page === 1) {
                setBlacklist(_blacklist);
            } else {
                setBlacklist([...blacklist, ..._blacklist]);
            }

            setEmptyList(false);
            setLoading(false);
            setRefreshing(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Camera", { screen: "More" });
                return;
            }
            console.error(err);
        }
    }

    async function deleteBlacklistItem(blacklistItem: BlacklistItem) {

        setRefreshing(true);
        try {
            if (cameraGroupId) {
                await platformAPI.deleteBlacklistItem({ plate: blacklistItem.plate, cameraGroup: { cameraGroupId: cameraGroupId, whitelist: whitelist } });
            } else {
                await platformAPI.deleteBlacklistItem({ plate: blacklistItem.plate });
            }
            handleRefresh();
            setRefreshing(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
            setRefreshing(false);
        }


    }

    function openActionSheet(blacklistItem: BlacklistItem) {
        const options: Option[] = [];

        options.push({
            title: translate("editDescription"),
            onClick: () => navigation.navigate('BlacklistForm', { id: blacklistItem.id, cameraGroupId: cameraGroupId ? cameraGroupId : '' }),
            disabled: userManaged ? blacklistItem.user_external_id != authenticatedUser?.id : false,
            subtitle: userManaged && blacklistItem.user_external_id != authenticatedUser?.id ? translate('onlyCreatorCanEditBlacklistItem') : undefined
        });

        options.push({
            title: translate("delete"),
            destructive: true,
            onClick: () => deleteBlacklistItem(blacklistItem),
            disabled: userManaged ? blacklistItem.user_external_id != authenticatedUser?.id : false,
            subtitle: userManaged && blacklistItem.user_external_id != authenticatedUser?.id ? translate('onlyCreatorCanDeleteBlacklistItem') : undefined
        });

        showActionSheet(showActionSheetWithOptions, options);
    }


    const renderItem = ({ item }: ListRenderItemInfo<BlacklistItem>) => {
        return (
            <TouchableOpacity onPress={() => canEditBlacklist ? openActionSheet(item) : null}>
                <View
                    style={styles.itemContainer}
                    onLayout={(e: LayoutChangeEvent) => {
                        if (renderItemHeight === 0) setRenderItemHeight(e.nativeEvent.layout.height);
                    }}
                >
                    <Text numberOfLines={1} adjustsFontSizeToFit style={styles.title}>
                        {item.plate}
                    </Text>
                    <Text numberOfLines={1} adjustsFontSizeToFit style={styles.subTitle}>
                        {item.desc}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    };

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                {showSearchBar ? <>
                    <View style={styles.bothSides}>
                        <TouchableOpacity
                            style={styles.leftButton}
                            onPress={() => {
                                setShowSearchBar(false);
                                cleanSearchBar();
                            }}
                        >
                            <List.Icon
                                style={styles.backArrow}
                                icon="arrow-left"
                                color="#fff"
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.searchInput}>
                        <TextInput
                            selectionColor='#0071bc'
                            style={[styles.searchInput, { width: windowWidth - (searchBarButtons * 3) }]}
                            value={search}
                            onKeyPress={handleKeyDown}
                            onChangeText={text => {
                                setSearch(text);
                                onChangeSearchBar(text);
                            }}
                            autoFocus
                        />
                    </View>
                    <View style={styles.bothSides}>
                        {onChangeSearch ?
                            <TouchableOpacity style={styles.closeButton} onPress={cleanSearchBar}>
                                <List.Icon
                                    icon='close'
                                    color='#fff' />
                            </TouchableOpacity>
                            : null}
                        <TouchableOpacity
                            style={styles.searchButton}
                            onPress={filteredBlacklist}
                        >
                            <List.Icon
                                style={styles.backArrow}
                                icon="magnify"
                                color="#fff"
                            />
                        </TouchableOpacity>
                    </View>
                </> : <>
                    <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity style={styles.leftButton} onPress={() => goBack()}>
                            <List.Icon
                                style={styles.backArrow}
                                icon="arrow-left"
                                color="#fff"
                            />
                        </TouchableOpacity>
                        <View style={styles.titleHeaderView}>
                            <Text style={styles.titleHeader}>{translate('blacklist')}</Text>
                        </View>
                    </View>
                    <View style={styles.registerView}>
                        <TouchableOpacity style={styles.searchButton} onPress={() => setShowSearchBar(true)}>
                            {showSearchBar ? null : <List.Icon
                                icon="magnify"
                                style={styles.searchIcon}
                                color='#fff'
                            />}
                        </TouchableOpacity>
                        {canEditBlacklist ?
                            <TouchableOpacity style={styles.addButton} onPress={() => navigation.navigate('BlacklistForm', { cameraGroupId: cameraGroupId ? cameraGroupId : '' })}>
                                <List.Icon
                                    style={styles.register}
                                    icon="plus"
                                    color="#fff"
                                />
                            </TouchableOpacity>
                            : null
                        }
                    </View>
                </>}
            </View>

            {!blacklist.length ? (
                !refreshing ? (
                    <View style={styles.noResults}>
                        <Text>{translate("noResults")}</Text>
                    </View>
                ) : <></>
            ) : (
                <FlatList
                    style={[styles.scrollView, { height: viewHeight }]}
                    bounces={false}
                    data={blacklist}
                    keyExtractor={(item) => item.id.toString()}
                    renderItem={renderItem}
                    removeClippedSubviews={true}
                    updateCellsBatchingPeriod={100}
                    windowSize={12}
                    ItemSeparatorComponent={ItemSeparator}
                    onEndReached={() => {
                        handleLoadMore();
                    }}
                    onEndReachedThreshold={0.03}
                    refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={handleRefresh}
                        />
                    }
                    onLayout={(e: LayoutChangeEvent) => {
                        setContentHeight(e.nativeEvent.layout.height);
                    }}
                />
            )}
        </DefaultPageContainer>
    );

}

const styles = StyleSheet.create({
    backgroundView: {
        flex: 1,
        backgroundColor: "#fff",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    bothSides: {
        flexDirection: "row",
        alignItems: "center"
    },
    leftButton: {
        alignSelf: 'center',
        width: searchBarButtons
    },
    backArrow: {
        alignSelf: "flex-start"
    },
    searchInput: {
        height: '100%',
        color: '#fff',
        fontSize: 20,
        flexGrow: 1
    },
    closeButton: {
        alignSelf: 'center',
        width: searchBarButtons
    },
    searchButton: {
        alignSelf: 'center',
        width: searchBarButtons
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    titleHeader: {
        alignSelf: "flex-start",
        fontSize: 26,
        color: "#fff",
    },
    registerView: {
        flexDirection: "row",
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    searchIcon: {
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: "center"
    },
    addButton: {
        alignSelf: 'center',
        width: searchBarButtons
    },
    register: {
        alignSelf: "flex-end",
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    scrollView: {
        width: "100%",
        height: "100%",
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    itemContainer: {
        flexDirection: 'column',
        justifyContent: "space-evenly",
        alignItems: "flex-start",
        height: 60,
        gap: 5,
        paddingVertical: 10,
        paddingLeft: 20,
        width: "100%"
    },
    title: {
        fontSize: 16,
    },
    subTitle: {
        fontSize: 12,
    },
});
