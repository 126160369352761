import React, { useState, useEffect, useCallback } from "react";
import {
    platformAPI,
    invalidBearerToken,
    forbiddenAccess,
    GetCrmCamerasParams,
    CrmCameras,
    UnexpectedError,
    DeleteReason,
    UserData,
} from "../../../services/platform-api";
import { translate } from '../../../services/service-translate';
import { ActivityIndicator, List } from "react-native-paper";
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    LayoutChangeEvent,
    ListRenderItemInfo,
    ScrollView,
    TextInput,
    Modal,
    Image,
} from 'react-native';
import {
    useSafeAreaInsets,
} from "react-native-safe-area-context";
import '../../../styles/tooltips.css';
import { showActionSheet, Option } from "../../../components/ActionSheet";
import { RefreshControl } from "react-native-web-refresh-control";
import { FlatList } from "react-native-gesture-handler";
import { useFocusEffect } from "@react-navigation/native";
import { useActionSheet } from "@expo/react-native-action-sheet";
import DefaultPageContainer from "../../../components/DefaultPageContainer";
import CamFilter from "../../../components/CameraFilter";
import Toast from "react-native-toast-message";
import DropDownPicker from "react-native-dropdown-picker";

interface cameraComponentProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}
const headerSize = 60;
const footerSize = 30;
const buttonsSize = 56;

export default function CrmCameraList({ navigation, authenticatedUser }: cameraComponentProps) {
    const insets = useSafeAreaInsets();
    const [emptyList, setEmptyList] = useState(false);
    const [cameraList, setCameraList] = useState<CrmCameras[]>([]);
    const [viewHeight, setViewHeight] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [contentHeight, setContentHeight] = useState(0);
    const [renderItemHeight, setRenderItemHeight] = useState(0);
    const { showActionSheetWithOptions } = useActionSheet();
    const [searchParams, setSearchParams] = useState<GetCrmCamerasParams>({
        online: true,
        offline: true,
        disabled: true,
        page: 1,
        filter: ''
    });
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [cameraToBeDeleted, setCameraToBeDeleted] = useState<CrmCameras>();
    const [deleteReasons, setDeleteReasons] = useState<DeleteReason[]>([]);
    const [otherReason, setOtherReason] = useState('');
    const [cameraDeleteReason, setCameraDeleteReason] = useState<DeleteReason>();
    const [dropDown, setDropDown] = useState(false);
    const [value, setValue] = useState(null);

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        checkPermissions();
        setViewHeight(height);
        document.body.style.position = "fixed";

        function handleResize() {
            setViewHeight(
                window.innerHeight - headerSize - insets.bottom - insets.top
            );
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const height = window.innerHeight - headerSize - insets.bottom - insets.top;
        setViewHeight(height);
    }, [insets]);

    useEffect(() => {
        if (contentHeight === 0 || contentHeight > viewHeight) return;

        if (renderItemHeight === 0 || !cameraList.length || cameraList.length * renderItemHeight > contentHeight) return;

        handleLoadMore();
    }, [contentHeight, renderItemHeight, viewHeight]);

    useEffect(() => {
        loadCrmCameras();
    }, [searchParams]);

    useFocusEffect(
        useCallback(() => {
            setSearchParams({ ...searchParams, page: 1 });
        }, [navigation])
    );

    async function checkPermissions() {
        try {
            const wl = await platformAPI.getWhitelabel();

            if (wl.id === 1) {
                goBack();
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }


    function openActionSheet(camera: CrmCameras) {
        setCameraToBeDeleted(camera);

        const options: Option[] = [];
        if (!authenticatedUser) return;

        if (authenticatedUser.permissions.can_see_camera_options || authenticatedUser.id === camera["User.id"]) {
            options.push({
                title: authenticatedUser.permissions.can_edit_camera_options ? translate("editRegistration") : translate("viewRegistration"),
                onClick: () => {
                    navigation.navigate('CrmCameraEdit', { id: camera.id });
                }
            });
            if (authenticatedUser.permissions.can_edit_camera_options || authenticatedUser.id === camera["User.id"]) {
                options.push({
                    title: translate("changePlan"),
                    onClick: () => {
                        navigation.navigate('CameraPlan', {
                            id: camera.id
                        });
                    }
                });
            }
            options.push({
                title: authenticatedUser?.permissions.can_edit_camera_options || authenticatedUser?.id === camera["User.id"] ? translate("changeAddress") : translate("seeAddress"),
                onClick: () => {
                    navigation.navigate('ChangeCameraAddress', { id: camera.id });
                }
            });
            options.push({
                title: authenticatedUser?.permissions.can_edit_camera_options || authenticatedUser?.id === camera["User.id"] ? translate("associateGroups") : translate("groupAssociations"),
                subtitle: `${camera.totalGroups} ${translate("groupsTitle")}`,
                onClick: () => {
                    navigation.navigate('CrmCameraGroupsAssociation', { id: camera.id });
                }
            });
        }


        if (authenticatedUser.permissions.can_edit_camera_options || authenticatedUser.id === camera["User.id"]) {
            getDeleteReasons();
            options.push({
                title: translate("delete"),
                destructive: true,
                onClick: () => {
                    setDeleteModalVisible(true);
                }
            });
        }
        if (options.length > 0) {
            showActionSheet(showActionSheetWithOptions, options);
        }
    }

    async function getDeleteReasons() {
        try {
            const res = await platformAPI.getDeleteReasons();
            setDeleteReasons(res);
        } catch (err) {
            if (err instanceof UnexpectedError) {
                Toast.show({
                    type: 'error',
                    text1: translate("UNEXPECTED_ERROR"),
                });
            }
            console.error(err);
        }
    }

    async function deleteCamera() {
        if (!cameraToBeDeleted || !cameraDeleteReason) {
            return;
        }
        setLoading(true);
        try {
            await platformAPI.cameraDelete({
                id: cameraToBeDeleted.id,
                CameraDeletionReasonId: cameraDeleteReason.id,
                deletionDescription: otherReason
            });
            setDeleteReasons([]);
            setDeleteModalVisible(false);
            setCameraDeleteReason(undefined);
            setOtherReason('');
            setCameraToBeDeleted(undefined);
            handleRefresh();
            setLoading(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            } else if (err instanceof UnexpectedError) {
                Toast.show({
                    type: 'error',
                    text1: translate("UNEXPECTED_ERROR"),
                });
            }
            console.error(err);
        }
    }

    async function loadCrmCameras() {
        setLoading(true);
        try {

            const crmCameras: CrmCameras[] = await platformAPI.getCrmCameras(searchParams);
            setLoading(false);

            if (crmCameras.length === 0 && searchParams.page === 1) {
                setCameraList([]);
                setEmptyList(true);
                setLoading(false);
                setRefreshing(false);
                return;
            }
            if (searchParams.page === 1) {
                setCameraList(crmCameras);
            } else {
                setCameraList([...cameraList, ...crmCameras]);
            }

            setRefreshing(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("Camera", { screen: "More" });
                return;
            }
            console.error(err);
            setLoading(false);
            setRefreshing(false);
        }
    }

    function handleLoadMore() {
        if (loading) return;

        if (emptyList) {
            setLoading(false);
        } else {
            setSearchParams({
                ...searchParams,
                page: searchParams.page + 1,
            });
        }
    }

    function handleRefresh() {
        setRefreshing(true);

        setSearchParams({ ...searchParams, page: 1 });

        if (!emptyList) {
            setRefreshing(false);
        } else {
            setRefreshing(true);
        }
    }

    function ItemSeparator() {
        return <View style={styles.itemSeparator} />;
    }

    function goBack() {
        navigation.navigate("Camera", { screen: "More" });
    }

    function addCamera() {
        navigation.navigate("CrmCameraRegistration");
    }

    function handleDropdownSelected(value: number | null) {
        const deleteReason = deleteReasons.find((deleteReason: DeleteReason) => deleteReason.id === value);
        setCameraDeleteReason(deleteReason);
    }

    const CameraItem = ({ item }: ListRenderItemInfo<CrmCameras>) => (
        <View style={styles.cameraCard}>
            <TouchableOpacity style={styles.innerCameraCard} onPress={() => openActionSheet(item)}>
                <View
                    style={styles.innerCameraCard}
                    onLayout={(e: LayoutChangeEvent) => {
                        if (renderItemHeight === 0) setRenderItemHeight(e.nativeEvent.layout.height);
                    }}>
                    <View style={{ width: "90%" }}>
                        <Text style={styles.textButton} numberOfLines={1} adjustsFontSizeToFit>{item.title}</Text>
                        {item["Plan.name"] ? (
                            <Text style={styles.textDefaultCamera}>{translate(item["Plan.name"])}</Text>
                        ) : null}
                    </View>
                    {item.status && !item.disabled_date ? (
                        <div className="ur-tooltip">
                            <span className="ur-tooltip-text">{translate("MAIL_CAMERA_ON")}</span>
                            <div className="ur-tooltip-icon">
                                <List.Icon icon={"check-circle"} color="green" style={styles.enabledIcon} />
                            </div>
                        </div>
                    ) : !item.status && !item.disabled_date ? (
                        <div className="ur-tooltip">
                            <span className="ur-tooltip-text">{translate("MAIL_CAMERA_OFF")}</span>
                            <div className="ur-tooltip-icon">
                                <List.Icon icon={"close-circle"} color="red" style={styles.enabledIcon} />
                            </div>
                        </div>
                    ) : item.disabled_date ? (
                        <div className="ur-tooltip">
                            <span className="ur-tooltip-text">{translate("disabled")}</span>
                            <div className="ur-tooltip-icon">
                                <List.Icon icon={"block-helper"} color="orange" style={styles.enabledIcon} />
                            </div>
                        </div>
                    ) : null}
                </View>
            </TouchableOpacity>
        </View >
    );

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <TouchableOpacity style={styles.titleHeaderView} onPress={goBack}>
                        <List.Icon
                            style={[styles.backArrow, styles.icons]}
                            icon="arrow-left"
                            color="#fff"

                        />
                    </TouchableOpacity>
                    <View style={styles.headerRight}>
                        <View style={styles.titleHeaderView}>
                            <Text style={styles.titleHeader}>{translate("cameras")}</Text>
                        </View>
                        <View style={styles.headerIcons}>
                            <TouchableOpacity onPress={() => {
                                setFilterModalVisible(!filterModalVisible);
                            }}>
                                <Image style={styles.filterImage} source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_filter.png' }} />
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.titleHeaderView} onPress={addCamera}>
                                {authenticatedUser?.permissions.can_create_camera ?
                                    <List.Icon
                                        style={[styles.plus, styles.icons]}
                                        icon="plus"
                                        color="#fff"
                                    />
                                    : null}
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>

            <CamFilter
                setSearchParams={setSearchParams}
                setModalVisible={setFilterModalVisible}
                modalVisible={filterModalVisible}
            />

            <Modal
                animationType='fade'
                transparent={true}
                visible={deleteModalVisible}
                onRequestClose={() => {
                    setDeleteModalVisible(!deleteModalVisible);
                }}>
                <View style={styles.fadeView} />
                <View style={styles.centeredView}>
                    <View style={[styles.containerModal, { marginTop: viewHeight / 3 }]}>
                        <Text style={styles.titleModal} adjustsFontSizeToFit>{translate("deleteCameraConfirmation")}{"\n"}</Text>
                        <Text style={styles.camNameModal} adjustsFontSizeToFit>{cameraToBeDeleted?.title}</Text>
                        <View style={styles.scrollInputView}>
                            <Text style={styles.labelText}>{translate("deleteCameraReason")}</Text>
                            <DropDownPicker
                                open={dropDown}
                                value={value}
                                setValue={setValue}
                                items={deleteReasons.map((deleteReason: DeleteReason) => {
                                    if (deleteReason.Children.length > 0) {
                                        return {
                                            label: translate(deleteReason.name),
                                            value: deleteReason.id,
                                            selectable: false,
                                            labelStyle: styles.parentText
                                        };
                                    }
                                    return { label: translate(deleteReason.name), value: deleteReason.id };
                                })}
                                style={styles.dropDown}
                                placeholder={translate("UNKNOWN")}
                                dropDownContainerStyle={styles.sugestionView}
                                onChangeValue={handleDropdownSelected}
                                listItemContainerStyle={styles.drops}
                                selectedItemContainerStyle={{ backgroundColor: '#F3F4F5'}}
                                showTickIcon={false}
                                setOpen={setDropDown}
                            />
                            {cameraDeleteReason?.name === "CAMERA_DELETION_REASON_0" && (
                                <>
                                    {dropDown == false && (
                                        <View style={styles.otherView}>
                                            <Text style={styles.labelText}>{translate("deleteCameraReasonOthers")}</Text>
                                            <TextInput
                                                style={styles.textInputs}
                                                onChangeText={(value) => setOtherReason(value)}
                                                value={otherReason}
                                            />
                                        </View>
                                    )}
                                </>
                            )}
                        </View>
                        {dropDown ? (null) : (
                            <View style={styles.viewButtonsModal}>
                                <TouchableOpacity style={styles.buttonCloseModal} onPress={() => setDeleteModalVisible(!deleteModalVisible)}>
                                    <Text>{translate("cancelAction")}</Text>
                                </TouchableOpacity>
                                {cameraDeleteReason?.name === "CAMERA_DELETION_REASON_0" ? (
                                    <TouchableOpacity
                                        style={otherReason !== "" ? styles.buttonDeleteModal : styles.invalidDeleteModal}
                                        disabled={otherReason !== "" ? false : true}
                                        onPress={() => otherReason !== "" ? deleteCamera() : null}>
                                        <Text style={styles.textButtonModal}>{translate('delete')}</Text>
                                    </TouchableOpacity>
                                ) : (
                                    <TouchableOpacity style={styles.buttonDeleteModal} onPress={() => deleteCamera()}>
                                        <Text style={styles.textButtonModal}>{translate('delete')}</Text>
                                    </TouchableOpacity>
                                )}
                            </View>
                        )}
                    </View>
                </View>
            </Modal>
            <ScrollView
                bounces={false}
                style={[styles.scrollView, { height: viewHeight }]}
            >
                {!cameraList.length ? (
                    !loading && (
                        <View style={styles.noResults}>
                            <Text>{translate("noResults")}</Text>
                        </View>
                    )
                ) : (
                    <View>
                        <FlatList
                            style={[styles.scrollView, { height: viewHeight }]}
                            bounces={false}
                            data={cameraList}
                            renderItem={CameraItem}
                            removeClippedSubviews={true}
                            updateCellsBatchingPeriod={100}
                            windowSize={12}
                            ItemSeparatorComponent={ItemSeparator}
                            nestedScrollEnabled
                            onEndReached={() => {
                                handleLoadMore();
                            }}
                            refreshControl={
                                <RefreshControl
                                    refreshing={refreshing}
                                    onRefresh={handleRefresh}
                                />
                            }
                            onEndReachedThreshold={0.03}
                            onLayout={(e: LayoutChangeEvent) => {
                                setContentHeight(e.nativeEvent.layout.height);
                            }}
                        />
                    </View>
                )}
            </ScrollView>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    enabledIcon: {
        width: 20,
        height: 20,
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: footerSize,
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#9a9a9a4f",
    },
    contentContainer: {
        justifyContent: "space-between",
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    scrollView: {
        width: "100%",
        height: "60%",
    },
    cameraCard: {
        color: "red",
        width: "100%",
        height: "72px",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    innerCameraCard: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    textButton: {
        fontSize: 16,
        marginLeft: "15px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    textDefaultCamera: {
        marginLeft: "15px",
        marginTop: "5px",
        color: "#0071bc",
        fontSize: 12,
    },
    headerContent: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    header: {
        alignSelf: 'flex-start',
        width: '100%',
        height: headerSize,
        backgroundColor: '#0071bc',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row'
    },
    headerIcons: {
        flexDirection: 'row',
        alignItems: "center"
    },
    icons: {
        maxWidth: buttonsSize,
        maxHeight: buttonsSize
    },
    headerRight: {
        width: 'calc(100% - 60px)',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    searchIcon: {
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: "center"
    },
    searchInput: {
        backgroundColor: '#fff',
        width: "100%",
        height: 45,
        color: '#000',
        fontSize: 16,
        borderBottomWidth: 1,
        borderBottomColor: '#0071bc',
    },
    searchInputText: {
        width: '100%',
        alignSelf: 'center',
        marginTop: 15,
        marginLeft: 10,
        height: 45,
        fontSize: 16
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    plus: {
        alignSelf: "center",
    },
    titleHeader: {
        fontSize: 26,
        color: '#fff',
        marginLeft: 15,
        alignSelf: 'center'
    },
    fadeView: {
        flex: 1,
        position: 'relative',
        backgroundColor: 'black',
        opacity: 0.25
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
    },
    containerModal: {
        backgroundColor: "#fff",
        borderRadius: 20,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 360,
        height: 390,
        justifyContent: 'center',
        justifyItems: 'center',
        gap: 15,
        paddingBottom: 15,
        paddingHorizontal: 10,
    },
    titleModal: {
        numberOfLines: 1,
        fontSize: 16,
        maxWidth: "80%",
        textAlign: 'center',
        position: 'absolute',
        top: 40
    },
    camNameModal: {
        numberOfLines: 1,
        fontSize: 16,
        maxWidth: "80%",
        textAlign: 'center',
        position: 'absolute',
        top: 80
    },
    viewButtonsModal: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 40
    },
    buttonCloseModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: '#fff'
    },
    buttonDeleteModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: 'red'
    },
    textButtonModal: {
        color: '#fff'
    },
    invalidDeleteModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: "rgba(255, 0, 0, 0.5)",
    },
    textInputs: {
        backgroundColor: '#fff',
        width: "100%",
        alignSelf: 'center',
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        marginHorizontal: 'auto',
        height: 45,
        color: '#000',
        fontSize: 14,
        paddingHorizontal: 12
    },
    labelText: {
        fontSize: 16,
    },
    dropDown: {
        borderColor: "#9a9a9a4f",
        flexDirection: "row",
        width: "100%",
        borderWidth: 1,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 12,
        marginBottom: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    drops: {
        borderColor: "#9a9a9a4f",
        width: "100%",
        borderWidth: 1,
        borderTopWidth: 0,
        color: '#000',
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 15,
    },
    dropDownInputWithParent: {
        paddingLeft: 15,
        color: '#000',
        fontSize: 16,
    },
    dropDownInput: {
        maxWidth: 280,
        color: '#000',
        fontSize: 16,
    },
    dropDownIcon: {
        height: 7,
        width: 1
    },
    sugestionView: {
        width: "100%",
        backgroundColor: '#fff',
        alignSelf: 'center',
        borderColor: "#9a9a9a4f",
        borderRadius: 8,
        borderWidth: 1,
        maxHeight: 250
    },
    parentText: {
        color: '#000',
        fontSize: 16,
        fontWeight: 'bold',
    },
    scrollInputView: {
        position: "absolute",
        width: "100%",
        paddingHorizontal: 20,
        top: 120
    },
    otherView: {
        marginTop: 25,
        width: '100%',
    },
    filterImage: {
        width: 25,
        height: 25
    }
});

