import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, StyleSheet, Image, Modal, View } from 'react-native';
import { UserData, platformAPI } from '../services/platform-api';
import * as Location from 'expo-location';
import Toast from 'react-native-toast-message';
import { Countdown } from 'react-native-element-timer';
import { translate } from '../services/service-translate';

interface PersonalAlertProps {
    authenticatedUser?: UserData;
}

export default function PersonalAlert({ authenticatedUser }: PersonalAlertProps) {
    const [canSendPersonalPanic, setCanSendPersonalPanic] = useState(false);
    const [whitelabelHasPersonalPanic, setWhitelabelHasPersonalPanic] = useState(false);
    const [personalPanicAnswer, setPersonalPanicAnswer] = useState(false);
    const [endTimer, setEndTimer] = useState(false);

    useEffect(() => {
        checkPermissions();
    }, [authenticatedUser]);

    async function checkPermissions() {
        if (!authenticatedUser) return;

        const wl = await platformAPI.getWhitelabel();
        setWhitelabelHasPersonalPanic(wl.has_personal_panic);
        setCanSendPersonalPanic(authenticatedUser.permissions.can_send_personal_panic);
    }

    async function sendAlert() {
        try {
            const { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                return;
            }

            const location = await Location.getCurrentPositionAsync({});

            await platformAPI.sendPersonalPanicNotification(location.coords.latitude, location.coords.longitude);

            Toast.show({
                type: 'success',
                text1: translate('personalPanicSuccess'),
            });
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'error',
                text1: translate('personalPanicFailure'),
            });
        }
    }

    useEffect(() => {
        setEndTimer(false);
    }, [personalPanicAnswer]);

    return (
        <>
            {canSendPersonalPanic && whitelabelHasPersonalPanic ?
                <>
                    <TouchableOpacity
                        style={styles.dialButton}
                        onPress={() => setPersonalPanicAnswer(true)}
                        activeOpacity={0.8}
                    >
                        <Image style={styles.alertImage} source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_alert.png' }}/>
                    </TouchableOpacity>
                    <Modal
                        animationType='fade'
                        transparent={true}
                        visible={personalPanicAnswer}
                        onRequestClose={() => setPersonalPanicAnswer(!personalPanicAnswer)}
                    >
                        <View style={styles.modalContainerPanic} />
                        <View style={styles.modalCenterViewPanic}>
                            <View style={styles.modalBodyPanic}>
                                <View style={styles.alertHeader}>
                                    <Text style={styles.alertTitle}>{translate('alertTitle')}</Text>
                                </View>
                                <View style={{ width: '90%' }}>
                                    <Text style={styles.alertContainerText}>{translate('personalTextCaution')}</Text>
                                </View>
                                <Text style={styles.alertContainerTextConfirm}>{translate('alertTextConfirm')}</Text>
                                <View style={styles.alertButtonsView}>
                                    <TouchableOpacity
                                        style={styles.alertButtons}
                                        onPress={() => {
                                            setPersonalPanicAnswer(!personalPanicAnswer), setTimeout(() => setEndTimer(false), 400);
                                        }}
                                    >
                                        <Text style={styles.alertButtonText}>{translate('cancel_caps')}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={[styles.alertButtons, { backgroundColor: endTimer ? '#0071bc' : '#7eb8dd' }]}
                                        onPress={() => {
                                            sendAlert(), setEndTimer(false); setPersonalPanicAnswer(!personalPanicAnswer);
                                        }}
                                        disabled={endTimer ? false : true}
                                    >
                                        <Text style={styles.alertButtonText}>{translate('yes_caps')}
                                            {endTimer ? null : <Text style={styles.alertConfirmParentheses}>(<Countdown
                                                initialSeconds={5}
                                                textStyle={styles.timerAlert}
                                                autoStart={true}
                                                onEnd={() => {
                                                    setEndTimer(true);
                                                }}
                                            />)</Text>}
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </Modal>
                </>
                :
                null
            }

        </>
    );
}
const styles = StyleSheet.create({
    dialButton: {
        backgroundColor: "#fe0000",
        borderRadius: 50,
        width: 60,
        height: 60,
        position: 'absolute',
        right: 15,
        bottom: 15,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    alertImage: {
        width: 30,
        height: 27,
        marginBottom: 3,
        tintColor: "#fff"
    },
    counterText: {
        fontSize: 20,
        fontWeight: "bold",
        color: "#fff"
    },
    modalContainerPanic: {
        flex: 1,
        position: 'relative',
        backgroundColor: '#000',
        opacity: 0.50,
    },
    modalCenterViewPanic: {
        justifyContent: 'center',
        alignItems: "center",
        alignSelf: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    modalBodyPanic: {
        backgroundColor: "#fff",
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 300,
        height: 200,
    },
    alertHeader: {
        width: '100%',
        height: 40,
        backgroundColor: '#0071bc',
        justifyContent: 'center'
    },
    alertTitle: {
        color: "#fff",
        alignSelf: 'center',
        fontSize: 20,
        fontWeight: '600',
    },
    alertContainerText: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '600',
        marginTop: 20
    },
    alertContainerTextConfirm: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '600',
        marginTop: 20
    },
    timerAlert: {
        color: "#fff",
        fontSize: 16,
        paddingHorizontal: 1
    },
    alertButtonsView: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 15
    },
    alertButtons: {
        marginHorizontal: 20,
        backgroundColor: '#0071bc',
        height: 30,
        width: 100,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center'
    },
    alertButtonText: {
        color: "#fff",
        fontWeight: '600'
    },
    alertConfirmParentheses: {
        paddingHorizontal: 3
    },
});
