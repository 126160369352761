import {
    View,
    Text,
    StyleSheet,
    KeyboardAvoidingView,
    TouchableOpacity,
    Image,
    SafeAreaView,
    NativeSyntheticEvent,
    TextInputKeyPressEventData
} from 'react-native';
import React, { useState, useRef, useEffect } from 'react';
import { Icon } from 'react-native-elements';
import { TextInputMask, TextInputMaskMethods } from 'react-native-masked-text';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { platformAPI } from '../../services/platform-api';
import { useActionSheet } from "@expo/react-native-action-sheet";
import { settingsLang, translate } from '../../services/service-translate';
import { useLinkTo } from '@react-navigation/native';

interface AuthenticationComponentProps {
    navigation: Navigation;
}

export function Authentication({ navigation }: AuthenticationComponentProps) {

    const numRef = useRef<TextInputMaskMethods>(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [totalLength, onTotalLength] = useState(false);
    const { showActionSheetWithOptions } = useActionSheet();
    const linkTo = useLinkTo();

    async function checkAuth() {
        const token = await AsyncStorage.getItem('BEARER_TOKEN');

        if (token) {
            linkTo('/cameras');
        }
    }

    useEffect(() => {
        document.body.style.position = 'fixed';

        checkAuth();
    }, []);

    const onChangePhone = (number: string) => {
        setPhoneNumber(number);
        if (number.length == 16) {
            onTotalLength(true);
        } else {
            onTotalLength(false);
        }
    };

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter" && totalLength == true) {
            onPressContinue();
        }
    }

    async function onPressContinue() {
        onTotalLength(false);
        setInvalidLogin(false);

        if (!numRef.current) {
            return;
        }

        const unmask = numRef.current.getRawValue();

        try {
            await platformAPI.signupPhone(unmask);
            navigation.navigate('InputOTP', unmask);
        } catch (err) {
            setInvalidLogin(true);
        }
    }

    const onPressLogin = () => {
        navigation.navigate('Login');
    };

    return (
        <SafeAreaView style={styles.container}>
            <KeyboardAvoidingView
                keyboardVerticalOffset={50}
                behavior={'padding'}
                style={styles.containerAvoidView}
            >
                <View style={styles.translateView}>
                    <TouchableOpacity onPress={() => settingsLang(showActionSheetWithOptions)}>
                        <View style={styles.translator}>
                            <Icon
                                name='g-translate'
                                color={'#0071bc'}
                            />
                        </View>
                    </TouchableOpacity>
                </View>
                <Text style={styles.textTitle}>{translate('title')}</Text>
                {invalidLogin ? <Text style={styles.invalidMessage}>{translate('invalidPhone')}</Text> : null}
                <View style={[
                    styles.containerInput,
                    {
                        borderBottomColor: 'black'
                    }
                ]}>
                    <View style={styles.openDialogView}>
                        <Text style={{ color: '#6A6A6A' }}>{"+55 |"}</Text>
                    </View>
                    <TextInputMask
                        type={'cel-phone'}
                        style={styles.phoneInputStyle}
                        options={{
                            maskType: 'BRL',
                            withDDD: true,
                            dddMask: '(99) 9 9999-9999'
                        }}
                        value={phoneNumber}
                        onChangeText={text => onChangePhone(text)}
                        maxLength={16}
                        keyboardType='numeric'
                        ref={numRef}
                        onKeyPress={handleKeyDown}
                    />
                    {
                        totalLength ?
                            <Icon
                                color='#55A1FC'
                                name='check'
                                type='Entypo'
                                size={20}
                            />
                            : <Icon
                                color='#FF2D00'
                                name='close'
                                type='MaterialIcons'
                                size={20}
                            />
                    }
                </View>
                <View style={styles.viewBtnContinue}>
                    <TouchableOpacity
                        onPress={onPressContinue}
                        disabled={totalLength ? false : true}
                    >
                        <View style={[
                            styles.btnContinue,
                            {
                                backgroundColor: totalLength ? '#0071bc' : '#7eb8dd'
                            }
                        ]}>
                            <Text style={styles.textContinue}>{translate('sendButton')}</Text>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.textOr}>
                    <View style={styles.viewStyleForLine}></View>
                    <Text style={{ color: '#C8C8C8' }}>{translate('subtitleText')}</Text>
                    <View style={styles.viewStyleForLine}></View>
                </View>
                <View style={styles.sideText}>
                    <TouchableOpacity onPress={onPressLogin}>
                        <View style={styles.viewLogin}>
                            <Text style={styles.textLogin}>{translate('buttonEmailLogin')}</Text>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.sideView}>
                    <View style={styles.termsView}>
                        <Text style={styles.termsText}>
                            {translate('terms')}
                            {<Text
                                style={{ color: '#005893' }}
                                onPress={() => navigation.navigate('Terms')}
                            >{translate('terms-link')}</Text>}
                            {translate('terms-1')}
                            {<Text
                                style={{ color: '#005893' }}
                                onPress={() => navigation.navigate('Policy')}
                            >{translate('terms-link-1')}</Text>}
                        </Text>
                    </View>
                </View>
                <View style={styles.viewLogo}>
                    <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fcamerite-logo-azul.png' }} style={styles.logo} />
                </View>
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    containerAvoidView: {
        flex: 1,
        alignItems: 'center',
        padding: 10
    },
    container: {
        backgroundColor: 'white',
        flex: 1
    },
    translateView: {
        justifyContent: 'center',
        height: 27,
        alignSelf: 'baseline',
        marginTop: 20,
        marginLeft: 10
    },
    translator: {
        alignContent: 'center',
        alignItems: 'center'
    },
    textTitle: {
        marginBottom: 20,
        marginTop: 50,
        fontSize: 28,
        color: '#0071bc',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    invalidMessage: {
        textAlign: 'center',
        textAlignVertical: 'center',
        marginBottom: 30,
        color: "red"
    },
    containerInput: {
        flexDirection: 'row',
        paddingHorizontal: 15,
        borderRadius: 0,
        backgroundColor: 'white',
        alignItems: 'center',
        borderBottomWidth: 2,
        width: 280
    },
    openDialogView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
    },
    phoneInputStyle: {
        marginLeft: 8,
        flex: 1,
        height: 45,
        backgroundColor: 'white',
        color: 'black',
    },
    viewBtnContinue: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: 20,
    },
    btnContinue: {
        width: 245,
        height: 43,
        borderRadius: 26,
        alignItems: 'center',
        justifyContent: 'center',
    },
    textContinue: {
        color: '#ffffff',
        alignItems: 'center',
        fontWeight: 'bold'
    },
    viewStyleForLine: {
        borderBottomColor: "#C8C8C8",
        borderBottomWidth: StyleSheet.hairlineWidth,
        width: "70%",
        alignSelf: 'center',
        padding: 2,
        marginHorizontal: 18
    },
    textOr: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        flexDirection: 'row',
        width: '50%',
        marginTop: -16
    },
    sideText: {
        flexDirection: 'row',
        padding: 3,
        justifyContent: 'center',

    },
    textLogin: {
        color: '#0071bc',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5
    },
    viewLogin: {
        width: 245,
        height: 43,
        borderRadius: 26,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: '#0071bc',
        borderWidth: 2,
        shadowColor: 'black',
        shadowRadius: 1.5,
    },
    sideView: {
        alignContent: 'flex-start',
        marginTop: 30
    },
    termsView: {
        flex: 1,
        width: 255,
        justifyContent: 'flex-start',
    },
    termsText: {
        color: 'black',
        textAlign: 'center',
        textAlignVertical: 'top'
    },
    viewLogo: {
        flex: 1,
        justifyContent: 'flex-end',
        paddingTop: 20,
        marginTop: -10,
    },
    logo: {
        padding: 20,
        width: 250,
        height: 48,
        marginBottom: 10
    },
});
