import AsyncStorage from '@react-native-async-storage/async-storage';
import { CameraBrands } from '../pages/crm/camera/Register';

const backend_endpoint = process.env.BACKEND_ENDPOINT;

class ExtendableError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }
}

class ClientError extends ExtendableError {
}

class NotFound extends ExtendableError {
}

class UnexpectedError extends ExtendableError {
}

class invalidBearerToken extends ExtendableError {
}

class forbiddenAccess extends ExtendableError {
}

type Coordinates = [number, number][];

interface Area {
    coordinates: Coordinates[];
    type: string;
}

interface CameraList {
    id: number;
    title: string;
    lastShot: string;
    showDefaultImage: boolean;
    factory: string;
    'District.City.name': string;
    area?: Area;
    lat: string;
    lng: string;
}

type CameraTypes = "INTERNAL" | "EXTERNAL" | "UNKNOWN";

interface Camera {
    id: number;
    factory: string;
    title: string;
    webrtc_data: {
        webrtc_tech: string,
        wss_address: string,
    },
    Plan?: Plan,
    video_archive_ttl_days?: number;
    UserId: number;
    ServerId?: number;
    stream_url: string;
    latitude: string;
    longitude: string;
    authentication: {
        overrideAuth: boolean;
        port: number;
        username: string | null;
        password: string | null;
    };
    CameraConfig: CameraConfig;
    type: CameraTypes;
}

interface CameraConfig {
    rtsp_address: string;
    camera_username?: string;
    camera_password?: string;
    port?: number;
    public: boolean;
    private: boolean;
}

interface CameraEditProps {
    title?: string;
    rtsp_address?: string;
    override?: boolean;
    port?: number;
    username?: string | null;
    password?: string | null;
    type: CameraTypes;
    pub?: boolean;
    priv?: boolean;
    latitude?: string;
    longitude?: string;
}

interface Plan {
    id: number;
    slug: string;
    name: string;
    value: number;
    amount: number;
}

interface RegisterCamera {
    streamName: string;
    isRtmpCamera: boolean;
    factory: CameraBrands;
    rtsp: string;
    title: string;
    plan: Plan;
    username?: string;
    password?: string;
    port?: number;
    lat?: number;
    lng?: number;
    area?: Area | null;
}

interface ValidP2P {
    factory: CameraBrands;
    id: string;
    rtsp: string;
    username?: string;
    password?: string;
    port?: number;
}

interface CameraNotifications {
    all: boolean;
    movement: boolean;
    status: boolean;
    camId?: number;
}

interface Video {
    id: number;
    title: string;
    videoUrl: string;
    createdDate: string;
    shotUrl: string;
    status: number;
    start: number;
    finish: number;
    Camera: {
        title: string;
    };
}

interface Timeline {
    key_map: {
        startTime: string;
        finishTime: string;
        shotList: string;
        shotTime: string;
    };
    shot_template: string | string[];
    video_template: string | string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    videos: any[];
}

interface CameraPermissions {
    can_use_camera_panic: boolean;
    can_see_lpr_tab: boolean;
}

interface UserPermissions {
    cameras: {
        [camera: number]: CameraPermissions | null;
    },
    can_impersonate_users: boolean;
    can_see_camera_group_tab: boolean;
    can_see_camera_tab: boolean;
    can_see_user_tab: boolean;
    can_create_camera: boolean;
    can_see_user_details: boolean;
    can_edit_user: boolean;
    can_edit_camera_group: boolean;
    can_create_camera_group: boolean;
    can_edit_user_payment: boolean;
    can_edit_permission_levels: boolean;
    can_see_permission_levels_tab: boolean;
    can_see_platform_payment_info: boolean;
    can_see_lpr_blacklist: boolean;
    can_edit_camera_options: boolean;
    can_see_camera_options: boolean;
    can_see_lpr_situation: boolean;
    can_edit_lpr_blacklist: boolean;
    can_see_marketing_kit: boolean;
    can_see_investigative_map: boolean;
    can_access_horus_requests: boolean;
    can_send_personal_panic: boolean;
    can_receive_personal_panic: boolean;
    can_create_trial_users: boolean;
}

interface UserData {
    id: number;
    didAcceptUseTerms: boolean;
    name: string;
    email: string;
    phone?: string;
    cep?: string;
    city?: string;
    complement?: string;
    cpf?: string;
    cnpj?: string;
    district?: string;
    streetNumber?: string;
    state?: string;
    street?: string;
    token: string;
    pushAll: boolean;
    pushMovement: boolean;
    pushStatus: boolean;
    permissions: UserPermissions;
    isNpsSurveyAvailable: boolean;
    disabledReason?: DisabledReason;
    isFranchise: boolean;
}

type fields = "DISABLE" | "ENABLE" | "REQUIRED";

interface SignupFields {
    signup_phone: fields;
    signup_cpf: fields;
    signup_cep: fields;
    signup_state: fields;
    signup_city: fields;
    signup_neighborhood: fields;
    signup_street: fields;
    signup_number: fields;
    signup_complement: fields;
    signup_name: fields;
    signup_email: fields;
}

interface WhitelabelSignupField {
    signup_fields: SignupFields;
}
class SignupFields implements SignupFields {
    constructor() {
        this.signup_phone = 'DISABLE';
        this.signup_cpf = 'DISABLE';
        this.signup_cep = 'DISABLE';
        this.signup_state = 'DISABLE';
        this.signup_city = 'DISABLE';
        this.signup_neighborhood = 'DISABLE';
        this.signup_street = 'DISABLE';
        this.signup_number = 'DISABLE';
        this.signup_complement = 'DISABLE';
        this.signup_name = 'DISABLE';
        this.signup_email = 'DISABLE';
    }
}

const defaultSignupFieldsValues = new SignupFields();


interface Whitelabel {
    id: number;
    has_panic: boolean;
    has_personal_panic: boolean;
    slug: string;
}

interface ChatGroup {
    id: number;
    title: string;
}

interface ChatMessage {
    groupId: number;
    groupTitle?: string;
    userName?: string;
    message: string;
    userId?: number;
    timestamp: number;
    isAuthor?: boolean;
}

interface setUserDataParameters {
    id?: number;
    cep?: string;
    city?: string;
    complement?: string;
    cpf?: string;
    cnpj?: string;
    district?: string;
    email?: string;
    name?: string;
    password?: string;
    phone?: string;
    state?: string;
    street?: string;
    streetNumber?: string;
    observation?: string;
    pushAll?: boolean;
    pushMovement?: boolean;
    pushStatus?: boolean;
}

interface CreateVideo {
    title: string;
    start: Date;
    finish: Date;
    camId: number;
}

interface BlacklistInfo {
    id: string;
    desc: string;
    userId?: string;
    userName?: string;
}

interface LprDetection {
    id: number;
    start: number;
    finish: number;
    camera: number;
    plate: string;
    confidence: number;
    color: string | null;
    brand: string | null;
    model: string | null;
    type: string | null;
    image_url: string | null;
    plate_position: string | null;
    vehicle_position: string | null;
    is_blacklist: boolean;
    blacklist_infos: BlacklistInfo[];
    "Camera.title": string;
    "Camera.timezone": string;
    dateTZFormatted: string;
    carbigdata_vehicle_information: VehicleInformation;
}

interface LprDetectionObject {
    results: LprDetection[];
    total: number;
    totalCameras: number;
    maxResults: number;
}

interface GetLprDetectionParams {
    page: number;
    startDate?: Date;
    finishDate?: Date;
    order?: string;
    plate?: string;
    cameras?: string;
    blacklist?: boolean;
}

interface LprCamera {
    id: number;
    title: string;
}


interface WhitelabelUser {
    id: number;
    name: string;
    email: string;
    checked?: boolean;
}

interface CrmGroupListItem {
    id: number;
    title: string;
    enabled: number;
    observation: string;
    WhitelabelId: number;
    userDefault: boolean;
    totalCameras: number;
    totalUsers: number;
}

interface groupPermissions {
    canSeeCameraDownload?: boolean;
    canSeeCameraMoviment?: boolean;
    canSeeCameraPanic?: boolean;
    canSeeCameraTimeline?: boolean;
    canSeeFaceRecognitionEvents?: boolean;
    canSeeLprTab?: boolean;
    canSeePTZControls?: boolean;
    canSeePersonDetectionEvents?: boolean;
}

interface groupPermissionRequest {
    id: number;
    permission_key: string;
    GroupUserCameraId: number;
}

interface updateCrmGroupParams {
    id: number;
    title?: string;
    observation?: string;
    enabled?: boolean;
    userDefault?: boolean;
    hasChat?: boolean;
    permissions?: groupPermissions;
    users_to_add?: number[];
    users_to_rm?: number[];
    cameras_to_rm?: number[];
    cameras_to_add?: number[];
}

interface createGroupParams {
    title?: string;
    observation?: string;
    enabled?: boolean;
    userDefault?: boolean;
    hasChat?: boolean;
    permissions: groupPermissions;
    users_to_add?: number[];
    cameras_to_add?: number[];
    logs_to_add?: [],
    gateways_to_add?: [],
}

interface IdParam {
    id: number;
}

interface PageParam {
    page: number;
    filter?: string;
}

interface CrmGroupCamera {
    id: number;
    title: string;
    p2p_uid?: string;
    checked?: boolean;
}

interface CrmCameraGroups {
    id: number;
    title: string;
}

interface CrmCameras {
    "id": number;
    'title': string;
    "CameraConfig.private": number;
    "CameraConfig.rtsp_address": string;
    "Plan.id": number;
    "Plan.name": string;
    "Plan.slug": string;
    "PlanId": number;
    "User.WhitelabelId": number;
    "User.id": number;
    "User.name": string;
    "disabled_date": string;
    "is_p2p": number;
    "observations": string;
    "p2p_uid": string;
    "status": number;
    "test": number;
    "totalGroups": number;
}

interface updateCameraGroupsParams {
    id: number;
    groups_to_add: number[];
    groups_to_rm: number[];
}

type DisabledReason = "MANUAL_DISABLED" | "OVERDUE_PAYMENT_ON_ASAAS" | "EXPIRED_DEMO";

interface CrmGroup {
    id: number;
    title: string;
    WhitelabelId: number;
    observation: string;
    enabled: boolean;
    userDefault: boolean;
    hasChat: boolean;
    lprWhitelist: boolean;
    GroupUser: WhitelabelUser[];
    GroupCamera: CrmGroupCamera[];
    GroupGateway: [];
    PermissionsLogs: [];
    GroupPermissions: groupPermissionRequest[];
}

interface CrmUserData {
    id: number;
    name: string;
    email: string;
    phone: number;
    is_activated: boolean;
    disabled_reason?: DisabledReason;
    master_admin: boolean;
    hasValidInfoForPayment: boolean;
}

interface GetUsersParams {
    page: number;
    filter: string;
    whiteLabelId?: number;
}

interface GetCrmCamerasParams {
    page: number;
    filter: string;
    online: boolean;
    offline: boolean;
    disabled: boolean;
}

interface ZipCode {
    logradouro: string;
    bairro: string;
    localidade: string;
    uf: string;
}

interface GetCrmUserGroups {
    checked?: boolean;
    id: number;
    title: string;
    type: string;
}

interface GetCrmUserPermissions {
    checked?: boolean;
    id: number;
    title: string;
    type: string;
}

interface GetCrmUser {
    id?: number;
    cep?: string;
    city?: string;
    complement?: string;
    cpf?: string;
    cnpj?: string;
    district?: string;
    email?: string;
    name: string;
    password?: string;
    phone?: string;
    state?: string;
    street?: string;
    streetNumber?: string;
    observation?: string;
    GroupUser?: GetCrmUserGroups[];
    PermissionLevels?: GetCrmUserPermissions[];
    demoExpirationDate?: Date;
}

interface UpdateCrmUserGroups {
    groups_to_add: number[],
    groups_to_rm: number[];
}

interface GetUserAsaasPayments {
    billingType: string;
    customer: string;
    dateCreated: Date;
    dueDate: string;
    externalReference: number;
    id: string;
    status: 'PENDING' | 'CONFIRMED' | 'RECEIVED' | 'RECEIVED_IN_CASH' | 'OVERDUE' | 'REFUND_REQUESTED' | 'REFUNDED' | 'CHARGEBACK_REQUESTED' |
    'CHARGEBACK_DISPUTE' | 'AWAITING_CHARGEBACK_REVERSAL' | 'DUNNING_REQUESTED' | 'DUNNING_RECEIVED' | 'AWAITING_RISK_ANALYSIS';
    value: number;
    invoiceUrl: string;
    invoice?: {
        status: 'SCHEDULED' | 'SYNCHRONIZED' | 'AUTHORIZED' | 'PROCESSING_CANCELLATION' | 'CANCELED' | 'CANCELLATION_DENIED' | 'ERROR';
        pdfUrl: string;
    };
}

interface GetUserAsaasSubscription {
    data: {
        billingType?: string;
        customer?: string;
        cycle?: string;
        externalReference?: number;
        id?: string;
        nextDueDate?: string;
        value?: number;
    };
    hasMore: boolean;
    limit: number;
    object: string;
    offset: number;
    totalCount: number;
}

interface WhitelabelGroup {
    id: number;
    title: string;
    checked: boolean;
}

interface UpdateCrmPermissionLevelsParams {
    id?: number;
    title?: string;
    observation?: string;
    enabled?: boolean;
    userDefault?: boolean;
    users_to_add?: number[];
    users_to_rm?: number[];
    permissions: PermissionLevelsPermissions;
}

interface PermissionLevelsPermissions {
    canSeeCameraTab?: boolean;
    canSeeCameraOptions?: boolean;
    canEditCameraOptions?: boolean;
    canCreateCamera?: boolean;
    canCreateDashboard?: boolean;
    canSeeCameraTotalizer?: boolean;
    canSeeCameraRTSP?: boolean;
    canSeeUserTab?: boolean;
    canSeeUserTotalizer?: boolean;
    canSeeUserDetails?: boolean;
    canEditUser?: boolean;
    canEditUserPayment?: boolean;
    canDeleteUser?: boolean;
    canInviteUsers?: boolean;
    canSeeCameraGroupTab?: boolean;
    canEditCameraGroup?: boolean;
    canSeePermissionLevelsTab?: boolean;
    canEditPermissionLevels?: boolean;
    canSeePlatformPaymentInfo?: boolean;
    mobileAccess?: boolean;
    canCreateTrialUsers?: boolean;
    canSeeMarketingKit?: boolean;
    canSeeLprSituation?: boolean;
    canSeeLprBlacklist?: boolean;
    canEditLprBlacklist?: boolean;
    canSeeLprBlacklistAlert?: boolean;
    canSeeLprDemo?: boolean;
    canSeeApiTab?: boolean;
    canEditApi?: boolean;
    canSeeInvestigativeMap?: boolean;
    canEditCameraAds?: boolean;
    canSeeEmbedValue?: boolean;
    canEnableCamera?: boolean;
}

interface ChargesList {
    id: number;
    WhitelabelId: number;
    status: 'paid' | 'accumulated' | 'analyze_pending' | 'processing' | 'waiting_payment' | 'pending_payment' | 'unpaid' | 'outsourced_billing' | 'canceled' | 'refunded' | 'chargedback' | 'refused' | 'estimatedValue' | 'accumulation_transferred' | 'to_clear' | 'cleared';
    total: number;
    createdAt: string;
    boleto_expiration_date: string;
    transaction_id?: string;
    type?: string;
    boleto_url?: string;
    Invoice?: {
        pdf?: string;
    };
}

interface DetailedCharge {
    ChargeItems: ChargeItems[];
    id: number;
    boleto_expiration_date: string;
    createdAt: string;
    WhitelabelId: number;
}

interface ChargeItems {
    id: number;
    description: string;
    value: string;
    quantity: string;
    data: {
        name?: string;
        type?: string;
        p2p?: number;
        PlanId?: number;
        cameras?: number;
        camera?: number;
        unique_cameras?: number;
        charge?: string;
        description?: string;
        amount?: number;
        quantity?: number;
        month?: number;
        total?: number;
        translate?: {
            plan?: number,
            amount?: number,
            anticipation?: number,
            description?: string;
        },
    };
    group: string;
    ChargeWhitelabelId: number;
}

interface Predictions {
    id: number;
    accumulated: number;
    accumulateds: {
        charge: string;
        month: number;
        total: number;
    }[];
    horus: number;
    marketingFeeTotal: {
        quantity: number;
        value: number;
        total: number;
    },
    planDiscount: number;
    planDiscounts: {
        PlanId: number;
        p2p: number;
        description: string;
        quantity: number;
        total: number;
    }[];
    planPackage: number;
    planPackages: PlanPackages[];
    rentalPackages: { [packageName: string]: Rentals; };
    rentals: { [rentalName: string]: Rentals; };
    servicePackages: ServicePackagesPredictions[];
    servicePackage: number;
    royalties: {
        description: string;
        value: number;
        quantity: number;
        total: number;
    }[];
    royaltiesValue: number;
    serviceDiscount: number;
    serviceDiscounts: { type: string; camera: number; total: number; }[];
    storage: number;
    storages: {
        isP2p: number;
        name: string;
        unique_cameras: number;
        total: number;
    }[];
    usages: {
        isP2p: number;
        name: string;
        unique_cameras: number;
        total: number;
    }[];
    usage: number;
    period: {
        limit: Date;
        start: Date;
    };
    generic: {
        data: {
            translate: {
                description: string;
            };
        };
        description: string;
        value: number;
    }[];
    total: number;
}

interface PlanPackages {
    description: string;
    total: number;
    cameras: number;
    quantity: number;
    PlanId: number;
    WhitelabelPlanPackageId: number;
}

interface ServicePackagesPredictions {
    description: string;
    total: number;
    cameras: number;
    quantity: number;
    type: string;
    WhitelabelServicePackageId: number;
}

interface Rentals {
    [rentalType: string]: RentalData;
}

interface RentalData {
    value: number;
    amount: number;
}

interface KitMarketing {
    id: number;
    title: string;
    s3_key: string;
    size: string;
    segments: string[];
    partnerTypes: string[];
    createdAt: string;
    format: string;
    url: string;
    thumbnail: string;
}
interface BlacklistGroup {
    id: number;
    title: string;
    description: string;
    whitelist: boolean;
    userManaged: boolean;
    blacklists: number;
    Users: {
        LprGroupUser: {
            canEdit: boolean;
            LprGroupId: number;
            UserId: number;
        },
        id: number;
        name: string;
    }[];
}

interface BlacklistParams {
    cameraGroupId?: string;
    page?: number;
    filter: string;
}

interface LPRCameraGroup {
    cameraGroupId: string;
    whitelist: boolean;
}

interface CreateBlacklistItemParams {
    id?: string;
    desc: string;
    plate: string;
    group?: number;
    cameraGroup?: LPRCameraGroup;
}

interface BlacklistItem {
    desc: string;
    plate: string;
    user_external_id?: number;
    id: number;
}

interface PrivatePreview {
    [key: string]: boolean | undefined;
}

interface DeleteCamera {
    CameraDeletionReasonId: number;
    id: number;
    deletionDescription?: string;
}

interface DeleteReason {
    id: number;
    name: string;
    order: number;
    ParentId?: number;
    Parent?: {
        id: number;
        name: string;
        order: number;
        ParentId: null;
    };
    Children: {
        ParentId: number;
        id: number;
        name: string;
        order: number;
    }[];
}

interface FormLprGroupParams {
    id?: string;
    title: string;
    description: string;
    whitelist: boolean;
    userManaged: boolean;
}

interface VehicleInformation {
    brand?: string;
    brand_model?: string;
    city?: string;
    color?: string;
    model?: string;
    model_year?: string;
    plate?: string;
    type?: string;
}

interface Person {
    id: number,
    name: string,
    watch_lists: number[],
    created_date: string,
    comment: string,
    active: boolean,
    thumbnail: string,
    images: {
        image: string,
        id: string;
    }[];
}

interface FacialFaces {
    next: string,
    pessoas: Person[],
    prev: string;
}

interface Category {
    id: number,
    color: string,
    name: string;
}

interface Categories {
    [categoryId: number]: Category;
}

function encodeQueryParams(params: { [key: string]: string | number | boolean | string[] | number[] | boolean[]; }) {
    const esc = encodeURIComponent;

    const query = Object.keys(params).map(k => {
        const value = params[k];
        if (Array.isArray(value)) {
            return value.map((param) => `${esc(k)}=${esc(param)}`).join('&');
        }
        return `${esc(k)}=${esc(value)}`;
    }).join('&');

    return "?" + query;
}

class PlatformAPI {

    getHeaders(token?: string, contentType?: string) {
        const headers = new Headers();
        headers.set("x-camerite-platform", "pwa");

        if (token) {
            headers.set("Authorization", "Bearer " + token);
        }

        if (contentType) {
            headers.set("Content-Type", contentType);
        }

        return headers;
    }

    /**
     *
     * @returns Returns if there's a bearer token stored on cache.
     */
    async isAuthenticated() {
        const token = await AsyncStorage.getItem('BEARER_TOKEN');

        if (!token) {
            return false;
        }

        return true;
    }

    /**
     *
     * @returns Returns a valid bearer token if the user is authenticated
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     */
    async checkAuth(): Promise<string> {
        const res = await this.getUserData();

        return res.token;
    }

    /**
     *
     * @returns Returns a list of available cameras to the authenticated user
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     */
    async getCameras(): Promise<CameraList[]> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/cameras`, {
            method: "GET",
            headers: this.getHeaders(token)
        });
        const cameras = await res.json();

        return cameras.mycameras.map((camera: CameraList) => {
            return {
                id: camera.id,
                title: camera.title,
                lastShot: camera.lastShot,
                showDefaultImage: false,
                factory: camera.factory,
                'District.City.name': camera['District.City.name'],
                area: camera.area,
                lat: camera.lat,
                lng: camera.lng
            };
        });
    }
    /**
     *
     * @returns Returns a camera based on a cameraId
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     * @throws {forbiddenAccess} It will return an error if the user does not have permission to access the video
     */
    async getCamera(cameraId: number): Promise<Camera> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/cameras/${cameraId}`, {
            method: "GET",
            headers: this.getHeaders(token)
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        const camera = await res.json();

        camera.webrtc_data = JSON.parse(camera.webrtc_data);

        return camera;
    }

    /**
     *
     * @returns Returns a camera based on a cameraId whithout permission to watch (can_i_watch_camera).
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     * @throws {forbiddenAccess} It will return an error if the user does not have permission to access the video
     */
    async getCameraForUpdate(cameraId: number): Promise<Camera> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/cameras/forupdate/${cameraId}`, {
            method: "GET",
            headers: this.getHeaders(token)
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        const camera = await res.json();

        return camera;
    }

    /**
     * @returns Returns camera config by id
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getCameraConfig(cameraId: number): Promise<CameraConfig> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/cameras/mycameraConfig/${cameraId}`, {
            method: "GET",
            headers: this.getHeaders(token)
        });

        const response = await res.json();

        return response.CameraConfig;
    }

    /**
     * Update camera config
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async updateCameraConfig(cameraId: number, { password, port, priv, pub, rtsp_address, title, type, username, override, latitude, longitude }: CameraEditProps) {
        const token = await this.checkAuth();
        const cameraForUpdate = await this.getCameraForUpdate(cameraId);

        if (type) cameraForUpdate.type = type;
        if (title) cameraForUpdate.title = title;
        if (latitude) cameraForUpdate.latitude = latitude;
        if (longitude) cameraForUpdate.longitude = longitude;
        if (rtsp_address !== undefined) cameraForUpdate.CameraConfig.rtsp_address = rtsp_address;
        if (priv !== undefined) cameraForUpdate.CameraConfig.private = priv;
        if (pub !== undefined) cameraForUpdate.CameraConfig.public = pub;

        if (cameraForUpdate.factory === "INTELBRAS" && override && username && password && port) {
            cameraForUpdate.authentication = {
                overrideAuth: override,
                username: username,
                password: password,
                port: port,
            };
        }
        const res = await fetch(`${backend_endpoint}/api/cameras/cameraConfigUpdate/${cameraId}`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({
                "camera": cameraForUpdate
            })
        });

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }
        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }

    }

    /**
     *
     * @returns Returns user personal camera notifications
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     */
    async getCameraNotification(camId: number): Promise<CameraNotifications> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/mobile/cameras/notifications/${camId}`, {
            method: "GET",
            headers: this.getHeaders(token)
        });
        const notification = await res.json();

        return notification;
    }

    /**
     *
     * @returns Updates user personal camera notifications
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     */
    async setCameraNotification({ all, movement, status, camId }: CameraNotifications): Promise<void> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/mobile/cameras/notifications/${camId}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({
                "all": all,
                "movement": movement,
                "status": status
            })
        });

        if (res.status != 204) {
            throw new Error("Unexpected error on updates camera notification");
        }
    }


    /**
     *
     * @returns Returns a camera based on a cameraId
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     * @throws {forbiddenAccess} It will return an error if the user does not have permission to access the video
     */
    async initializeCamera(cameraId: number): Promise<Camera> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/cameras/initialize/${cameraId}`, {
            method: "GET",
            headers: this.getHeaders(token)
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        const response = await res.json();

        const camera = response.p2p_status[0];

        camera.webrtc_data = JSON.parse(camera.webrtc_data);

        return camera;
    }

    /**
     *
     * @returns Returns a list of available video to the authenticated user
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     */
    async getVideos(): Promise<Video[]> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/cameras/videos`, {
            method: "GET",
            headers: this.getHeaders(token)
        });
        const videosJson = await res.json();

        return videosJson.map((video: Video) => {
            return {
                id: video.id,
                title: video.title,
                createdDate: video.createdDate,
                videoUrl: video.videoUrl,
                shotUrl: video.shotUrl,
                status: video.status,
                start: video.start,
                finish: video.finish,
                Camera: video.Camera
            };
        });

    }

    /**
     *
     * @returns Returns a video by id.
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     * @throws {forbiddenAccess} It will return an error if the user does not have permission to access the video
     */
    async getVideo(id: number): Promise<Video> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/cameras/videos/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 403 || res.status == 404) {
            throw new forbiddenAccess('Unauthorized access');
        }

        const video = await res.json();

        return video;
    }

    /**
     * Create Video/Download
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     */
    async createVideo({ title, start, finish, camId }: CreateVideo): Promise<void> {
        const token = await this.checkAuth();
        const startNumber = start.getTime();
        const finishNumber = finish.getTime();
        const res = await fetch(`${backend_endpoint}/api/cameras/download/${camId}`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({
                "title": title,
                "start": startNumber,
                "finish": finishNumber,
                "CameraId": camId
            })
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on create video");
        }

    }

    /**
     * Deletes a video
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     */
    async deleteVideo(id: number): Promise<void> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/cameras/deleteDownload/${id}`, {
            method: "POST",
            headers: this.getHeaders(token)
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on delete download");
        }

    }

    /**
     * Authenticates user using email
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async authenticateByMail(email: string, password: string) {
        const res = await fetch(`${backend_endpoint}/mobile/authenticate`, {
            method: "POST",
            headers: this.getHeaders(undefined, "application/json;charset=utf-8"),
            body: JSON.stringify({
                "user": {
                    "email": email,
                    "password": password
                }
            })
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on login");
        }
        const json_response = await res.json();
        const token = json_response.token;

        if (token) {
            await AsyncStorage.setItem('BEARER_TOKEN', token);
        }

    }

    /**
     * Create a new user without login using a token and a wlslug
     * @throws {Error} Will throw an error if there's something invalid on request
     */
    async signupByLink(inputs: setUserDataParameters, wlslug: string, token: string) {
        const res = await fetch(`${backend_endpoint}/auth/signup`, {
            method: "POST",
            headers: this.getHeaders(undefined, "application/json;charset=utf-8"),
            body: JSON.stringify({
                "name": inputs.name,
                "email": inputs.email,
                "password": inputs.password,
                "phone": inputs.phone,
                "cep": inputs.cep,
                "city": inputs.city,
                "complement": inputs.complement,
                "cpf": inputs.cpf,
                "cnpj": inputs.cnpj,
                "district": inputs.district,
                "state": inputs.state,
                "street": inputs.street,
                "streetNumber": inputs.streetNumber,
                "whitelabel": wlslug,
                "token": token
            })
        });

        if (res.status == 404) {
            throw new NotFound();
        }
        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }
        if (res.status != 200) {
            throw new UnexpectedError("Unexpected error on signupByLink");
        }

        const json_response = await res.json();
        const tokenSession = json_response.token;

        if (tokenSession) {
            await AsyncStorage.setItem('BEARER_TOKEN', tokenSession);
        }
    }

    async signupDemoByLink(inputs: setUserDataParameters, wlslug: string) {
        const res = await fetch(`${backend_endpoint}/auth/demo/signup`, {
            method: "POST",
            headers: this.getHeaders(undefined, "application/json;charset=utf-8"),
            body: JSON.stringify({
                "name": inputs.name,
                "email": inputs.email,
                "password": inputs.password,
                "phone": inputs.phone,
                "cep": inputs.cep,
                "city": inputs.city,
                "complement": inputs.complement,
                "cpf": inputs.cpf,
                "cnpj": inputs.cnpj,
                "district": inputs.district,
                "state": inputs.state,
                "street": inputs.street,
                "streetNumber": inputs.streetNumber,
                "whitelabel": wlslug
            })
        });

        if (res.status == 404) {
            throw new NotFound();
        }
        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }
        if (res.status != 200) {
            throw new UnexpectedError("Unexpected error on signupDemoByLink");
        }

        const json_response = await res.json();
        const tokenSession = json_response.token;

        if (tokenSession) {
            await AsyncStorage.setItem('BEARER_TOKEN', tokenSession);
        }
    }

    /**
     * Request an authentication using phone, it'll generate a SMS
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async signupPhone(phone: string) {
        const res = await fetch(`${backend_endpoint}/auth/signupPhone`, {
            method: "POST",
            headers: this.getHeaders(undefined, "application/json;charset=utf-8"),
            body: JSON.stringify({
                "phone": phone,
            })
        });

        if (res.status != 204) {
            throw new Error("Unexpected error on signupPhone");
        }

    }

    /**
     * Confirms authentication by phone using token received on SMS
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async confirmPhone(phone: string, token: string) {
        const res = await fetch(`${backend_endpoint}/auth/confirm-phone`, {
            method: "POST",
            headers: this.getHeaders(undefined, "application/json;charset=utf-8"),
            body: JSON.stringify({
                "phone": phone,
                "token": token,
            })
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on confirmPhone");
        }

        const json_response = await res.json();
        const tokenSession = json_response.token;

        if (tokenSession) {
            await AsyncStorage.setItem('BEARER_TOKEN', tokenSession);
        }

    }

    /**
     * Request a recover password using email, it'll generate a e-mail
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async recoverPassword(email: string) {
        const res = await fetch(`${backend_endpoint}/auth/recover`, {
            method: "POST",
            headers: this.getHeaders(undefined, "application/json;charset=utf-8"),
            body: JSON.stringify({
                "email": email,
            })
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on recover password");
        }
    }

    /**
     * Registers the device on camerite-platform so that it can be able to receive notifications
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async registerUserDevice(firebaseToken: string, deviceId: string, deviceOS: string) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/mobile/users/devices`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({
                id: 'fingerprint:' + deviceId,
                firebaseToken: firebaseToken,
                model: 'PWA',
                os: deviceOS,
                osVersion: navigator.platform || 'Unknow',
            })
        });

        if (res.status != 204) {
            throw new Error("Unexpected error on recover password");
        }
    }

    /**
     * Deletes the registry of device on camerite-platform so that it won't receive any notifications
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async unregisterUserDevice(firebaseToken: string) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/mobile/users/devices/${firebaseToken}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status != 204) {
            throw new Error("Unexpected error on recover password");
        }
    }

    /**
     * Returns a camera timeline
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getTimeline(cameraId: number): Promise<Timeline> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/v4/cameras/timeline/${cameraId}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status != 200) {
            return {
                key_map: {
                    startTime: "",
                    finishTime: "",
                    shotList: "",
                    shotTime: ""
                },
                videos: [],
                shot_template: "",
                video_template: "",
            };
        }

        return await res.json();
    }

    /**
     *
     * Updates user data receiving parameters name, email and phone
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getWhitelabel(): Promise<Whitelabel> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/mobile/users/whitelabel`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });
        const response = await res.json();

        return response;
    }

    /**
     * Create camera panic alert.
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async createPanicAlert(camId: number): Promise<boolean> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/mobile/panicAlert/${camId}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 403) {
            throw new forbiddenAccess("User doesn't have permission");
        }

        if (res.status == 200) {
            return true;
        }

        return false;
    }

    /**
     * @returns Returns a user data information
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     */

    async getUserData(): Promise<UserData> {
        const token = await AsyncStorage.getItem("BEARER_TOKEN");

        if (!token) {
            throw new invalidBearerToken("There's no token stored on cache.");
        }

        const res = await fetch(`${backend_endpoint}/api/mobile/v2/authenticate/check`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status != 200) {
            await AsyncStorage.removeItem("BEARER_TOKEN");
            throw new invalidBearerToken("The token token stored on cache is no longer valid.");
        }

        const response = await res.json();
        response.token = token;

        return response;
    }

    /**
     *
     * @returns Impersonate User
     * @throws {Error} Will issue an Error if something went wront on user impersonation.
     */
    async impersonateUser(userId: string) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/user/${userId}/token/impersonate`, {
            method: "PUT",
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }
    }

    /**
     *
     * @returns SignOut an account
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     */
    async signOut() {
        const token = await this.checkAuth();

        await fetch(`${backend_endpoint}/auth/signout`, {
            method: "GET",
            headers: this.getHeaders(token)
        });
    }

    /**
     *
     * Updates user data receiving parameters name, email and phone
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async setUserData({ cep, streetNumber, city, complement, observation, cpf, cnpj, district, email, name, phone, state, street, pushAll, pushMovement, pushStatus }: setUserDataParameters) {
        const token = await this.checkAuth();

        const body: setUserDataParameters = {};

        if (cep !== null) {
            body.cep = cep;
        }
        if (city !== null) {
            body.city = city;
        }
        if (complement !== null) {
            body.complement = complement;
        }
        if (cpf !== null) {
            body.cpf = cpf;
        }
        if (cnpj !== null) {
            body.cnpj = cnpj;
        }
        if (district !== null) {
            body.district = district;
        }
        if (email !== null) {
            body.email = email;
        }
        if (name !== null) {
            body.name = name;
        }
        if (phone !== null) {
            body.phone = phone;
        }
        if (state !== null) {
            body.state = state;
        }
        if (street !== null) {
            body.street = street;
        }
        if (streetNumber !== null) {
            body.streetNumber = streetNumber;
        }
        if (observation !== null) {
            body.observation = observation;
        }
        if (pushAll !== null) {
            body.pushAll = pushAll;
        }
        if (pushMovement !== null) {
            body.pushMovement = pushMovement;
        }
        if (pushStatus !== null) {
            body.pushStatus = pushStatus;
        }

        const res = await fetch(`${backend_endpoint}/api/mobile/updateUser`, {
            method: "POST",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(body)
        });

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on update user data");
        }

    }

    /**
     * Return the list of chats
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     */
    async getChatGroups(): Promise<ChatGroup[]> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/mobile/users/groups`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on get chat groups");
        }

        return await res.json();
    }


    /**
     * Send message in chat group
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async sendChatMessage(message: string, groupId: number) {

        const token = await this.checkAuth();

        const date = new Date().getTime();

        const res = await fetch(`${backend_endpoint}/api/mobile/users/message`, {
            method: "POST",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "groupId": groupId,
                "message": message,
                "timestamp": date
            })
        });

        if (res.status != 204) {
            throw new Error("Unexpected error on send messages");
        }
    }

    /**
     * Get messages from a group
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getChatMessage(groupId: number): Promise<ChatMessage[]> {

        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/mobile/users/messages/${groupId}`, {
            method: "GET",
            headers: this.getHeaders(token)
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on get messages");
        }

        return await res.json();
    }

    /**
     * Return terms of use
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getTerms(): Promise<string | undefined> {

        const res = await fetch(`${backend_endpoint}/api/mobile/defaultUseTerms`, {
            method: "GET"
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on get terms of use");
        }

        return (await res.json()).use_terms;
    }

    /**
     * Change the status of user terms and conditions from false to true
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async userAcceptTerms() {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/mobile/users/acceptTerms`, {
            method: "PUT",
            headers: this.getHeaders(token)
        });

        if (res.status != 204) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }
    }

    /**
     * Return license plate readings
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getLprDetections({ page, startDate, finishDate, order, plate, cameras, blacklist }: GetLprDetectionParams): Promise<LprDetectionObject> {
        const token = await this.checkAuth();

        const params = {
            page: page || 0,
            startDate: startDate ? startDate.getTime() : new Date().getTime() - 86400000,
            finishDate: finishDate ? finishDate.getTime() : new Date().getTime(),
            order: order || "desc",
            plate: plate || "",
            cameras: cameras || "",
            blacklist: blacklist || false,
            count: false
        };

        const res = await fetch(`${backend_endpoint}/api/crm/lprdetections${encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on get license plate readings");
        }

        return await res.json();
    }

    /**
     * Return license plate reading by id
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getLprDetectionById(id: number): Promise<LprDetection> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/lprdetections/${id}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status == 404) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get license plate readings");
        }

        return await res.json();
    }

    /**
     * Returns cameras with lpr function enabled.
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getLprCameras(): Promise<LprCamera[]> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/lpr/cameras`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status == 404) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get license plate readings");
        }

        return await res.json();
    }

    /**
     * Return list users by pages.
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getWhiteLabelUsers(wlslug?: string): Promise<WhitelabelUser[]> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/users/${wlslug ? wlslug : 'camerite'}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on get users list");
        }

        return (await res.json());
    }

    /**
     * Returns whitelabel camera list.
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getWhiteLabelCameras(wlslug?: string): Promise<CrmGroupCamera[]> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/cameras/${wlslug ? wlslug : 'camerite'}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on get users list");
        }

        return (await res.json());
    }

    /**
    * Returns camera list by page.
    * @throws {Error} Will throw an error if there's something invalid on authentication
    */
    async getCrmCameras({ page, filter, online, offline, disabled }: GetCrmCamerasParams): Promise<CrmCameras[]> {
        const token = await this.checkAuth();

        const params = {
            filterParams: JSON.stringify({
                online: online,
                offline: offline,
                disabled: disabled
            }),
            order: "asc",
            page: page || 0,
            filter: filter,
            sort: "title"
        };

        const res = await fetch(`${backend_endpoint}/api/crm/cameras-mobile${encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on get users list");
        }
        return (await res.json()).results;
    }

    /**
   * Returns a list of groups that are associated with a specific camera.
   * @throws {Error} Will throw an error if some there's something invalid on authentication
   */
    async getCameraGroups(id: number): Promise<CrmCameraGroups[]> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/camera/${id}/groups`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on get users list");
        }
        return (await res.json());
    }

    /** Request the alteration change on camera groups list
     * @throws { Error } Will throw an error if some there's something invalid on authentication
     */
    async updateCameraGroups({ id, groups_to_add, groups_to_rm }: updateCameraGroupsParams): Promise<void> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/camera/${id}/groups`, {
            method: "PUT",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "groups_to_rm": groups_to_rm,
                "groups_to_add": groups_to_add,
            })
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on editing this camera.");
        }
    }

    /**
     * Return list users by pages.
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getCrmUsers({ page, filter }: GetUsersParams): Promise<CrmUserData[]> {
        const token = await this.checkAuth();

        const params = {
            order: "asc",
            page: page || 0,
            filter: filter
        };

        const res = await fetch(`${backend_endpoint}/api/crm/users${encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on get users list");
        }

        return (await res.json()).results;
    }

    /**
     * Return get user info
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getCrmUser(id: number): Promise<GetCrmUser> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/crm/user/${id}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }
        if (res.status != 200) {
            throw new Error("Unexpected error on get user info");
        }
        return await res.json();
    }

    /**
     * Return user payments details
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getAsaasPayments(id: number): Promise<GetUserAsaasPayments[]> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/user/asaasPayments/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get user info");
        }
        return (await res.json()).data;
    }

    /**
     * New Asaas payment
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async newAsaasPayment(id: number, date: Date, value: number) {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/user/asaasPayments/${id}`, {
            method: "POST",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "date": date,
                "value": value
            })
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get user info");
        }
    }

    /**
     * Delete Asaas payment
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async deleteAsaasPayment(id: string) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/user/asaasPayments/deletePayment/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token)
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get user info");
        }
    }

    /**
     * New Asaas Subscription
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async newAsaasSubscription(id: number, value: number, nextDueDate: string) {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/user/asaasSubscription/${id}`, {
            method: "POST",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                value: value,
                nextDueDate: nextDueDate,
            })
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get user info");
        }
    }

    /**
     * Delete Asaas subscription
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async deleteAsaasSubscription(id: number) {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/user/asaasSubscription/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get user info");
        }
    }

    /**
     * Return subscription details
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getAsaasSubscription(id: number): Promise<number | null> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/user/asaasSubscription/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get user info");
        }

        const response = await res.json();

        if (!response.data.length) {
            return null;
        }

        return response.data[0].value;
    }

    /**
     * Return privacy policy
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async getPolicy(): Promise<string | undefined> {

        const res = await fetch(`${backend_endpoint}/api/mobile/defaultPrivacyPolicy`, {
            method: "GET"
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on get license plate readings");
        }

        return (await res.json()).privacy_policy;
    }

    /**
     * Return zip-code
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getZipCode(zipCode: string | undefined): Promise<ZipCode> {
        const res = await fetch(`${backend_endpoint}/api/partner/search-cep/${zipCode}`, {
            method: "GET"
        });

        const cep = await res.json();

        if (res.status != 200) {
            throw new Error("Unexpected error on get zip code");
        }

        if (cep.erro) {
            throw new Error("Unexpected error on get zip code");
        }

        return cep;
    }

    /**
     * Register user
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async registerUser({ cep, streetNumber, city, complement, observation, cpf, cnpj, district, email, name, phone, state, street, password, demoExpirationDate }: GetCrmUser) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/user`, {
            method: "POST",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "cep": cep,
                "city": city,
                "complement": complement,
                "cpf": cpf,
                "cnpj": cnpj,
                "district": district,
                "email": email,
                "name": name,
                "phone": phone,
                "state": state,
                "street": street,
                "streetNumber": streetNumber,
                "observation": observation,
                "password": password,
                "demoExpirationDate": demoExpirationDate
            })
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on register user");
        }
    }

    /**
     * Edit user
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async updateUser(id: number, { cep, streetNumber, city, complement, observation, cpf, cnpj, district, email, name, phone, state, street }: GetCrmUser) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/user/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                cep: cep,
                city: city,
                complement: complement,
                cpf: cpf,
                cnpj: cnpj,
                district: district,
                email: email,
                name: name,
                phone: phone,
                state: state,
                street: street,
                streetNumber: streetNumber,
                observation: observation,
            })
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on edit user");
        }
    }

    /**
     * Change a user password just with an email sent token
     */
    async newPassword(token: string, newPassword: string) {
        const res = await fetch(`${backend_endpoint}/auth/newPassword`, {
            method: "POST",
            headers: this.getHeaders(undefined, "application/json;charset=utf-8"),
            body: JSON.stringify({
                token: token,
                newPassword: newPassword
            })
        });

        if (res.status != 200) {
            throw new ClientError("Unexpected error on new password");
        }

        const json_response = await res.json();
        const tokenSession = json_response.user.token;
        if (tokenSession) {
            await AsyncStorage.setItem('BEARER_TOKEN', tokenSession);
        }
    }

    /**
     * Assign camera or permissions groups to users.
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async updateUserGroups(id: number, { groups_to_add, groups_to_rm }: UpdateCrmUserGroups) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/user/${id}/updateUserGroups`, {
            method: "PUT",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                groups_to_add: groups_to_add,
                groups_to_rm: groups_to_rm
            })
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 201) {
            throw new Error("Unexpected error on update user groups");
        }
    }

    /**
     * Disable or enable user
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async changeUserStatus(id: number, isActivated: boolean) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/user/${id}/updateUser`, {
            method: "PUT",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "isActivated": isActivated
            })
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 201) {
            throw new Error("Unexpected error on change user status");
        }
    }

    /**
     * Delete user
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async deleteUser(id: number) {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/crm/user/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on edit user");
        }
    }

    /**
     * Return email validation
     */
    async checkEmail(email: string): Promise<boolean> {
        const res = await fetch(`${backend_endpoint}/api/user/available-email`, {
            method: "POST",
            headers: this.getHeaders(undefined, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "email": email
            })
        });

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on register user");
        }

        const emailValidation = await res.json();

        return emailValidation.emailAvailable;
    }

    /** Return CRM Group Cameras
    * @throws {Error} Will throw an error if there's something invalid on authentication
    */
    async getCrmGroups({ page, filter }: PageParam): Promise<CrmGroupListItem[]> {
        const token = await this.checkAuth();
        const params = {
            filterParams: JSON.stringify({ hasCameraPermission: true }),
            order: "asc",
            filter: filter || "",
            page: page || 1,
            sort: "title",
        };
        const res = await fetch(`${backend_endpoint}/api/crm/groups/${encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get group cameras list");
        }
        return (await res.json()).results;
    }

    /**
     *
     * Returns camera plans
     * @throws {forbiddenAccess} It will return an error if the user does not have permission to access the video
     */
    async getPlans(): Promise<Plan[]> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/plans/wl`, {
            method: "GET",
            headers: this.getHeaders(token)
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on get plans list");
        }

        const plans = await res.json();
        return plans.data;
    }

    /**
     * Publish new camera
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async publishCamera({ title, streamName, factory, isRtmpCamera, rtsp, plan, username, password, port, lat, lng, area }: RegisterCamera): Promise<{ id: number; }> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/cameras/savePublishCamera`, {
            method: "POST",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "title": title,
                "streamName": streamName,
                "isRtmpCamera": isRtmpCamera,
                "factory": factory,
                "rtsp": rtsp,
                "plan": plan,
                "username": username,
                "password": password,
                "port": port,
                "latitude": lat,
                "longitude": lng,
                "area": area
            })
        });

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }
        return await res.json();
    }

    /**
     * SerialNumber verification
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async validP2P({ id, rtsp, factory, username, password, port }: ValidP2P) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/cameras/publishP2P`, {
            method: "POST",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "factory": factory,
                "id": id,
                "username": username,
                "password": password,
                "port": port,
                "rtsp": rtsp,
            })
        });
        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }
        if (res.status !== 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }
        return await res.json();
    }

    /** Return list of users in associated with the provided Group
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getCrmGroup(id: number): Promise<CrmGroup> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/groups/${id}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get this group.");
        }
        const response = await res.json();

        if (response.type !== "CAMERA") {
            throw new forbiddenAccess("Invalid group type.");
        }
        return response;
    }

    /** Request the alteration of an existing group, changing their users list
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async updateCrmGroup({ id, users_to_add, title, observation, enabled, userDefault, permissions, hasChat, users_to_rm, cameras_to_add, cameras_to_rm }: updateCrmGroupParams): Promise<void> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/groups/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "type": "CAMERA",
                "title": title,
                "observation": observation,
                "enabled": enabled,
                "userDefault": userDefault,
                "hasChat": hasChat,
                "permissions": permissions ? permissions : {},
                "users_to_rm": users_to_rm,
                "users_to_add": users_to_add,
                "cameras_to_rm": cameras_to_rm,
                "cameras_to_add": cameras_to_add,
            })
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on editing this group.");
        }
    }

    /** Rquest the the creation of a new group
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async AddGroup({ title, observation, userDefault, permissions, hasChat, }: createGroupParams): Promise<void> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/groups/`, {
            method: "POST",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "type": "CAMERA",
                "title": title,
                "observation": observation,
                "enabled": true,
                "userDefault": userDefault,
                "hasChat": hasChat,
                "permissions": permissions ? permissions : {},
                "users_to_add": [],
                "cameras_to_add": [],
                "logs_to_add": [],
                "gateways_to_add": [],
            })
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on editing this group.");
        }
    }

    /** Return whitelabel groups
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getCrmGroupWl(wlslug?: string): Promise<WhitelabelGroup[]> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/groups/wl/${wlslug ? wlslug : 'camerite'}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get whitelabel groups.");
        }

        return await res.json();
    }

    /**
     * Delete group
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async deleteGroup(id: number) {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/crm/groups/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on edit user");
        }
    }

    /** Return whitelabel permissions groups
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getCrmPermissionsWl(wlslug?: string): Promise<WhitelabelGroup[]> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/permissionlevels/wl/${wlslug ? wlslug : 'camerite'}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get whitelabel permissions groups.");
        }

        return await res.json();
    }

    /** Return a list of user history payments
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getUserPayments(): Promise<GetUserAsaasPayments[]> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/user/asaasPayments`, {
            method: "GET",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on get user payments");
        }
        const response = await res.json();
        return response.data;
    }
    /** Return CRM Group Permissions
    * @throws {Error} Will throw an error if there's something invalid on authentication
    */
    async getCrmPermissionLevel({ page, filter }: PageParam): Promise<CrmGroupListItem[]> {
        const token = await this.checkAuth();
        const params = {
            filterParams: JSON.stringify({ hasCameraPermission: false }),
            order: "asc",
            filter: filter || "",
            page: page || 1,
            sort: "title",
        };
        const res = await fetch(`${backend_endpoint}/api/crm/groups/${encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get permission levels list");
        }
        return (await res.json()).results;
    }

    /** Return list of users in associated with the provided permissions group
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getCrmGroupPermissions(id: number): Promise<CrmGroup> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/groups/${id}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get this permissions group.");
        }
        const response = await res.json();

        if (response.type !== "PERMISSION") {
            throw new forbiddenAccess("Invalid group type.");
        }
        return response;
    }

    /** Request the alteration of an existing permissions group, changing their users list
    * @throws { Error } Will throw an error if there's something invalid on authentication
    */
    async updateCrmPermissionsGroup({ id, users_to_add, title, observation, enabled, userDefault, users_to_rm, permissions }: UpdateCrmPermissionLevelsParams): Promise<void> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/groups/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "type": "PERMISSION",
                "title": title,
                "observation": observation,
                "enabled": enabled,
                "userDefault": userDefault,
                "permissions": permissions ? permissions : {},
                "users_to_rm": users_to_rm,
                "users_to_add": users_to_add,
            })
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on editing this permissions group.");
        }
    }

    /** Request the the creation of a new permissions group
    * @throws { Error } Will throw an error if there's something invalid on authentication
    */
    async addLevelPermissions({ title, observation, userDefault, permissions }: UpdateCrmPermissionLevelsParams): Promise<void> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/groups`, {
            method: "POST",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "type": "PERMISSION",
                "title": title,
                "observation": observation,
                "enabled": true,
                "userDefault": userDefault,
                "permissions": permissions ? permissions : {},
                "users_to_add": [],
            })
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on add level permissions on user.");
        }
    }

    /**
     * Rtsp verification
     * @throws {Error} Will throw an error if there's something invalid on authentication
     */
    async testRtsp(rtsp: string) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/cameras/testRtsp`, {
            method: "POST",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                "rtsp": rtsp,
            })
        });
        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }
        if (res.status !== 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }
        return await res.json();
    }
    /** Return history charges in list
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getHistoryCharges(): Promise<ChargesList[]> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/crm/financial/charges-for-pwa`, {
            method: "GET",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });
        if (res.status != 200) {
            throw new Error("Unexpected error on get history charges");
        }
        return res.json();
    }

    /** Return a current charge
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getCurrentCharge(id: number): Promise<Predictions> {
        const token = await this.checkAuth();
        const date = new Date();
        const firstDayNextMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDayNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const params = {
            start: firstDayNextMonth.toISOString(),
            limit: lastDayNextMonth.toISOString(),
        };
        const res = await fetch(`${backend_endpoint}/api/crm/financial/predictions/${id}?limit=${params.limit}&start=${params.start}`, {
            method: "GET",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on get current charge");
        }

        return res.json();
    }
    /**
     *
     * @returns Returns a list of Marketing Kit platform
     * @throws {invalidBearerToken} Will throw an error if there's something invalid on authentication
     */
    async getMarketingKit(): Promise<KitMarketing[]> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/crm/marketingfile`, {
            method: "GET",
            headers: this.getHeaders(token)
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error");
        }

        const marketingKit = await res.json();
        return marketingKit;
    }

    /**
     * Returns the blacklist groups.
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getBlacklistGroups({ page, filter }: PageParam): Promise<BlacklistGroup[]> {
        const token = await this.checkAuth();
        const params = {
            order: "asc",
            filter: filter || "",
            page: page || 1,
            sort: "title",
        };
        const res = await fetch(`${backend_endpoint}/api/crm/lpr/group${encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get blacklist groups list");
        }

        const responseJson = await res.json();

        return responseJson.results;
    }

    /**
     * Creates an lpr group.
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async createLprGroup({ title, description, whitelist, userManaged }: FormLprGroupParams) {
        const token = await this.checkAuth();

        const params = {
            title: title,
            description: description,
            whitelist: whitelist,
            userManaged: userManaged,
            users_to_add: [],
            cameras_to_add: []
        };

        const res = await fetch(`${backend_endpoint}/api/crm/lpr/group`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params)
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get blacklist groups list");
        }
    }

    /**
     * Updates an lpr group.
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async updateLprGroup({ id, title, description, whitelist, userManaged }: FormLprGroupParams) {
        const token = await this.checkAuth();

        const params = {
            title: title,
            description: description,
            whitelist: whitelist,
            userManaged: userManaged
        };

        const res = await fetch(`${backend_endpoint}/api/crm/lpr/group/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params)
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get blacklist groups list");
        }
    }

    /**
     * Deletes an lpr group.
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async deleteLprGroup(id: number) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/lpr/group/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token)
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get blacklist groups list");
        }
    }

    /**
     * Returns an lpr group.
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getBlacklistGroup(id: string): Promise<BlacklistGroup> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/crm/lpr/group/${id}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });
        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get blacklist groups list");
        }

        return await res.json();
    }

    /**
     * Returns a blacklist.
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getBlacklist({ cameraGroupId, filter, page }: BlacklistParams): Promise<BlacklistItem[]> {
        const token = await this.checkAuth();

        const params = {
            page: page ? page - 1 : 0,
            filter: filter,
            cameraGroupId: cameraGroupId || '',
            max_results: 10
        };

        const res = await fetch(`${backend_endpoint}/api/crm/lpr/blacklist${encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get blacklist groups list");
        }

        const responseJson = await res.json();

        return responseJson.results;
    }

    /**
     * Returns a blacklist item.
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getBlacklistItem({ id, cameraGroupId }: { id: string, cameraGroupId?: string; }): Promise<BlacklistItem> {
        const token = await this.checkAuth();

        let queryParams = '';

        if (cameraGroupId) {
            const params = {
                cameraGroupId: cameraGroupId
            };
            queryParams = encodeQueryParams(params);
        }

        const res = await fetch(`${backend_endpoint}/api/crm/lpr/blacklist/${id}${queryParams}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get blacklist groups list");
        }

        return await res.json();
    }

    /**
     * Creates a blacklist item.
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async createBlacklistItem({ desc, plate, cameraGroup }: CreateBlacklistItemParams): Promise<void> {
        const token = await this.checkAuth();

        const params = {
            desc: desc,
            plate: plate,
            cameraGroup: cameraGroup
        };

        const res = await fetch(`${backend_endpoint}/api/crm/lpr/blacklist`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params)
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get blacklist groups list");
        }

    }

    /**
     * Updates a blacklist item.
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async updateBlacklistItem({ id, desc, plate, cameraGroup, group }: CreateBlacklistItemParams): Promise<void> {
        const token = await this.checkAuth();

        const params = {
            id: id,
            desc: desc,
            plate: plate,
            cameraGroup: cameraGroup,
            group: group
        };

        const res = await fetch(`${backend_endpoint}/api/crm/lpr/blacklist/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params)
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get blacklist groups list");
        }

    }

    /**
     * Updates a blacklist item.
     * @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async deleteBlacklistItem({ plate, cameraGroup }: { plate: string, cameraGroup?: LPRCameraGroup; }): Promise<void> {
        const token = await this.checkAuth();
        let queryParams = '';

        if (cameraGroup) {
            const params = {
                cameraGroupId: cameraGroup.cameraGroupId,
                whitelist: cameraGroup.whitelist
            };
            queryParams = encodeQueryParams(params);
        }

        const res = await fetch(`${backend_endpoint}/api/crm/lpr/blacklist/${plate}${queryParams}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get blacklist groups list");
        }
    }

    /**
     * Returns the private preview list
     *  @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getPrivatePreview(): Promise<PrivatePreview> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/private-preview-from-wl`, {
            method: "GET",
            headers: this.getHeaders(token)
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new Error("Unexpected error on get private preview list");
        }

        return await res.json();
    }


    /**
    * Updates camera plans
    * @param camIdToServer - The camera ID to update the plan
    * @param newPlan - The new plan for the camera
    * @throws {ForbiddenAccess} It will return an error if the user does not have permission to access the video
    */
    async updateCameraPlan(cameraId: number, planId: number): Promise<void> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/cameras/changePlan/${cameraId}`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ 'newPlan': planId }),
        });
        if (res.status !== 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }
        return await res.json();
    }

    /**
     * Delete camera
     * @throws {Error} Will throw an error if some there's something invalid on authentication
     */
    async cameraDelete({ CameraDeletionReasonId, deletionDescription, id }: DeleteCamera) {

        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/cameras/deleteCamera/${id}`, {
            method: "POST",
            headers: this.getHeaders(token),
            body: JSON.stringify({
                "CameraDeletionReasonId": CameraDeletionReasonId,
                "deletionDescription": deletionDescription
            })
        });

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }
    }

    /**
     * Returns delete reasons
     * @throws {forbiddenAccess} It will return an error if the user does not have permission to access the video
     */
    async getDeleteReasons(): Promise<DeleteReason[]> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/cameraDeletionReason`, {
            method: "GET",
            headers: this.getHeaders(token)
        });

        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }
        return await res.json();
    }

    /**
     *  Return vehicle informations
     *  @throws { Error } Will throw an error if there's something invalid on authentication
     */
    async getVehicleInfo(plate: string): Promise<VehicleInformation> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/crm/vehicleInfo/${plate}`, {
            method: "GET",
            headers: this.getHeaders(token)
        });

        if (res.status == 404) {
            throw new ClientError("VEHICLE_NOT_FOUND");
        }

        if (res.status == 403) {
            throw new forbiddenAccess('Unauthorized access');
        }

        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }

        return await res.json();
    }

    /**
     * Return rental types
     * @throws { Error } Will throw an error if some there's something invalid on authentication
     */
    async getRentalTypes(): Promise<string> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/crm/rental/type`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on get rental types");
        }

        return res.json();
    }

    /**
     * Returns all plans
     * @throws {forbiddenAccess} It will return an error if the user does not have permission to access the video
     */
    async getAllPlans(): Promise<Plan[]> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/plans`, {
            method: "GET",
            headers: this.getHeaders(token)
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on get all plans");
        }

        const plans = await res.json();
        return plans.data;
    }

    /**
     * Return a detailed charge by id
     * @throws { Error } Will throw an error if some there's something invalid on authentication
     */
    async getDetailedCharge(id: number): Promise<DetailedCharge> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/crm/financial/charges-for-pwa/${id}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status != 200) {
            throw new Error("Unexpected error on get detailed charge");
        }

        return res.json();
    }

    /**
     *
     * Dowload detailed charge by id
     * @throws { Error } Will throw an error if some there's something invalid on authentication
     */
    async downloadDetailedCharge(chargeId: number) {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/crm/financial/charge/download/${chargeId}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        const fileName = res.headers.get("Content-Language");

        if (res.status != 200 || !fileName) {
            throw new Error("Unexpected error on get detailed charge");
        }

        const blob = await res.blob();
        const newUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = newUrl;
        link.setAttribute('download', fileName);
        link.click();
    }

    /**
     *
     * Facial defect search
     * @throws { Error } Will throw an error if some there's something invalid on authentication
     */
    async facialDetect(imageData: string): Promise<FacialFaces> {
        const token = await this.checkAuth();

        const base64ImageData = imageData.split(',')[1];

        const binaryData = atob(base64ImageData);
        const byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: "image/png" });

        const imageFile = new File([blob], "sample-image.png", { type: "image/png" });

        const formData = new FormData();
        formData.append('photo', imageFile);

        const res = await fetch(`${backend_endpoint}/facial/detect/`, {
            method: "POST",
            headers: this.getHeaders(token),
            body: formData,
        });

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }

        return res.json();
    }

    /**
     *
     * Get Facial Categories
     * @throws { Error } Will throw an error if some there's something invalid on authentication
     */
    async getCategories(): Promise<Categories> {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/facial/watchLists`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        const response = await res.json();

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }

        const categories: Categories = {};

        for (const category of response.categorias) {
            categories[category.id] = category;
        }

        return categories;
    }
    /**
     *
     * Get Singup fields properties
     * @throws { Error } Will throw an error if some there's something invalid on authentication
     */
    async getWhitelabelSingupFields(): Promise<WhitelabelSignupField> {
        const token = await this.checkAuth();
        const res = await fetch(`${backend_endpoint}/api/mobile/users/whitelabel`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }

        const newRes = await res.json();

        for (const prop in defaultSignupFieldsValues) {
            if (newRes.signup_fields[prop] === undefined) {
                newRes.signup_fields[prop] = "DISABLE";
            }
        }

        return newRes;
    }

    /**
 *
 * Get Singup fields properties just using wlslug
 * @throws { UnexpectedError } Will throw an error if some there's some error
 */
    async getWhitelabelSingupFieldsWithSlug(wlslug: string): Promise<WhitelabelSignupField> {
        const res = await fetch(`${backend_endpoint}/api/mobile/users/whitelabel/signup-fields/${wlslug}`, {
            method: "GET",
        });

        if (res.status >= 400 && res.status <= 499) {
            const response = await res.json();
            throw new ClientError(response.message);
        }

        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }

        const newRes = await res.json();

        for (const prop in defaultSignupFieldsValues) {
            if (newRes.signup_fields[prop] === undefined) {
                newRes.signup_fields[prop] = "DISABLE";
            }
        }

        return newRes;
    }

    /**
     *
     * Send Personal Panic Notification
     * @throws { Error } Will throw an error if some there's something invalid on authentication
     */
    async sendPersonalPanicNotification(lat: number, lng: number) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/mobile/personalPanic/${lat}/${lng}`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }
    }

    /**
     *
     * Send NPS Survey Score
     * @throws { Error } Will throw an error if some there's something invalid
     */
    async sendNpsSurvey(score: number | null, scoreJustification: string | null) {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/survey/pwa/sendScore`, {
            method: "POST",
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                'responseChannel': 'PWA',
                'score': score,
                'scoreJustification': scoreJustification
            })
        });

        if (res.status != 200) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }
    }

    /**
     *
     * Send Generic Notification
     * @throws { Error } Will throw an error if some there's something invalid
     */
    async sendGenericNotification() {
        const token = await this.checkAuth();

        const res = await fetch(`${backend_endpoint}/api/mobile/notification/test`, {
            method: "GET",
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status != 204) {
            throw new UnexpectedError("UNEXPECTED_ERROR");
        }
    }
}

export const platformAPI = new PlatformAPI();

export {
    UserData,
    Camera,
    Video,
    invalidBearerToken,
    forbiddenAccess,
    CameraList,
    Timeline,
    ChatGroup,
    LprDetection,
    GetLprDetectionParams,
    ChatMessage,
    LprCamera,
    CrmGroupListItem,
    updateCrmGroupParams,
    CrmUserData,
    GetUsersParams,
    WhitelabelUser,
    PageParam,
    CrmGroup,
    ZipCode,
    GetCrmUser,
    IdParam,
    CrmGroupCamera,
    GetUserAsaasPayments,
    GetUserAsaasSubscription,
    Plan,
    ValidP2P,
    ClientError,
    NotFound,
    GetCrmUserGroups,
    WhitelabelGroup,
    RegisterCamera,
    groupPermissions,
    createGroupParams,
    PermissionLevelsPermissions,
    UpdateCrmPermissionLevelsParams,
    ChargesList,
    Predictions,
    UnexpectedError,
    CrmCameras,
    Area,
    BlacklistGroup,
    PrivatePreview,
    KitMarketing,
    BlacklistItem,
    BlacklistParams,
    CameraConfig,
    CameraEditProps,
    DetailedCharge,
    ChargeItems,
    ServicePackagesPredictions,
    PlanPackages,
    RentalData,
    Rentals,
    VehicleInformation,
    DeleteCamera,
    DeleteReason,
    GetCrmCamerasParams,
    FacialFaces,
    Person,
    Categories,
    WhitelabelSignupField,
    CameraTypes
};
