import React, { useEffect, useState } from "react";
import {
    UserData,
    platformAPI,
    invalidBearerToken,
    forbiddenAccess,
    UpdateCrmPermissionLevelsParams,
    PermissionLevelsPermissions,
    CrmGroup,
} from "../../../services/platform-api";
import { translate } from '../../../services/service-translate';
import { List } from "react-native-paper";
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    ScrollView,
    TextInput,
} from 'react-native';
import { Switch } from 'react-native-elements';
import DefaultPageContainer from "../../../components/DefaultPageContainer";

type CrmPermissionLevelsPermissions = "canSeeCameraTab" | "canSeeCameraOptions" | "canEditCameraOptions" |
    "canCreateCamera" | "canCreateDashboard" | "canSeeCameraTotalizer" | "canSeeCameraRTSP" | "canSeeUserTab" |
    "canEditCameraGroup" | "canSeePermissionLevelsTab" | "canEditPermissionLevels" | "mobileAccess" | "canSeePlatformPaymentInfo" |
    "canSeeLprDemo" | "canSeeApiTab" | "canEditApi" | "canSeeInvestigativeMap" | "canEditCameraAds" | "canSeeEmbedValue" | "canEnableCamera" |
    "canSeeUserTotalizer" | "canSeeUserDetails" | "canEditUser" | "canEditUserPayment" | "canDeleteUser" | "canInviteUsers" | "canSeeCameraGroupTab" |
    "canCreateTrialUsers" | "canSeeMarketingKit" | "canSeeLprSituation" | "canSeeLprBlacklist" | "canEditLprBlacklist" | "canSeeLprBlacklistAlert";

interface CrmPermissionLevelsFormProps {
    navigation: Navigation;
    route: {
        params: {
            id: number;
        };
    };
    authenticatedUser?: UserData;
}

interface Permissions {
    permission_key: CrmPermissionLevelsPermissions;
}

interface SwitchItmParams {
    title: string;
    inputKey: CrmPermissionLevelsPermissions;
    callback?: (newValue?: boolean) => void;
}

const headerSize = 60;

export default function CrmPermissionLevelsForm({ navigation, route, authenticatedUser }: CrmPermissionLevelsFormProps) {
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const params = route.params;
    const defaultInput = {
        title: "",
        observation: "",
        userDefault: false,
        permissions: {
            canSeeCameraTab: false,
            canSeeCameraOptions: false,
            canEditCameraOptions: false,
            canCreateCamera: false,
            canCreateDashboard: false,
            canSeeEmbedValue: false,
            canEnableCamera: false,
            canEditCameraAds: false,
            canSeeCameraTotalizer: false,
            canSeeCameraRTSP: false,
            canSeeUserTab: false,
            canSeeUserTotalizer: false,
            canSeeUserDetails: false,
            canEditUser: false,
            canEditUserPayment: false,
            canDeleteUser: false,
            canInviteUsers: false,
            canSeeCameraGroupTab: false,
            canEditCameraGroup: false,
            canSeePermissionLevelsTab: false,
            canEditPermissionLevels: false,
            mobileAccess: false,
            canSeePlatformPaymentInfo: false,
            canCreateTrialUsers: false,
            canSeeMarketingKit: false,
            canSeeLprSituation: false,
            canSeeLprBlacklist: false,
            canEditLprBlacklist: false,
            canSeeLprBlacklistAlert: false,
            canSeeLprDemo: false,
            canSeeApiTab: false,
            canEditApi: false,
            canSeeInvestigativeMap: false,
        },
    };
    const [permissionLevel, setPermissionLevel] = useState<CrmGroup>();
    const [readOnly, setReadOnly] = useState(false);
    const [showPermissions, setShowPermissions] = useState({
        camera: true,
        user: true,
        groupCamera: true,
        permissionLevel: true,
        mobile: true,
        financial: true,
        demonstration: true,
        lpr: true,
        api: true,
        investigativeMap: true,
        marketingKit: true,
    });
    const [valid, setValid] = useState(false);
    const [inputs, setInputs] = useState<UpdateCrmPermissionLevelsParams>(defaultInput);

    useEffect(() => {
        checkPermissions();
    }, [authenticatedUser]);

    useEffect(() => {
        if (params?.id) {
            const unsubscribe = navigation.addListener('focus', () => {
                loadPermissionLevel();
            });
            return unsubscribe;
        }
    }, [navigation, route]);

    const validate = (text: string) => {
        setInputs({ ...inputs, title: text });
        if (text !== "") {
            setValid(true);
        } else {
            setValid(false);
        }
    };

    async function save() {
        try {
            setValid(false);
            if (permissionLevel) {
                await platformAPI.updateCrmPermissionsGroup({ ...inputs });
            } else {
                await platformAPI.addLevelPermissions(inputs);
            }
            goBack();

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("CrmPermissionLevels");
                return;
            }
            console.error(err);
        } finally {
            setValid(true);
        }
    }

    async function loadPermissionLevel() {
        if (!params) return;
        try {
            const groupRequest = await platformAPI.getCrmGroupPermissions(params.id);
            const permissions: PermissionLevelsPermissions = {};

            for (const permission of groupRequest.GroupPermissions) {
                permissions[(permission as Permissions).permission_key] = true;
            }

            setInputs({ ...groupRequest, permissions: permissions });

            setPermissionLevel(groupRequest);

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            if (!authenticatedUser.permissions.can_edit_permission_levels) {
                if (!authenticatedUser.permissions.can_see_permission_levels_tab) {
                    navigation.navigate("CrmPermissionLevels");
                }
                setReadOnly(true);
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    function goBack() {
        setValid(false);
        setPermissionLevel(undefined);
        setInputs(defaultInput);
        navigation.navigate("CrmPermissionLevels");
    }

    function SwitchItem({ title, inputKey, callback }: SwitchItmParams) {
        const inputValue = inputs.permissions[inputKey];
        return (
            <View style={styles.switchItem}>
                <Text style={styles.switchItemText}>{translate(title)}</Text>
                <Switch
                    trackColor={{ false: '#767577', true: '#ADBCF9' }}
                    color={inputValue ? '#5a7bf4' : '#f4f3f4'}
                    onValueChange={() => {
                        inputs.permissions[inputKey] = !inputValue;
                        if (callback) callback(inputs.permissions[inputKey]);
                        forceUpdate();
                    }}
                    disabled={readOnly}
                    value={inputValue}
                />
            </View>
        );
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("permissionLevel")}</Text>
                    </View>
                </View>
                <View style={styles.registerView}>
                    {!readOnly ? valid || permissionLevel ?
                        <TouchableOpacity onPress={() => save()}>
                            <Text style={styles.register}>{translate("save")}</Text>
                        </TouchableOpacity>
                        :
                        <Text style={styles.registerInvalid}>{translate("save")}</Text>
                        :
                        null
                    }
                </View>
            </View>
            <ScrollView style={styles.scrollContainer}>
                <View style={[styles.inputTextView, styles.titleInput]}>
                    <Text style={styles.inputTextTitle}>{translate("textTitle")}</Text>
                    <TextInput
                        style={[(!readOnly ? styles.textInputs : styles.inputsDisabled), styles.textInputsTitle]}
                        value={inputs.title}
                        onChangeText={(text) => validate(text)}
                        editable={!readOnly}
                    />
                </View>
                <View style={styles.switchItem}>
                    <Text style={styles.switchItemText}>{translate('defaultPermissionLevel')}</Text>
                    <Switch
                        trackColor={{ false: '#767577', true: '#ADBCF9' }}
                        color={inputs.userDefault ? '#5a7bf4' : '#f4f3f4'}
                        onValueChange={() => setInputs({ ...inputs, userDefault: !inputs.userDefault })}
                        value={inputs.userDefault}
                        disabled={readOnly}
                    />
                </View>
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions({ ...showPermissions, camera: !showPermissions.camera })}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions.camera ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate('cameraCAPSLOCK')}</Text>
                </TouchableOpacity>
                {showPermissions.camera ?
                    <View style={styles.permissions}>
                        <SwitchItem
                            title="permissionsForm_viewInCrm"
                            inputKey="canSeeCameraTab"
                            callback={(value) => {
                                if (!value) {
                                    inputs.permissions.canSeeCameraOptions = false;
                                    inputs.permissions.canEditCameraOptions = false;
                                    inputs.permissions.canSeeCameraRTSP = false;
                                    inputs.permissions.canSeeCameraTotalizer = false;
                                    inputs.permissions.canSeeEmbedValue = false;
                                    inputs.permissions.canEnableCamera = false;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_viewConfigs"
                            inputKey="canSeeCameraOptions"
                            callback={(value) => {
                                if (!value) {
                                    inputs.permissions.canEditCameraOptions = false;
                                    inputs.permissions.canSeeEmbedValue = false;
                                    inputs.permissions.canEnableCamera = false;
                                } else {
                                    inputs.permissions.canSeeCameraTab = true;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_editConfigs"
                            inputKey="canEditCameraOptions"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeCameraTab = true;
                                    inputs.permissions.canSeeCameraOptions = true;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_configureAdvertisement"
                            inputKey="canEditCameraAds"
                        />
                        <SwitchItem
                            title="permissionsForm_addCamera"
                            inputKey="canCreateCamera"
                        />
                        <SwitchItem
                            title="permissionsForm_viewTotalizer"
                            inputKey="canSeeCameraTotalizer"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeCameraTab = true;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_viewRTSP"
                            inputKey="canSeeCameraRTSP"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeCameraTab = true;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_viewEmbeddedBody"
                            inputKey="canSeeEmbedValue"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeCameraTab = true;
                                    inputs.permissions.canSeeCameraOptions = true;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_addMosaics"
                            inputKey="canCreateDashboard"
                        />
                        <SwitchItem
                            title="permissionsForm_reconnectCamera"
                            inputKey="canEnableCamera"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeCameraTab = true;
                                    inputs.permissions.canSeeCameraOptions = true;
                                }
                            }} />

                    </View>
                    : null}
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions({ ...showPermissions, user: !showPermissions.user })}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions.user ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate("userCAPSLOCK")}</Text>
                </TouchableOpacity>
                {showPermissions.user ?
                    <View style={styles.permissions}>
                        <SwitchItem
                            title="permissionsForm_viewInCrm"
                            inputKey="canSeeUserTab"
                            callback={(value) => {
                                if (!value) {
                                    inputs.permissions.canSeeUserTotalizer = false;
                                    inputs.permissions.canSeeUserDetails = false;
                                    inputs.permissions.canEditUser = false;
                                    inputs.permissions.canDeleteUser = false;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_viewTotalizer"
                            inputKey="canSeeUserTotalizer"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeUserTab = true;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_viewOptions"
                            inputKey="canSeeUserDetails"
                            callback={(value) => {
                                if (!value) {
                                    inputs.permissions.canEditUser = false;
                                    inputs.permissions.canDeleteUser = false;
                                } else {
                                    inputs.permissions.canSeeUserTab = true;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_createAndEditOptions"
                            inputKey="canEditUser"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeUserTab = true;
                                    inputs.permissions.canSeeUserDetails = true;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_editPayments"
                            inputKey="canEditUserPayment"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeUserTab = true;
                                    inputs.permissions.canSeeUserDetails = true;
                                    inputs.permissions.canEditUser = true;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_delete"
                            inputKey="canDeleteUser"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeUserTab = true;
                                    inputs.permissions.canSeeUserDetails = true;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_invite"
                            inputKey="canInviteUsers"
                        />
                    </View>
                    : null}
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions({ ...showPermissions, groupCamera: !showPermissions.groupCamera })}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions.groupCamera ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate("cameraGroupCAPSLOCK")}</Text>
                </TouchableOpacity>
                {showPermissions.groupCamera ?
                    <View style={styles.permissions}>
                        <SwitchItem
                            title="permissionsForm_viewInCrm"
                            inputKey="canSeeCameraGroupTab" />
                        <SwitchItem
                            title="permissionsForm_createAndEditOptions"
                            inputKey="canEditCameraGroup"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeCameraGroupTab = true;
                                }
                            }} />
                    </View>
                    : null}
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions({ ...showPermissions, permissionLevel: !showPermissions.permissionLevel })}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions.permissionLevel ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate("permissionLevelsCAPSLOCK")}</Text>
                </TouchableOpacity>
                {showPermissions.permissionLevel ?
                    <View style={styles.permissions}>
                        <SwitchItem
                            title="permissionsForm_viewInCrm"
                            inputKey="canSeePermissionLevelsTab" />
                        <SwitchItem
                            title="permissionsForm_createAndEditOptions"
                            inputKey="canEditPermissionLevels"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeePermissionLevelsTab = true;
                                }
                            }} />
                    </View>
                    : null}
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions({ ...showPermissions, mobile: !showPermissions.mobile })}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions.mobile ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate("mobileCAPSLOCK")}</Text>
                </TouchableOpacity>
                {showPermissions.mobile ?
                    <View style={styles.permissions}>
                        <SwitchItem
                            title="permissionsForm_mobileAccess"
                            inputKey="mobileAccess" />
                    </View>
                    : null}
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions({ ...showPermissions, financial: !showPermissions.financial })}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions.financial ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate("financialCAPSLOCK")}</Text>
                </TouchableOpacity>
                {showPermissions.financial ?
                    <View style={styles.permissions}>
                        <SwitchItem
                            title="permissionsForm_viewPaymentInformation"
                            inputKey="canSeePlatformPaymentInfo" />
                    </View>
                    : null}
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions({ ...showPermissions, demonstration: !showPermissions.demonstration })}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions.demonstration ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate("demonstrationCAPSLOCK")}</Text>
                </TouchableOpacity>
                {showPermissions.demonstration ?
                    <View style={styles.permissions}>
                        <SwitchItem
                            title="permissionsForm_createDemoUser"
                            inputKey="canCreateTrialUsers" />
                    </View>
                    : null}
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions({ ...showPermissions, lpr: !showPermissions.lpr })}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions.lpr ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate("licensePlateReadingCAPSLOCK")}</Text>
                </TouchableOpacity>
                {showPermissions.lpr ?
                    <View style={styles.permissions}>
                        <SwitchItem
                            title="permissionsForm_viewSituation"
                            inputKey="canSeeLprSituation" />
                        <SwitchItem
                            title="permissionsForm_viewAllBlacklists"
                            inputKey="canSeeLprBlacklist"
                            callback={(value) => {
                                if (!value) {
                                    inputs.permissions.canEditLprBlacklist = false;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_editAllBlacklists"
                            inputKey="canEditLprBlacklist"
                            callback={(value) => {
                                if (value) {
                                    inputs.permissions.canSeeLprBlacklist = true;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_viewBlacklistAlert"
                            inputKey="canSeeLprBlacklistAlert" />
                        <SwitchItem
                            title="permissionsForm_viewDemo"
                            inputKey="canSeeLprDemo" />
                    </View>
                    : null}
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions({ ...showPermissions, api: !showPermissions.api })}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions.api ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>API</Text>
                </TouchableOpacity>
                {showPermissions.api ?
                    <View style={styles.permissions}>
                        <SwitchItem
                            title="permissionsForm_viewInCrm"
                            inputKey="canSeeApiTab"
                            callback={(value) => {
                                if (!value) {
                                    inputs.permissions.canEditApi = false;
                                }
                            }} />
                        <SwitchItem
                            title="permissionsForm_editOptions"
                            inputKey="canEditApi" />
                    </View>
                    : null}
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions({ ...showPermissions, investigativeMap: !showPermissions.investigativeMap })}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions.investigativeMap ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate("investigativeMapCAPSLOCK")}</Text>
                </TouchableOpacity>
                {showPermissions.investigativeMap ?
                    <View style={styles.permissions}>
                        <SwitchItem title="permissionsForm_unlockInvestigativeMapOptions" inputKey="canSeeInvestigativeMap" />
                    </View>
                    : null}
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowPermissions({ ...showPermissions, marketingKit: !showPermissions.marketingKit })}>
                    <View style={styles.viewIcon}>
                        {
                            showPermissions.marketingKit ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate("marketingKitCAPSLOCK")}</Text>
                </TouchableOpacity>
                {showPermissions.marketingKit ?
                    <View style={styles.permissions}>
                        <SwitchItem title="permissionsForm_viewInCrm" inputKey="canSeeMarketingKit" />
                    </View>
                    : null}
                <View style={styles.inputTextView}>
                    <Text style={styles.inputTextTitle}>{translate("observations")}</Text>
                    <TextInput
                        style={[(!readOnly ? styles.textInputs : styles.inputsDisabled), styles.observations]}
                        value={inputs.observation}
                        onChangeText={(text) => {
                            setInputs({ ...inputs, observation: text });
                        }}
                        multiline={true}
                        editable={!readOnly}
                    />
                </View>
            </ScrollView>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    titleInput: {
        marginBottom: 10,
    },
    inputTextTitle: {
        marginLeft: 10,
        marginBottom: 3,
    },
    inputTextView: {
        marginTop: 15,
        width: '100%',
    },
    permissions: {
        width: "100%",
    },
    observations: {
        textAlignVertical: "top",
        height: 120,
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        fontSize: 16,
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginBottom: 10,
    },
    switchItemText: {
        fontSize: 14,
        paddingEnd: 20,
    },
    switchItem: {
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingBottom: "15px",
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    textInputsTitle: {
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        height: 45,
        fontSize: 16,
        paddingHorizontal: 15,
        marginBottom: 10,
    },
    textInputs: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#fff',
        color: '#000',
    },
    inputsDisabled: {
        borderColor: "#9a9a9a4f",
        backgroundColor: '#F2F3F5',
        color: '#9a9a9a',
        cursor: 'default'
    },
    viewIcon: {
        margin: 0,
        width: 30,
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
    },
    titleDropDown: {
        fontSize: 16,
    },
    dropDown: {
        width: "100%",
        height: "30px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    enabledIcon: {
        width: 20,
        height: 20,
    },
    registerView: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    registerInvalid: {
        alignSelf: "flex-end",
        color: "rgba(255, 255, 255, 0.5)",
        fontSize: 20,
        marginRight: 15,
        cursor: "default",
        userSelect: "none",
    },
    register: {
        alignSelf: "flex-end",
        color: "#fff",
        fontSize: 20,
        marginRight: 15,
    },
    scrollContainer: {
        backgroundColor: '#fff',
        height: 'calc(100vh - 60px)',

    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    textDefaultGroup: {
        marginTop: "5px",
        color: "#0071bc",
        fontSize: 12,
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    headerContent: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeader: {
        fontSize: 26,
        color: '#fff',
        marginRight: 15,
        alignSelf: 'center',
    },
});

