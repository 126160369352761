import {
    View,
    Text,
    StyleSheet,
    KeyboardAvoidingView,
    TextInput,
    TouchableOpacity,
    Image,
    SafeAreaView,
    NativeSyntheticEvent,
    TextInputKeyPressEventData
} from 'react-native';
import React, { useEffect, useState, useRef } from 'react';
import { Icon } from 'react-native-elements';
import { Countdown } from 'react-native-element-timer';
import { platformAPI } from '../../services/platform-api';
import { useNavigationState, useLinkTo, useIsFocused } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// translations
import { translate } from '../../services/service-translate';

interface InputOTPComponentProps {
    route: {
        params: string;
    };
}

export function InputOTP({ route }: InputOTPComponentProps) {
    let textInput = useRef(null);
    const lenghtInput = 6;
    const [token, setToken] = useState("");
    const [invalidToken, setInvalidToken] = useState(false);
    const [totalLength, setTotalLength] = useState(false);
    const countdownRef = useRef(null);
    const [endTimer, setEndTimer] = useState(false);
    const index = useNavigationState(state => state);
    const linkTo = useLinkTo();

    const phoneNumber = route.params;

    if (useIsFocused()) {
        checkAuth();
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter" && totalLength == true) {
            onPressContinue();
        }
    }

    async function checkAuth() {
        const token = await AsyncStorage.getItem('BEARER_TOKEN');

        if (token) {
            linkTo('/cameras');
        }
    }

    async function otpRequest() {
        try {
            if ("OTPCredential" in window) {

                const ac = new AbortController();
                setTimeout(() => {
                    ac.abort();
                }, 60 * 1000);

                const otp = await navigator.credentials.get({
                    otp: { transport: ["sms"] },
                    signal: ac.signal
                });
                if (otp) {
                    await onChangeText(otp.code);
                    onPressContinue();
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        document.body.style.position = 'fixed';

        checkAuth();

        otpRequest();
    }, []);

    async function onPressResend() {
        await platformAPI.signupPhone(phoneNumber);
    }

    async function onPressContinue() {
        setInvalidToken(false);
        try {
            await platformAPI.confirmPhone(phoneNumber, token);
            if (index.routes[0].name == 'Login'
                || index.routes[0].name == 'Authentication'
                || index.routes[0].name == 'InputOTP') {
                linkTo('/cameras');
                return;
            }
            if (index.routes[0].path != undefined) {
                linkTo(index.routes[0].path);
                return;
            }
        } catch (err) {
            setInvalidToken(true);
        }
    }

    const onChangeText = (val: string) => {
        setToken(val);
        /* eslint-disable */
        return new Promise((resolve) => {
            setTotalLength(val.length == 6, () => {
                resolve();
            });
            /* eslint-enable */
        });
    };

    useEffect(() => {
        textInput.focus();
    }, []);

    useEffect(() => {
        countdownRef.current.start();
    }, []);

    const pressResend = () => {
        onPressResend();
        setEndTimer(false);
        countdownRef.current.start();

    };

    return (
        <SafeAreaView style={styles.container}>
            <KeyboardAvoidingView
                keyboardVerticalOffset={50}
                behavior={'padding'}
                style={styles.containerAvoidView}
            >
                <Text style={styles.textTitle}>{translate('insertNumber')}</Text>
                <Text style={styles.codeText}>{translate('codeTitle')}</Text>
                <View style={styles.inputView}>
                    <TextInput
                        style={styles.phoneInputStyle}
                        ref={(input) => textInput = input}
                        onChangeText={onChangeText}
                        value={token}
                        maxLength={lenghtInput}
                        returnKeyType="done"
                        keyboardType="numeric"
                        textAlign='center'
                        onKeyPress={handleKeyDown}
                    />
                    {totalLength ?
                        <Icon
                            color='#55A1FC'
                            name='check'
                            type='Entypo'
                            size={20}

                        />
                        : <Icon
                            color='#FF2D00'
                            name='close'
                            type='MaterialIcons'
                            size={20}
                        />}
                </View>
                <View>
                    <TouchableOpacity
                        onPress={onPressContinue}
                        disabled={totalLength ? false : true}
                    >
                        <View style={[
                            styles.btnContinue,
                            {
                                backgroundColor: totalLength ? '#0071bc' : '#7eb8dd'
                            }
                        ]}>
                            <Text style={styles.textContinue}>{translate('continue')}</Text>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.timerView}>
                    <Countdown
                        ref={countdownRef}
                        style={styles.timer}
                        initialSeconds={59}
                        onEnd={() => {
                            setEndTimer(true);
                        }}
                    />
                </View>
                <View style={styles.sideText}>
                    <TouchableOpacity
                        onPress={pressResend}
                        disabled={endTimer ? false : true}
                    >
                        <View style={[
                            styles.viewResend,
                            {
                                borderColor: endTimer ? '#0071bc' : '#7eb8dd'
                            }

                        ]}>
                            <Text style={[
                                styles.textResend,
                                {
                                    color: endTimer ? '#0071bc' : '#7eb8dd'
                                }
                            ]}>{translate('resendCode')}</Text>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.invalidView}>
                    {invalidToken ? <Text style={styles.invalidMessage}>{translate('invalidCode')}</Text> : null}
                </View>
                <View style={styles.viewLogo}>
                    <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fcamerite-logo-azul.png' }} style={styles.logo} />
                </View>
            </KeyboardAvoidingView>

        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    containerAvoidView: {
        flex: 1,
        alignItems: 'center',
        padding: 10,
        backgroundColor: 'white'
    },
    container: {
        flex: 1
    },
    textTitle: {
        marginBottom: 20,
        marginTop: 50,
        fontSize: 28,
        color: '#0071bc',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    codeText: {
        marginTop: 10
    },
    inputView: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
        flexDirection: 'row',
        borderBottomWidth: 2,
    },
    phoneInputStyle: {
        height: 40,
        backgroundColor: 'white',
        color: 'black',
        width: 130,
        textAlign: 'center',
        fontSize: 20
    },
    btnContinue: {
        width: 245,
        height: 43,
        borderRadius: 26,
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        marginTop: 30
    },
    textContinue: {
        color: '#ffffff',
        alignItems: 'center',
        fontWeight: "bold"
    },
    timerView: {
        flexDirection: 'row',
        marginTop: 7
    },
    timer: {
        padding: 1,
        fontSize: 16,
    },
    textResend: {
        color: '#0071bc',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5
    },
    viewResend: {
        width: 245,
        height: 43,
        borderRadius: 26,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: '#0071bc',
        borderWidth: 2,
        shadowColor: 'black',
        shadowRadius: 1.5,
        marginTop: 7
    },
    sideText: {
        flexDirection: 'row',
        padding: 3,
        alignItems: 'flex-start',
        justifyContent: 'center',

    },
    invalidView: {
        flex: 1,
        marginTop: 10
    },
    invalidMessage: {
        textAlign: 'center',
        marginBottom: 20,
        color: 'red',
    },
    viewLogo: {
        justifyContent: 'flex-end',

    },
    logo: {
        padding: 20,
        width: 250,
        height: 48,
        marginBottom: 10
    },

});
