import React from "react";
import { BaseToast, ErrorToast, BaseToastProps } from 'react-native-toast-message';


export const toastConfig = {
    success: (props: BaseToastProps) => (
        <BaseToast
            {...props}
            style={{ borderLeftColor: "green" }}
            text1NumberOfLines={2}
            text1Style={{ fontSize: 14 }}
        />
    ),
    error: (props: BaseToastProps) => (
        <ErrorToast
            {...props}
            style={{ borderLeftColor: "red" }}
            text1NumberOfLines={2}
            text1Style={{ fontSize: 14 }}
        />
    ),
    longError: (props: BaseToastProps) => (
        <ErrorToast
            {...props}
            style={{ borderLeftColor: "red", height: "80px" }}
            text1NumberOfLines={3}
            text1Style={{ fontSize: 14 }}
        />
    )
};
