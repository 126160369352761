import * as React from 'react';
import videojs from 'video.js';
import * as ScreenOrientation from 'expo-screen-orientation';
import srsPlayer from "../services/srsPlayer";
import Hammer from 'hammerjs';

// Styles
import '../styles/video-js.css';

export default class VideoPlayer extends React.Component {
    public player?: videojs.Player;
    private videoRef: React.RefObject<HTMLVideoElement>;
    public canvasRef?: React.RefObject<HTMLCanvasElement>;
    public divRef?: React.RefObject<HTMLDivElement>;
    public downloadRef?: React.RefObject<HTMLAnchorElement>;
    private myProps: videojs.PlayerOptions;
    public srsWebRtc: srsPlayer;
    public doubleClickHandler: (ev: HammerInput) => void;
    private containerWidth: number;
    private containerHeight: number;
    private minScale: number;
    private maxScale: number;
    private displayImageX: number;
    private displayImageY: number;
    private displayImageScale: number;

    private displayDefaultWidth: number;
    private displayDefaultHeight: number;

    private rangeX: number;
    private rangeMaxX: number;
    private rangeMinX: number;

    private rangeY: number;
    private rangeMaxY: number;
    private rangeMinY: number;

    private displayImageCurrentX: number;
    private displayImageCurrentY: number;
    private displayImageCurrentScale: number;

    private objectFit: 'fill' | 'contain';

    constructor(props: videojs.PlayerOptions) {
        super(props);
        this.player = undefined;
        this.videoRef = React.createRef<HTMLVideoElement>();
        this.canvasRef = React.createRef();
        this.divRef = React.createRef<HTMLDivElement>();
        this.downloadRef = React.createRef();
        this.myProps = props;
        this.srsWebRtc = new srsPlayer();
        this.doubleClickHandler = () => {
            return;
        };
        this.containerWidth = 0;
        this.containerHeight = 0;
        this.minScale = 1;
        this.maxScale = 4;

        this.displayImageX = 0;
        this.displayImageY = 0;

        this.displayImageScale = 1;

        this.displayDefaultWidth = 0;
        this.displayDefaultHeight = 0;

        this.rangeX = 0;
        this.rangeMaxX = 0;
        this.rangeMinX = 0;

        this.rangeY = 0;
        this.rangeMaxY = 0;
        this.rangeMinY = 0;

        this.displayImageCurrentX = 0;
        this.displayImageCurrentY = 0;
        this.displayImageCurrentScale = 1;

        this.objectFit = 'fill';

        const ua = window.navigator.userAgent;

        const isAppleDevice = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Macintosh/i);
        const isAppleWebKit = !!ua.match(/AppleWebKit/i);
        const isSafari = !!ua.match(/Safari/i);

        if (isAppleDevice && isAppleWebKit && isSafari) {
            // safari can't handle objectFit fill
            this.objectFit = 'contain';
        }

    }

    setWidth(width: number) {
        this.player?.width(width);
    }

    showPlaybackRateMenuButton() {
        this.player?.ready(() => {
            const component = this.player?.controlBar.getChild("playbackRateMenuButton");
            if (component) {
                component.el().style.opacity = "1";
            }
        });

    }

    resetPlaybackRate() {
        /**
         * this functions looks weird and useless, i know...
         * but actually videojs has an bug. When I change the video src
         * it keeps with the playbackRate correctly value, but the video
         * doesn't really run on the playbackRate value (it resets to 1.0).
         * So here I manually reset the playbackRate value with 1.0 then
         * to the old value and then it works 🤷‍♂️
         */
        this.player?.ready(() => {
            const playbackRate = this.player?.playbackRate();
            this.player?.playbackRate(1.0);
            if (playbackRate) {
                this.player?.playbackRate(playbackRate);
            }
        });
    }

    onVideoEnded(callback: () => void) {
        this.player?.ready(() => {
            this.player?.on("ended", callback);
        });
    }

    hidePlaybackRateMenuButton() {
        this.player?.ready(() => {
            const component = this.player?.controlBar.getChild("playbackRateMenuButton");
            if (component) {
                component.el().style.opacity = "0";
            }
        });

    }

    hideProgressBar() {
        this.player?.ready(() => {
            let component;
            component = this.player?.controlBar.getChild("liveDisplay");
            if (component) {
                component.el().style.opacity = "0";
            }
            component = this.player?.controlBar.getChild("progressControl");
            if (component) {
                component.el().style.opacity = "0";
            }
            component = this.player?.controlBar.getChild("remainingTimeDisplay");
            if (component) {
                component.el().style.opacity = "0";
            }
        });
    }

    setDoubleClickHandler(handler: (ev: HammerInput) => void) {
        this.doubleClickHandler = handler;
    }

    setHeight(height: number) {
        this.player?.height(height);
    }

    addBackButton(clickHandler: () => void, paddingTop: number) {
        const el = this.player?.controlBar.addChild('button', {
            text: 'back',
            clickHandler: clickHandler
        });
        if (!el) {
            return;
        }
        el.addClass('vjs-return-button');
        el.setAttribute('style', `top: ${paddingTop.toString()}px`);
    }

    addConfigButton(clickHandler: () => void, paddingTop: number) {
        const el = this.player?.controlBar.addChild('button', {
            text: 'config',
            clickHandler: clickHandler
        });
        if (!el) {
            return;
        }
        el.addClass('vjs-config-button');
        el.setAttribute('style', `top: ${paddingTop.toString()}px`);
    }


    resizeContainer() {
        const video = this.videoRef?.current;
        if (!video) {
            return;
        }
        this.containerWidth = video.offsetWidth;
        this.containerHeight = video.offsetHeight;
        if (this.displayDefaultWidth !== undefined && this.displayDefaultHeight !== undefined) {
            this.displayDefaultWidth = video.offsetWidth;
            this.displayDefaultHeight = video.offsetHeight;
            this.updateRange();
            this.displayImageCurrentX = this.clamp(this.displayImageX, this.rangeMinX, this.rangeMaxX);
            this.displayImageCurrentY = this.clamp(this.displayImageY, this.rangeMinY, this.rangeMaxY);
            this.updateDisplayImage(
                this.displayImageCurrentX,
                this.displayImageCurrentY,
                this.displayImageCurrentScale, false
            );
        }
    }

    clamp(value: number, min: number, max: number) {
        return Math.min(Math.max(min, value), max);
    }

    clampScale(newScale: number) {
        return this.clamp(newScale, this.minScale, this.maxScale);
    }

    updateDisplayImage(x: number, y: number, scale: number, zoom: boolean) {
        this.player?.ready(() => {
            if (!this.player) {
                return;
            }

            const el = this.player.tech(true).el();

            const videoWidth = el.offsetWidth;
            const videoHeight = el.offsetHeight;

            const centerX = videoWidth / 2;
            const centerY = videoHeight / 2;

            if (!zoom) {
                el.style.transformOrigin = `${centerX}px ${centerY}px`;
                const transform = 'translateX(' + x + 'px) translateY(' + y + 'px) translateZ(0px) scale(' + scale + ',' + scale + ')';
                el.style.transform = transform;
                el.style.webkitTransform = transform;
                return;
            }

            el.style.transition = 'transform-origin 0.1s';
            el.style.transform = 'scale(' + scale + ',' + scale + ') translateZ(0px)';
            el.style.transformOrigin = `${x}px ${y}px`;
        });
    }

    updateRange() {
        this.rangeX = Math.max(0, Math.round(this.displayDefaultWidth * this.displayImageCurrentScale) - this.containerWidth);
        this.rangeY = Math.max(0, Math.round(this.displayDefaultHeight * this.displayImageCurrentScale) - this.containerHeight);

        this.rangeMaxX = Math.round(this.rangeX / 2);
        this.rangeMinX = 0 - this.rangeMaxX;

        this.rangeMaxY = Math.round(this.rangeY / 2);
        this.rangeMinY = 0 - this.rangeMaxY;
    }

    setHls(source: string) {
        this.player?.ready(async function () {

            this.src({
                src: source,
                type: 'application/x-mpegURL'
            });
            this.muted(false);

            const el = this.tech(true).el();
            el.play();
            this.play = async () => {
                el.play();
            };
        });
    }

    setSrc(source: string) {
        this.player?.ready(async function () {

            const el = this.tech(true).el();
            el.pause();
            el.srcObject = null;
            el.src = source;
            el.play();

            this.play = async () => {
                el.play();
            };
        });
    }

    async getTime(): Promise<number> {
        return new Promise((resolve, reject) => {
            this.player?.ready(async function () {
                try {
                    const el = this.tech(true).el();
                    resolve(el.currentTime);
                } catch (err) {
                    reject(err);
                }
            });
        });
    }

    setTimeAt(timeInSeconds: number) {
        this.player?.ready(async function () {

            const el = this.tech(true).el();
            el.currentTime = timeInSeconds;
        });
    }

    cleanSrc() {
        this.player?.ready(async function () {
            const el = this.tech(true).el();
            el.src = "";
        });
    }

    setWebRTC(address: string) {
        this.player?.ready(async () => {
            try {
                if (this.player) {
                    const el = this.player.tech(true).el();
                    el.pause();
                    el.src = "";

                    await this.srsWebRtc.play(address);
                    await this.srsWebRtc.setStreamOnPlayer(this.player);

                    el.play();

                    this.player.play = async () => {
                        el.play();
                    };
                }


            } catch (err) {
                console.log('an error occoured when loading webRTC', err);
            }
        });
    }

    takePicture(pictureName?: string | undefined) {
        const canvas = this.canvasRef?.current;
        const download = this.downloadRef?.current;

        if (!pictureName) {
            pictureName = 'screenshot';
        }

        if (!canvas) {
            return;
        }
        this.player?.ready(async function () {
            const video = this.tech(true).el();
            if (!video || !(video instanceof HTMLVideoElement)) {
                return;
            }

            canvas.width = video.offsetWidth;
            canvas.height = video.offsetHeight;

            const ctx = canvas.getContext('2d');
            ctx?.drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight);

            download?.addEventListener('click', function () {
                const url = canvas.toDataURL('image/jpeg');
                this.download = `${pictureName}.jpg`;
                this.href = url;
            });
            download?.click();
        });
    }

    hidePlayer() {
        const div = this.divRef?.current;
        if (!div) {
            return;
        }

        div.style.opacity = "0";
    }

    showPlayer() {
        const div = this.divRef?.current;
        if (!div) {
            return;
        }

        div.style.opacity = "100%";
    }

    componentDidMount() {
        const video = this.videoRef?.current;
        if (!video) {
            return;
        }

        this.player = videojs(video, this.myProps);
        video.style.objectFit = this.objectFit;
        this.player.on('fullscreenchange', () => {
            if (this.player?.isFullscreen()) {
                ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
            } else {
                ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
            }
        });
        this.resizeContainer();
        this.displayDefaultWidth = video.offsetWidth;
        this.displayDefaultHeight = video.offsetHeight;
        this.rangeX = Math.max(0, this.displayDefaultWidth - this.containerWidth);
        this.rangeY = Math.max(0, this.displayDefaultHeight - this.containerHeight);

        video.addEventListener('wheel', (e: WheelEvent) => {
            this.displayImageScale = this.displayImageCurrentScale = this.clampScale(this.displayImageScale + (e.wheelDelta / 800));
            this.updateRange();
            this.displayImageCurrentX = this.clamp(this.displayImageCurrentX, this.rangeMinX, this.rangeMaxX);
            this.displayImageCurrentY = this.clamp(this.displayImageCurrentY, this.rangeMinY, this.rangeMaxY);
            this.updateDisplayImage(e.clientX, e.clientY, this.displayImageScale, true);
        }, false);

        window.addEventListener('resize', this.resizeContainer, true);
        this.player?.ready(async () => {
            const hammer = new Hammer(this.player?.tech(true).el());
            hammer.get('pinch').set({ enable: true });
            hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

            hammer.on("pan", (e) => {
                if (e.maxPointers == 2) {
                    this.displayImageCurrentX = this.displayImageX;
                    this.displayImageCurrentY = this.displayImageY;
                }
                if (e.maxPointers == 1) {
                    this.displayImageCurrentX = this.clamp(this.displayImageX + e.deltaX, this.rangeMinX, this.rangeMaxX);
                    this.displayImageCurrentY = this.clamp(this.displayImageY + e.deltaY, this.rangeMinY, this.rangeMaxY);
                }
                this.updateDisplayImage(this.displayImageCurrentX, this.displayImageCurrentY, this.displayImageScale, false);
            });

            hammer.on('pinch pinchmove', ev => {
                this.displayImageCurrentScale = this.clampScale(ev.scale * this.displayImageScale);
                this.updateRange();

                const centerX = ev.center.x - (this.displayDefaultWidth / 2);
                const centerY = ev.center.y - (this.displayDefaultHeight / 2);

                this.displayImageCurrentX = this.clamp(this.displayImageX + (-centerX * this.displayImageCurrentScale), this.rangeMinX, this.rangeMaxX);
                this.displayImageCurrentY = this.clamp(this.displayImageY + (-centerY * this.displayImageCurrentScale), this.rangeMinY, this.rangeMaxY);

                return this.updateDisplayImage(this.displayImageCurrentX, this.displayImageCurrentY, this.displayImageCurrentScale, false);
            });

            hammer.on('panend pancancel pinchend pinchcancel', () => {
                this.displayImageScale = this.displayImageCurrentScale;
                this.displayImageX = this.displayImageCurrentX;
                this.displayImageY = this.displayImageCurrentY;
            });

            hammer.on('doubletap', (ev) => {
                if (/iPhone|iPad|iPod|Android/i.test(navigator.platform)) {
                    this.doubleClickHandler(ev);
                }
            });
        });
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        return (
            <div className="c-player" ref={this.divRef}>
                <div className="c-player__screen" data-vjs-player="true">;
                    <canvas style={{ display: 'none' }} ref={this.canvasRef} />
                    <a style={{ display: 'none' }} ref={this.downloadRef} />
                    <video disableRemotePlayback playsInline crossOrigin='anonymous' ref={this.videoRef} className="video-js" />
                </div>
            </div >
        );
    }
}
