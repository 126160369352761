// This class was adapted from function https://github.com/ossrs/srs/blob/4.0release/trunk/research/players/js/srs.sdk.js

import videojs from 'video.js';

interface retObject {
    url: string,
    schema: string,
    server: string,
    port: number,
    vhost: string,
    app: string,
    stream: string,
    user_query: { [key: string]: string; } | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    domain: any | undefined;
}

interface srsPlayer {
    __internal: {
        prepareUrl: (webrtcUrl: string) => { apiUrl: string, streamUrl: string, schema: string, urlObject: retObject, port: number, tid: string; };
        parse: (url: string) => retObject;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fill_query: (query_string: string, obj: retObject | any) => void;
        defaultPath: string;
    };
    pc: RTCPeerConnection | null;
    stream: MediaStream;
    playing: boolean;
    refreshInterval: NodeJS.Timer;
    receivedFirstPacket: boolean;
    muted_in_row: number;
    player: videojs.Player;
    url: string;
}

// Depends on adapter-7.4.0.min.js from https://github.com/webrtc/adapter
// Async-await-promise based SRS RTC Player.
class srsPlayer {

    // @see https://github.com/rtcdn/rtcdn-draft
    // @url The WebRTC url to play with, for example:
    //      webrtc://r.ossrs.net/live/livestream
    // or specifies the API port:
    //      webrtc://r.ossrs.net:11985/live/livestream
    //      webrtc://r.ossrs.net:80/live/livestream
    // or autostart the play:
    //      webrtc://r.ossrs.net/live/livestream?autostart=true
    // or change the app from live to myapp:
    //      webrtc://r.ossrs.net:11985/myapp/livestream
    // or change the stream from livestream to mystream:
    //      webrtc://r.ossrs.net:11985/live/mystream
    // or set the api server to myapi.domain.com:
    //      webrtc://myapi.domain.com/live/livestream
    // or set the candidate(eip) of answer:
    //      webrtc://r.ossrs.net/live/livestream?candidate=39.107.238.185
    // or force to access https API:
    //      webrtc://r.ossrs.net/live/livestream?schema=https
    // or use plaintext, without SRTP:
    //      webrtc://r.ossrs.net/live/livestream?encrypt=false
    // or any other information, will pass-by in the query:
    //      webrtc://r.ossrs.net/live/livestream?vhost=xxx
    //      webrtc://r.ossrs.net/live/livestream?token=xxx

    constructor() {
        this.__internal = {
            defaultPath: '/rtc/v1/play/',
            prepareUrl: (webrtcUrl) => {
                const urlObject = this.__internal.parse(webrtcUrl);

                // If user specifies the schema, use it as API schema.
                let schema = "";

                if (urlObject.user_query) {
                    schema = urlObject.user_query.schema;
                }

                schema = schema ? schema + ':' : window.location.protocol;

                let port = urlObject.port || 1985;
                if (schema === 'https:') {
                    port = urlObject.port || 443;
                }

                // @see https://github.com/rtcdn/rtcdn-draft
                let api = "";
                if (urlObject.user_query && urlObject.user_query.play) {
                    api = urlObject.user_query.play;
                } else {
                    api = this.__internal.defaultPath;
                }

                if (api.lastIndexOf('/') !== api.length - 1) {
                    api += '/';
                }

                let apiUrl = schema + '//' + urlObject.server + ':' + port + api;
                for (const key in urlObject.user_query) {
                    if (key !== 'api' && key !== 'play') {
                        apiUrl += '&' + key + '=' + urlObject.user_query[key];
                    }
                }
                // Replace /rtc/v1/play/&k=v to /rtc/v1/play/?k=v
                apiUrl = apiUrl.replace(api + '&', api + '?');

                const streamUrl = urlObject.url;

                return {
                    apiUrl: apiUrl,
                    streamUrl: streamUrl,
                    schema: schema,
                    urlObject: urlObject,
                    port: port,
                    tid: Number(Math.round(new Date().getTime() * Math.random() * 100)).toString(16).substr(0, 7)
                };
            },
            parse: (url) => {
                // @see: http://stackoverflow.com/questions/10469575/how-to-use-location-object-to-parse-url-without-redirecting-the-page-in-javascri
                const a = document.createElement("a");
                a.href = url.replace("rtmp://", "http://")
                    .replace("webrtc://", "http://")
                    .replace("rtc://", "http://");

                let vhost = a.hostname;
                let app = a.pathname.substr(1, a.pathname.lastIndexOf("/") - 1);
                const stream = a.pathname.substr(a.pathname.lastIndexOf("/") + 1);

                // parse the vhost in the params of app, that srs supports.
                app = app.replace("...vhost...", "?vhost=");
                if (app.indexOf("?") >= 0) {
                    const params = app.substr(app.indexOf("?"));
                    app = app.substr(0, app.indexOf("?"));

                    if (params.indexOf("vhost=") > 0) {
                        vhost = params.substr(params.indexOf("vhost=") + "vhost=".length);
                        if (vhost.indexOf("&") > 0) {
                            vhost = vhost.substr(0, vhost.indexOf("&"));
                        }
                    }
                }

                // when vhost equals to server, and server is ip,
                // the vhost is __defaultVhost__
                if (a.hostname === vhost) {
                    const re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
                    if (re.test(a.hostname)) {
                        vhost = "__defaultVhost__";
                    }
                }

                // parse the schema
                let schema = "rtmp";
                if (url.indexOf("://") > 0) {
                    schema = url.substr(0, url.indexOf("://"));
                }

                let port = 0;

                if (a.port) {
                    port = parseInt(a.port);
                }

                // Finger out by webrtc url, if contains http or https port, to overwrite default 1985.
                if (schema === 'webrtc' && url.indexOf('webrtc://' + a.host + ':') === 0) {
                    port = (url.indexOf('webrtc://' + a.host + ':80') === 0) ? 80 : 443;
                }

                // Guess by schema.
                if (!port) {
                    if (schema === 'http') {
                        port = 80;
                    } else if (schema === 'https') {
                        port = 443;
                    } else if (schema === 'rtmp') {
                        port = 1935;
                    }
                }

                const ret = <retObject>{
                    url: url,
                    schema: schema,
                    server: a.hostname,
                    port: port,
                    vhost: vhost,
                    app: app,
                    stream: stream
                };
                this.__internal.fill_query(a.search, ret);

                // For webrtc API, we use 443 if page is https, or schema specified it.
                if (!ret.port) {
                    if (schema === 'webrtc' || schema === 'rtc') {
                        if (ret.user_query && ret.user_query.schema === 'https') {
                            ret.port = 443;
                        } else if (window.location.href.indexOf('https://') === 0) {
                            ret.port = 443;
                        } else {
                            // For WebRTC, SRS use 1985 as default API port.
                            ret.port = 1985;
                        }
                    }
                }

                return ret;
            },
            fill_query: function (query_string, obj) {
                // pure user query object.
                obj.user_query = {};

                if (query_string.length === 0) {
                    return;
                }

                // split again for angularjs.
                if (query_string.indexOf("?") >= 0) {
                    query_string = query_string.split("?")[1];
                }

                const queries = query_string.split("&");
                for (let i = 0; i < queries.length; i++) {
                    const elem = queries[i];
                    const query = elem.split("=");
                    obj[query[0]] = query[1];
                    obj.user_query[query[0]] = query[1];
                }

                // alias domain for vhost.
                if (obj.domain) {
                    obj.vhost = obj.domain;
                }
            }
        };
    }

    startRetry() {
        if (this.refreshInterval) {
            return;
        }

        this.refreshInterval = setInterval(() => {
            if (this.playing) {
                const videoTrack = this.stream.getAudioTracks()[0];

                if (videoTrack && !videoTrack.muted) {
                    this.receivedFirstPacket = true;
                    this.muted_in_row = 0;
                    return;
                }

                if (this.receivedFirstPacket) {
                    this.muted_in_row += 1;

                    if (this.muted_in_row >= 10) {
                        console.log('restarted by muted');
                        this.restart().then(() => {
                            this.setStreamOnPlayer(this.player);
                        });
                        this.muted_in_row = 0;
                    }
                }
            }
        }, 1000);
    }

    cancelRetry() {
        if (!this.refreshInterval) {
            return;
        }
        clearInterval(this.refreshInterval);
    }

    async setStreamOnPlayer(player: videojs.Player) {
        if (player) {
            this.player = player;
        } else {
            player = this.player;
        }

        player.tech().el().srcObject = this.stream;
        player.tech().el().play();

    }

    async play(url: string) {
        this.playing = true;

        if (url) {
            this.url = url;
        } else {
            url = this.url;
        }

        this.pc = new RTCPeerConnection();

        // Create a stream to add track to the stream, @see https://webrtc.org/getting-started/remote-streams
        this.stream = new MediaStream();

        // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/ontrack
        this.pc.ontrack = (event: RTCTrackEvent) => {
            if (this.ontrack) {
                this.ontrack(event);
            }
        };

        const conf = this.__internal.prepareUrl(url);
        this.pc.addTransceiver("audio", { direction: "recvonly" });
        this.pc.addTransceiver("video", { direction: "recvonly" });

        const offer = await this.pc.createOffer();

        await this.pc.setLocalDescription(offer);


        // @see https://github.com/rtcdn/rtcdn-draft
        const data = {
            api: conf.apiUrl,
            tid: conf.tid,
            streamurl: conf.streamUrl,
            clientip: null,
            sdp: offer.sdp
        };

        const res = await fetch(`${conf.apiUrl}`, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                'x-camerite-platform': 'pwa'
            }),
            body: JSON.stringify(data)
        });

        const session = await res.json();
        if (session.code) {
            throw session;
        }

        await this.pc.setRemoteDescription(
            new RTCSessionDescription({ type: 'answer', sdp: session.sdp })
        );

        session.simulator = conf.schema + '//' + conf.urlObject.server + ':' + conf.port + '/rtc/v1/nack/';
        return session;
    }

    // Close the player.
    close() {
        this.playing = false;
        this.pc && this.pc.close();
        this.pc = null;
    }

    restart() {
        this.close();
        return this.play(this.url);
    }

    // The callback when got remote track.
    // Note that the onaddstream is deprecated, @see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/onaddstream
    ontrack(event: RTCTrackEvent) {
        // https://webrtc.org/getting-started/remote-streams
        this.stream.addTrack(event.track);
    }

}

export default srsPlayer;
