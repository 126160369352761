import React, { useState } from 'react';
import { translate } from '../services/service-translate';
import { List } from "react-native-paper";
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    ListRenderItemInfo
} from 'react-native';
import { FlatList } from "react-native-gesture-handler";
import { CheckBox } from "react-native-elements";
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import '../styles/map.css';

export interface CheckAssociationItem {
    name?: string,
    title?: string,
    checked: boolean,
}

interface CheckboxProps {
    dataChecked: CheckAssociationItem[],
    dataUnchecked: CheckAssociationItem[],
    readOnly?: boolean;
}

export default function CheckAssociation({ dataChecked, dataUnchecked, readOnly }: CheckboxProps) {
    const [showSelected, setShowSelected] = useState(true);
    const [showOptions, setShowOptions] = useState(true);
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);


    function useCheckbox(item: CheckAssociationItem) {
        if (!readOnly) {
            item.checked = !item.checked;
            forceUpdate();
        }
    }

    const RenderItem = ({ item }: ListRenderItemInfo<CheckAssociationItem>) => (
        <View style={styles.groupCard}>
            <TouchableOpacity style={styles.innerGroupCard} onPress={() => useCheckbox(item)}>
                <View style={styles.innerGroupCard}>
                    <CheckBox
                        disabled={readOnly}
                        checked={item?.checked}
                        checkedColor={readOnly ? "#999" : undefined}
                        containerStyle={styles.checkBox}
                        onPress={() => useCheckbox(item)}
                    />
                    <Text style={styles.textButton}>{item.name ? item.name : item.title}</Text>
                </View>
            </TouchableOpacity>
        </View>
    );

    return (
        <>

            <View>
                <TouchableOpacity style={styles.dropDown} onPress={() => setShowSelected(!showSelected)}>
                    <View style={styles.viewIcon}>
                        {
                            showSelected ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                : <List.Icon icon="chevron-right" color="#b5b5b5" />
                        }
                    </View>
                    <Text style={styles.titleDropDown}>{translate("dropDownSelected").toLocaleUpperCase()}</Text>
                </TouchableOpacity>
            </View>
            {showSelected ?
                <View>
                    <FlatList
                        style={[styles.scrollView]}
                        bounces={false}
                        data={dataChecked}
                        contentContainerStyle={styles.flatlist}
                        renderItem={RenderItem}
                        removeClippedSubviews={true}
                        updateCellsBatchingPeriod={100}
                        windowSize={12}
                        nestedScrollEnabled
                        onEndReachedThreshold={0.03}
                    />
                </View> : null}
            {
                !readOnly ?
                    <>
                        <TouchableOpacity style={styles.dropDown} onPress={() => setShowOptions(!showOptions)}>
                            <View style={styles.viewIcon}>
                                {
                                    showOptions ? <List.Icon icon="chevron-down" color="#b5b5b5" />
                                        : <List.Icon icon="chevron-right" color="#b5b5b5" />
                                }
                            </View>

                            <Text style={styles.titleDropDown}>{translate("dropDownOptions").toLocaleUpperCase()}</Text>
                        </TouchableOpacity>
                        {showOptions ?

                            <View>
                                <FlatList
                                    style={[styles.scrollView]}
                                    bounces={false}
                                    data={dataUnchecked}
                                    contentContainerStyle={styles.flatlist}
                                    renderItem={RenderItem}
                                    removeClippedSubviews={true}
                                    updateCellsBatchingPeriod={100}
                                    windowSize={12}
                                    nestedScrollEnabled
                                    onEndReachedThreshold={0.03}
                                />
                            </View>

                            : null
                        }
                    </>
                    : null}
        </>
    );
}
const styles = StyleSheet.create({
    unckedIcon: {
        width: 12,
        height: 12,
        border: "1px solid #CCCCCC",
        borderRadius: 2
    },
    checkBox: {
        margin: 0,
        padding: 0,
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dropDownIcon: {
        color: "#b5b5b5",
        margin: 0,
    },
    viewIcon: {
        margin: 0,
        width: 30,
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
    },
    titleDropDown: {
        fontSize: 16,
    },
    dropDown: {
        width: "100%",
        height: "30px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    enabledIcon: {
        width: 20,
        height: 20,
    },
    flatlist: {},
    scrollView: {
        width: "100%",
    },
    groupCard: {
        color: "red",
        width: "100%",
        height: "30px",
        marginBottom: "5px",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    innerGroupCard: {
        width: "100%",
        marginLeft: 5,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    textButton: {
        fontSize: 16,
        textAlignVertical: "center",
        width: 300,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },

});
