import I18n, { TranslateOptions } from "i18n-js";
import en from "../languages/english.json";
import pt from "../languages/portuguese.json";
import es from "../languages/spanish.json";

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Localization from 'expo-localization';
import { showActionSheet, Option } from "../../src/components/ActionSheet";
import { ActionSheetOptions } from "@expo/react-native-action-sheet";

async function ChangeLanguage(lang: string) {

    if (lang == '') {
        I18n.locale = Localization.locale;
    } else {
        I18n.locale = lang;
    }
    await AsyncStorage.setItem('Translate', lang);
}

I18n.translations = { en, pt, es };
I18n.fallbacks = true;
I18n.locale = Localization.locale;

async function getDefaultLanguage() {
    const defaultLanguage = await AsyncStorage.getItem('Translate');
    if(!defaultLanguage) {
        return "";
    }
    return defaultLanguage;
}


function settingsLang(showActionSheetWithOptions: (options: ActionSheetOptions, callback: (i?: number) => void | Promise<void>) => void) {
    const list: Option[] = [ {
        title: translate('translate-pt'),
        onClick:async () => {
            await ChangeLanguage('pt'),
            window.location.reload();
        }
    },
    {
        title: translate('translate-es'),
        onClick:async () => {
            await ChangeLanguage('es');
            window.location.reload();
        }
    },
    {
        title: translate('translate-en'),
        onClick:async () => {
            await ChangeLanguage('en');
            window.location.reload();
        }
    },
    {
        title: translate('cancelAction'),
        destructive: true
    },
    ];

    showActionSheet(showActionSheetWithOptions, list);
}

export const translate = (key: string, options?: TranslateOptions) => I18n.t(key, options);
export { getDefaultLanguage, settingsLang };
export default ChangeLanguage;