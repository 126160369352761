import React, { useState } from "react";
import {
    Modal,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    NativeSyntheticEvent,
    TextInputKeyPressEventData,
    View
} from "react-native";
import { Checkbox, List } from "react-native-paper";
import { GetCrmCamerasParams } from "../services/platform-api";
import { translate } from "../services/service-translate";

interface CameraFilters {
    setSearchParams: React.Dispatch<React.SetStateAction<GetCrmCamerasParams>>;
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    modalVisible: boolean;
}

export default function CamFilter({ setSearchParams, modalVisible, setModalVisible }: CameraFilters) {
    const [isOnline, setIsOnline] = useState<boolean>(true);
    const [isOffline, setIsOffline] = useState<boolean>(true);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [search, setSearch] = useState('');

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key === "Enter") {
            setSearchParams({
                online: isOnline,
                offline: isOffline,
                disabled: isDisabled,
                page: 1,
                filter: search
            });
            setModalVisible(false);
        }
    }

    return (
        <Modal
            animationType='slide'
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
                setModalVisible(false);
            }}
        >
            <View style={styles.fadeView} />
            <View style={styles.modalFilter}>
                <View style={styles.headerModalFilter}>
                    <Text style={styles.modalFilterTitle}>{translate("filter")}</Text>
                    <TouchableOpacity onPress={() => {
                        setModalVisible(false);
                    }}
                    >
                        <List.Icon
                            style={styles.modalFilterIcon}
                            icon="chevron-down"
                            color="#0071bc"
                        />
                    </TouchableOpacity>
                </View>
                <View>
                    <Text style={styles.labelTextFilterInput}>{translate("camFilter")}</Text>
                    <View style={styles.searchInput}>
                        <TextInput
                            selectionColor='#0071bc'
                            style={styles.searchInputText}
                            value={search}
                            onKeyPress={handleKeyDown}
                            onChangeText={text => {
                                setSearch(text);
                            }}
                            autoFocus
                        />
                    </View>

                    <Text style={styles.labelTextFilterInput}>{translate("camFilterStatus")}</Text>
                    <View style={styles.modalButtonFilters}>
                        <TouchableOpacity
                            onPress={() => {
                                setIsOnline(!isOnline);
                            }}
                            disabled={!isOffline && !isDisabled}
                            style={styles.buttonCheck}
                        >
                            <View style={styles.check}>
                                <Checkbox
                                    disabled={!isOffline && !isDisabled}
                                    status={isOnline ? 'checked' : 'unchecked'}
                                />
                                <List.Icon
                                    icon={"check-circle"}
                                    color="green"
                                    style={styles.filterIcon}
                                />
                                <Text adjustsFontSizeToFit>{translate("online")}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity
                            onPress={() => {
                                setIsOffline(!isOffline);
                            }}
                            style={styles.buttonCheck}
                            disabled={!isOnline && !isDisabled}
                        >
                            <View style={styles.check}>
                                <Checkbox
                                    disabled={!isOnline && !isDisabled}
                                    status={isOffline ? 'checked' : 'unchecked'}
                                />

                                <List.Icon
                                    icon={"close-circle"}
                                    color="red"
                                    style={styles.filterIcon}
                                />
                                <Text style={styles.filterText} adjustsFontSizeToFit>{translate("offline")}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity
                            onPress={() => {
                                setIsDisabled(!isDisabled);
                            }}
                            style={styles.buttonCheck}
                            disabled={!isOnline && !isOffline}
                        >
                            <View style={styles.check}>
                                <Checkbox
                                    disabled={!isOnline && !isOffline}
                                    status={isDisabled ? 'checked' : 'unchecked'}
                                />

                                <List.Icon
                                    icon={"block-helper"}
                                    color="orange"
                                    style={styles.filterIcon}
                                />
                                <Text style={styles.filterText} adjustsFontSizeToFit>{translate("disabled")}</Text>
                            </View>
                        </TouchableOpacity>

                    </View>

                </View>

                <View style={styles.viewFilterButtonsModal}>
                    <TouchableOpacity style={styles.buttonClean} onPress={() => {
                        setSearch('');
                        setIsOnline(true);
                        setIsOffline(true);
                        setIsDisabled(true);
                        setSearchParams({
                            online: true,
                            offline: true,
                            disabled: true,
                            page: 1,
                            filter: ''
                        });
                        setModalVisible(false);
                    }}>
                        <Text style={styles.textClean}>{translate("clean")}</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={isOnline || isDisabled || isOffline || search !== '' ? styles.buttonFilterModal : styles.invalidButtonFilterModal}
                        disabled={isOnline || isDisabled || isOffline || search !== '' ? false : true}
                        onPress={() => {
                            setSearchParams({
                                online: isOnline,
                                offline: isOffline,
                                disabled: isDisabled,
                                page: 1,
                                filter: search
                            });
                            setModalVisible(false);
                        }

                        }>
                        <Text style={styles.textButtonModal}>{translate('results')}</Text>
                    </TouchableOpacity>

                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    fadeView: {
        flex: 1,
        position: 'relative',
        backgroundColor: 'black',
        opacity: 0.25
    },
    modalFilter: {
        backgroundColor: "#fff",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: '100%',
        height: '92.8%',
        gap: 15,
        paddingBottom: 15,
        paddingHorizontal: 5,
        display: 'flex',
        flexDirection: 'column'
    },
    textButtonModal: {
        color: '#fff',
        marginTop: '2%'
    },
    modalButtonFilters: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: 5,
        marginTop: 25,
        marginBottom: 25
    },
    buttonFilterModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: "#0071bc",
    },
    invalidButtonFilterModal: {
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: "#9a9a9a4f",
        borderRadius: 4,
        padding: 10,
        paddingHorizontal: 35,
        backgroundColor: "rgba(0, 113, 188, 0.5)",
    },
    viewFilterButtonsModal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '95%',
        marginHorizontal: 'auto'
    },
    labelTextFilterInput: {
        color: "#0071bc",
        marginTop: 25,
        fontSize: 16,
    },
    headerModalFilter: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        height: 40,
        width: '95%',
        justifyContent: 'flex-end'
    },
    modalFilterTitle: {
        fontSize: 16,
        color: "#0071bc",
        marginTop: 15,
        fontWeight: '600',
    },
    modalFilterIcon: {
        marginTop: 15,
        height: 25,
        width: 25
    },
    buttonClean: {
        width: '30%',
        height: 45,
        alignItems: 'center',
        justifyContent: 'center',
    },
    textClean: {
        color: "#0071bc",
        fontWeight: '600'
    },
    searchInput: {
        backgroundColor: '#fff',
        width: "100%",
        height: 45,
        color: '#000',
        fontSize: 16,
        borderBottomWidth: 1,
        borderBottomColor: '#0071bc',
    },
    searchInputText: {
        width: '100%',
        alignSelf: 'center',
        marginTop: 15,
        marginLeft: 10,
        height: 45,
        fontSize: 16
    },
    filterText: {
        fontSize: 14,
        color: '#000'
    },
    filterIcon: {
        width: 5,
        height: 5,
        marginRight: 20
    },
    buttonCheck: {
        width: '100%',
        height: 45
    },
    check: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        paddingHorizontal: 5,
        fontSize: 14,
    },
});
