import * as React from 'react';
import { Image } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context';

import { translate } from './services/service-translate';
import { UserData as UserDataInterface, platformAPI, invalidBearerToken } from './services/platform-api';
// screens
import Login from './pages/auth/Login';
import { Authentication } from './pages/auth/Authentication';
import { InputOTP } from './pages/auth/InputOTP';
import RecoveryPassword from './pages/config/RecoveryPassword';
import DisabledAccount from './pages/auth/DisabledAccount';

// pages
import CameraComponent from './pages/Cameras';
import Map from './pages/Map';
import VideoComponent from './pages/Video';
import More from './pages/More';
import UserData from './pages/config/UserData';
import Settings from './pages/config/Settings';
import LPR from './pages/config/LicensePlateRecognitionList';
import AcceptPrivacyAndTerms from './pages/terms/AcceptPrivacyAndTerms';
import Terms from './pages/terms/Terms';
import Policy from './pages/terms/PrivacyPolicy';
import CameraNotification from './pages/CameraNotification';
import ChatGroupComponent from './pages/ChatGroup';
import Chat from './pages/Chat';
import LicensePlateRecognitionDetails from './pages/config/LicensePlateRecognitionDetails';
import ImpersonateUsers from './pages/config/ImpersonateUsers';
import MarketingKit from './pages/crm/MarketingKit';
import HistoryPayments from './pages/crm/HistoryPayments';

// players
import WatchVideo from './pages/media/WatchVideo';
import CameraWatch from './pages/CameraWatch';

// crm group
import Groups from './pages/crm/group/List';
import CrmGroupsUserAssociation from './pages/crm/group/UserAssociation';
import CrmGroupsCamerasAssociation from './pages/crm/group/CameraAssociation';
import CrmGroupsForm from './pages/crm/group/Form';

// crm user
import CrmUserList from './pages/crm/user/List';
import CrmUserForm from './pages/crm/user/Form';
import CrmUserPayments from './pages/crm/user/Payments';
import CrmUserCameraGroups from './pages/crm/user/CameraGroups';
import CrmUserPermissionLevels from './pages/crm/user/PermissionLevels';

// crm permissions levels
import CrmPermissionLevels from './pages/crm/permission-levels/List';
import CrmPermissionLevelsUserAssociation from './pages/crm/permission-levels/UserAssociation';
import CrmPermissionLevelsForm from './pages/crm/permission-levels/Form';

//crm blacklist
import LprGroups from './pages/crm/blacklist/LprGroups';
import Blacklist from './pages/crm/blacklist/Blacklist';
import BlacklistForm from './pages/crm/blacklist/Form';
import LprGroupForm from './pages/crm/blacklist/LprGroupForm';

// charges/financial
import CrmChargesList from './pages/crm/charges/List';
import CrmDetailedCharge from './pages/crm/charges/DetailedCharge';
import CrmPredictions from './pages/crm/charges/Predictions';

//crm cameras
import CrmCameraRegistration from './pages/crm/camera/Register';
import CrmCameraList from './pages/crm/camera/List';
import CrmCameraGroupsAssociation from './pages/crm/camera/GroupAssociation';
import ChangeCameraAddress from './pages/crm/camera/ChangeCameraAddress';
import CameraPlan from './pages/crm/camera/CameraPlan';
import CrmCameraEdit from './pages/crm/camera/Edit';

//Persons of interest
import PersonsOfInterestList from './pages/crm/facial/List';

interface ComponentDefaultProps {
    navigation: Navigation;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    route: any;
    authenticatedUser?: UserDataInterface;
}

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const config = {
    screens: {
        Authentication: {
            path: '/signIn',
        },
        Login: {
            path: '/login'
        },
        InputOTP: {
            path: '/sms'
        },
        Invite: {
            path: '/invite/:wlslug/:token'
        },
        DemoInvite: {
            path: '/invite-demo/:wlslug'
        },
        RecoveryPassword: {
            path:
                '/checkDevice/change_password/:token'
        },
        Camera: {
            path: '',
            screens: {
                Cameras: '/cameras',
                Video: '/videos',
                More: '/more',
                ChatGroup: '/chat'
            }
        },
        CameraWatch: {
            initialRouteName: "Camera",
            path: '/cameras/:id'
        },
        WatchVideo: {
            initialRouteName: 'Video',
            path: '/videos/:id'
        },
        Map: '/cameras/map',
        UserData: '/more/userData',
        Settings: '/more/settings',
        AcceptPrivacyAndTerms: '/accept-privacy-and-terms',
        DisabledAccount: '/disabled-account',
        Terms: '/about/terms-of-use',
        Policy: '/about/privacy-policy',
        Chat: '/chat/:id',
        LPR: '/lpr',
        LPR_Details: '/lpr/:id',
        CrmUserList: '/crm/users',
        CrmUserForm: '/crm/users/:id?/form/',
        CrmUserPayments: '/crm/users/:id/payments',
        CrmUserCameraGroups: '/crm/users/:id/camera-groups',
        CrmUserPermissionLevels: '/crm/users/:id/permission-levels',
        Impersonate: '/impersonate',
        Groups: '/groups',
        CrmCameraList: '/crm/cameras',
        CrmCameraRegistration: '/crm/cameras/form',
        CrmCameraGroupsAssociation: '/crm/camera/:id/groups',
        CrmGroupsUserAssociation: '/groups/user-association/:id',
        CrmGroupsCamerasAssociation: '/groups/cameras-association/:id',
        CrmGroupsForm: '/groups/form',
        CrmPermissionLevels: '/crm/permission-levels',
        CrmPermissionLevelsUserAssociation: '/crm/permission-levels/:id/user-association',
        CrmPermissionLevelsForm: '/crm/permission-levels/:id?/form',
        CrmChargesList: '/crm/charges',
        CrmDetailedCharge: '/crm/charges/:id?/detailed-charge',
        CrmPredictions: '/crm/charges/predictions',
        LprGroups: '/crm/lpr/groups',
        Blacklist: '/crm/lpr/blacklist/:id?',
        BlacklistForm: '/crm/lpr/blacklist/:cameraGroupId?/form/:id?',
        MarketingKit: '/crm/marketing-kit',
        HistoryPayments: '/more/HistoryPayments',
        CameraPlan: '/crm/camera/:id/plan',
        LprGroupForm: '/crm/lpr/groups/form/:id?',
        ChangeCameraAddress: '/crm/camera/:id/change-address',
        CrmCameraEdit: '/crm/camera/:id/edit',
        PersonsOfInterestList: '/crm/persons-of-interest'
    }
};

const linking = {
    prefixes: ['https://camerite.com', 'mycam://'],
    config,
};

function Authenticate(component: ({ navigation, route, authenticatedUser }: ComponentDefaultProps) => JSX.Element) {
    return ({ navigation, route }: ComponentDefaultProps) => {
        const [authenticatedUser, setAuthenticatedUser] = React.useState<UserDataInterface>();
        async function checkAuth() {
            if (route?.params?.pathname) {
                const path = route.params.pathname.split('/');
                if (path.length == 3 && path[1] == 'invite-demo') {
                    navigation.navigate('DemoInvite', {
                        wlslug: path[2]
                    });
                    return;
                }
                if (path.length == 4 && path[1] == 'invite') {
                    navigation.navigate('Invite', {
                        wlslug: path[2],
                        token: path[3]
                    });
                    return;
                }
                if (path.length == 4 && path[1] == "checkDevice" && path[2] == 'change_password') {
                    navigation.navigate('RecoveryPassword', {
                        token: path[3]
                    });
                    return;
                }
            }

            try {
                const userReq = await platformAPI.getUserData();
                setAuthenticatedUser(userReq);
                if (!userReq.didAcceptUseTerms) {
                    return navigation.navigate("AcceptPrivacyAndTerms");
                }
                if (!await checkIfUserHasRequiredFields(userReq)) {
                    return navigation.navigate("UserData");
                }
                if (userReq.disabledReason) {
                    return navigation.navigate("DisabledAccount");
                }
            } catch (err) {
                if (err instanceof invalidBearerToken) {
                    navigation.navigate("Login");
                    return;
                }
                console.error(err);
            }
        }
        React.useEffect(() => {
            checkAuth();
        }, []);
        return component({ navigation, route, authenticatedUser });
    };
}
async function checkIfUserHasRequiredFields(user: UserDataInterface) {
    const wl = await platformAPI.getWhitelabelSingupFields();

    if (wl.signup_fields.signup_phone == 'REQUIRED' && !user.phone) {
        return false;
    }
    if (wl.signup_fields.signup_name == 'REQUIRED' && !user.name) {
        return false;
    }
    if (wl.signup_fields.signup_email == 'REQUIRED' && !user.email) {
        return false;
    }
    if (wl.signup_fields.signup_cpf == 'REQUIRED' && !user.cpf &&
        !user.cnpj) {
        return false;
    }
    if (wl.signup_fields.signup_cep == 'REQUIRED' && !user.cep) {
        return false;
    }
    if (wl.signup_fields.signup_state == 'REQUIRED' && !user.state) {
        return false;
    }
    if (wl.signup_fields.signup_city == 'REQUIRED' && !user.city) {
        return false;
    }
    if (wl.signup_fields.signup_street == 'REQUIRED' && !user.street) {
        return false;
    }
    if (wl.signup_fields.signup_neighborhood == 'REQUIRED' && !user.district) {
        return false;
    }
    if (wl.signup_fields.signup_number == 'REQUIRED' && !user.streetNumber) {
        return false;
    }
    if (wl.signup_fields.signup_complement == 'REQUIRED' && !user.complement) {
        return false;
    }
    return true;
}

const PagesRoutes = ({ navigation, route }: ComponentDefaultProps) => {
    const insets = useSafeAreaInsets();

    if (route?.params?.pathname) {
        const path = route.params.pathname.split('/');

        if (path.length == 3 && path[1] == 'invite-demo') {
            navigation.navigate('DemoInvite', {
                wlslug: path[2]
            });
            return (<></>);
        }
        if (path.length == 4 && path[1] == 'invite') {
            navigation.navigate('Invite', {
                wlslug: path[2],
                token: path[3]
            });
            return (<></>);
        }
        if (path.length == 4 && path[1] == "checkDevice" && path[2] == 'change_password') {
            navigation.navigate('RecoveryPassword', {
                token: path[3]
            });
            return (<></>);
        }
    }

    return (
        <SafeAreaProvider initialSafeAreaInsets={insets} style={{ paddingBottom: insets.bottom, backgroundColor: "#000" }}>
            <Tab.Navigator
                initialRouteName='Cameras'
                screenOptions={{
                    tabBarStyle: {
                        height: 50,
                        paddingBottom: 4
                    }
                }}
            >
                <Tab.Screen
                    name="Cameras"
                    component={Authenticate(CameraComponent)}
                    options={{
                        tabBarLabelStyle: { fontSize: 12 },
                        headerShown: false,
                        title: translate('cameras'),
                        tabBarIcon: ({ focused }) =>
                            <Image
                                source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_camera.png' }}
                                style={{
                                    tintColor: focused ? "#007AFF" : "#808080",
                                    width: 20,
                                    height: 20
                                }}
                            />
                    }}
                />
                <Tab.Screen
                    name="Video"
                    component={Authenticate(VideoComponent)}
                    options={{
                        tabBarLabelStyle: { fontSize: 12 },
                        headerShown: false,
                        title: translate('videos'),
                        tabBarIcon: ({ focused }) =>
                            <Image
                                source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_videos.png' }}
                                style={{
                                    tintColor: focused ? "#007AFF" : "#808080",
                                    width: 20,
                                    height: 20
                                }}
                            />
                    }}
                />
                <Tab.Screen
                    name="ChatGroup"
                    component={Authenticate(ChatGroupComponent)}
                    options={{
                        tabBarLabelStyle: { fontSize: 12 },
                        headerShown: false,
                        title: translate('chat_tab'),
                        tabBarIcon: ({ focused }) =>
                            <Image
                                source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_chat.png' }}
                                style={{
                                    tintColor: focused ? "#007AFF" : "#808080",
                                    marginTop: 3,
                                    width: 25,
                                    height: 25
                                }}
                            />
                    }}
                />
                <Tab.Screen
                    name="More"
                    component={Authenticate(More)}
                    options={{
                        tabBarLabelStyle: { fontSize: 12 },
                        headerShown: false,
                        title: translate('more'),
                        tabBarIcon: ({ focused }) =>
                            <Image
                                source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_more.png' }}
                                style={{
                                    tintColor: focused ? "#007AFF" : "#808080",
                                    marginTop: 3,
                                    width: 25,
                                    height: 25
                                }}
                            />
                    }}
                />
            </Tab.Navigator>
        </SafeAreaProvider>
    );
};

export default function MainRoutes() {
    return (
        <SafeAreaProvider style={{ backgroundColor: "#000" }}>
            <NavigationContainer linking={linking}>
                <Stack.Navigator initialRouteName=''>
                    {/* doesn't require authenticate */}
                    <Stack.Screen name="Login" component={Login} options={{ headerShown: false }} />
                    <Stack.Screen name="Authentication" component={Authentication} options={{ headerShown: false }} />
                    <Stack.Screen name="InputOTP" component={InputOTP} options={{ headerShown: false }} />
                    <Stack.Screen name="Invite" component={UserData} options={{ headerShown: false }} />
                    <Stack.Screen name="DemoInvite" component={UserData} options={{ headerShown: false }} />
                    <Stack.Screen name="Terms" component={Terms} options={{ headerShown: false, title: translate('listTerms') }} />
                    <Stack.Screen name="Policy" component={Policy} options={{ headerShown: false, title: translate('policy') }} />
                    <Stack.Screen name='RecoveryPassword' component={RecoveryPassword} options={{ headerShown: false, title: translate("RecoverPassword") }} />
                    <Stack.Screen name="Camera" component={PagesRoutes} options={{ headerShown: false }} />

                    {/* required authenticate  */}
                    <Stack.Screen name="Map" component={Authenticate(Map)} options={{ headerShown: false, title: translate('GENERIC_MAP') }} />
                    <Stack.Screen name="AcceptPrivacyAndTerms" component={Authenticate(AcceptPrivacyAndTerms)} options={{ headerShown: false, title: translate('BeforeGoing') }} />
                    <Stack.Screen name="WatchVideo" component={Authenticate(WatchVideo)} options={{ headerShown: false }} />
                    <Stack.Screen name="CameraWatch" component={Authenticate(CameraWatch)} options={{ headerShown: false }} />
                    <Stack.Screen name="UserData" component={Authenticate(UserData)} options={{ headerShown: false }} />
                    <Stack.Screen name="Settings" component={Authenticate(Settings)} options={{ headerShown: false }} />
                    <Stack.Screen name='CameraNotification' component={Authenticate(CameraNotification)} options={{ headerShown: false }} />
                    <Stack.Screen name='Chat' component={Authenticate(Chat)} options={{ headerShown: false }} />
                    <Stack.Screen name='LPR' component={Authenticate(LPR)} options={{ headerShown: false }} />
                    <Stack.Screen name='LPR_Details' component={Authenticate(LicensePlateRecognitionDetails)} options={{ headerShown: false }} />
                    <Stack.Screen name='CrmUserList' component={Authenticate(CrmUserList)} options={{ headerShown: false, title: translate("users") }} />
                    <Stack.Screen name='CrmUserForm' component={Authenticate(CrmUserForm)} options={{ headerShown: false, title: translate("registerUser") }} />
                    <Stack.Screen name='CrmUserPayments' component={Authenticate(CrmUserPayments)} options={{ headerShown: false, title: translate("payment") }} />
                    <Stack.Screen name='CrmUserCameraGroups' component={Authenticate(CrmUserCameraGroups)} options={{ headerShown: false, title: translate("cameraGroups") }} />
                    <Stack.Screen name='CrmUserPermissionLevels' component={Authenticate(CrmUserPermissionLevels)} options={{ headerShown: false, title: translate("userPermissionLevels") }} />
                    <Stack.Screen name='MarketingKit' component={Authenticate(MarketingKit)} options={{ headerShown: false, title: translate("marketingKit") }} />
                    <Stack.Screen name='HistoryPayments' component={Authenticate(HistoryPayments)} options={{ headerShown: false, title: translate("paymentsHistory") }} />
                    <Stack.Screen name='CrmCameraRegistration' component={Authenticate(CrmCameraRegistration)} options={{ headerShown: false, title: translate('camRegister') }} />
                    <Stack.Screen name='Impersonate' component={Authenticate(ImpersonateUsers)} options={{ headerShown: false }} />
                    <Stack.Screen name='Groups' component={Authenticate(Groups)} options={{ headerShown: false, title: translate("groupsTitle") }} />
                    <Stack.Screen name='CrmGroupsUserAssociation' component={Authenticate(CrmGroupsUserAssociation)} options={{ headerShown: false, title: translate("associateUsers") }} />
                    <Stack.Screen name='CrmGroupsCamerasAssociation' component={Authenticate(CrmGroupsCamerasAssociation)} options={{ headerShown: false, title: translate("associateCameras") }} />
                    <Stack.Screen name='CrmGroupsForm' component={Authenticate(CrmGroupsForm)} options={{ headerShown: false, title: translate("moreGroups") }} />
                    <Stack.Screen name='CrmPermissionLevels' component={Authenticate(CrmPermissionLevels)} options={{ headerShown: false, title: translate("permissionLevel") }} />
                    <Stack.Screen name='CrmPermissionLevelsUserAssociation' component={Authenticate(CrmPermissionLevelsUserAssociation)} options={{ headerShown: false, title: translate("associateUsers") }} />
                    <Stack.Screen name='CrmPermissionLevelsForm' component={Authenticate(CrmPermissionLevelsForm)} options={{ headerShown: false, title: translate("permissionLevelsTab") }} />
                    <Stack.Screen name='CrmCameraList' component={Authenticate(CrmCameraList)} options={{ headerShown: false, title: translate("cameras") }} />
                    <Stack.Screen name='CrmCameraEdit' component={Authenticate(CrmCameraEdit)} options={{ headerShown: false, title: translate('cameraEditTitle') }} />
                    <Stack.Screen name='CrmChargesList' component={Authenticate(CrmChargesList)} options={{ headerShown: false, title: translate('charges') }} />
                    <Stack.Screen name='CrmDetailedCharge' component={Authenticate(CrmDetailedCharge)} options={{ headerShown: false, title: translate('detailedCharge') }} />
                    <Stack.Screen name='CrmPredictions' component={Authenticate(CrmPredictions)} options={{ headerShown: false, title: translate('detailedCharge') }} />
                    <Stack.Screen name='CrmCameraGroupsAssociation' component={Authenticate(CrmCameraGroupsAssociation)} options={{ headerShown: false, title: translate('associateUsers') }} />
                    <Stack.Screen name='LprGroups' component={Authenticate(LprGroups)} options={{ headerShown: false, title: translate("LprGroups") }} />
                    <Stack.Screen name='LprGroupForm' component={Authenticate(LprGroupForm)} options={{ headerShown: false, title: translate("LprGroups") }} />
                    <Stack.Screen name='Blacklist' component={Authenticate(Blacklist)} options={{ headerShown: false, title: translate("blacklist") }} />
                    <Stack.Screen name='BlacklistForm' component={Authenticate(BlacklistForm)} options={{ headerShown: false, title: translate("blacklist") }} />
                    <Stack.Screen name='ChangeCameraAddress' component={Authenticate(ChangeCameraAddress)} options={{ headerShown: false, title: translate("changeAddress") }} />
                    <Stack.Screen name='CameraPlan' component={Authenticate(CameraPlan)} options={{ headerShown: false, title: translate("changePlan") }} />
                    <Stack.Screen name='PersonsOfInterestList' component={Authenticate(PersonsOfInterestList)} options={{ headerShown: false, title: translate("PersonOfInterest") }} />
                    <Stack.Screen name='DisabledAccount' component={Authenticate(DisabledAccount)} options={{ headerShown: false, title: translate("disabledAccount") }} />
                </Stack.Navigator>
            </NavigationContainer>
        </SafeAreaProvider>
    );
}
