import React, { useEffect, useState, useCallback } from 'react';
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    TextInput,
    Image,
    ListRenderItemInfo,
    TextInputKeyPressEventData,
    NativeSyntheticEvent,
    FlatList,
    ScrollView,
    KeyboardAvoidingView,
    ActivityIndicator
} from 'react-native';
import { platformAPI, ChatMessage, ChatGroup, invalidBearerToken } from "../services/platform-api";
import { List } from 'react-native-paper';
import moment from 'moment';
import { translate } from '../services/service-translate';
import { ChatNotification, notificationService } from '../services/notification-service';
import { useFocusEffect } from '@react-navigation/native';
import DefaultPageContainer from '../components/DefaultPageContainer';

interface ChatProps {
    navigation: Navigation;
    route: {
        params: ChatGroup;
    };
}

const headerSize = 60;
const footerSize = 70;

export default function Chat({ route, navigation }: ChatProps) {
    const param = route.params;
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [text, setText] = useState('');
    const [canSend, setCanSend] = useState(true);
    const [groupName, setGroupName] = useState<string>();
    const [messageLoader, setMessageLoader] = useState(false);
    const [groupList, setGroupList] = useState<ChatGroup[]>([]);
    const [loadingMessages, setLoadingMessages] = useState(true);
    const scrollViewRef = React.useRef<ScrollView>(null);
    const textInputRef = React.useRef<TextInput>(null);

    useFocusEffect(
        useCallback(() => {
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
        }, [navigation])
    );

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            getMessages();
            getGroupName();
        });
        return unsubscribe;
    }, [navigation]);

    async function getGroupName() {
        try {
            const res = await platformAPI.getChatGroups();

            setGroupList(res);

            res.map((group: ChatGroup) => {
                if (group.id == param.id) {
                    setGroupName(group.title);
                }
                return;
            });
        } catch (err) {
            console.error(err);
        }
    }

    async function getMessages() {
        setLoadingMessages(true);
        try {
            const res = await platformAPI.getChatMessage(param.id);

            setMessages(res);
            setLoadingMessages(false);
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    async function sendMessage() {
        try {
            const inputRef = textInputRef.current;
            const tempText = text;
            setMessageLoader(true);
            setMessages(oldList => [...oldList, {
                message: text,
                timestamp: new Date().getTime(),
                groupId: param.id,
                groupTitle: groupName,
                isAuthor: true
            }
            ]);
            setText('');
            await platformAPI.sendChatMessage(tempText, param.id);

            if (inputRef) {
                inputRef.focus();
            }

            setMessageLoader(false);
        } catch (err) {
            console.error(err);
        }
    }

    notificationService.chatNotificationListener = async (data: ChatNotification) => {
        if (param.id == Number(data.id)) {
            setMessages(oldList => [...oldList, {
                message: data.message,
                userName: data.username,
                timestamp: Number(data.timestamp),
                groupId: Number(data.id),
                groupTitle: data.title,
                isAuthor: false
            }
            ]);
        } else {
            const serviceWorkerRegistration = await navigator.serviceWorker.ready;
            serviceWorkerRegistration.showNotification(`${data.username} @ ${data.title}`, {
                icon: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
                body: data.message,
                badge: 'https://camerite-landing-page-v2.s3.amazonaws.com/favicon.ico',
                tag: 'chat_message'
            });
        }
    };

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            sendMessage();
        }
    }

    const onChangeText = (text: string) => {

        if (text.length == 0) {
            setCanSend(true);
        } else {
            setCanSend(false);
        }
        setText(text);
    };

    function goBack() {
        notificationService.chatNotificationListener = notificationService.defaultChatNotificationListener;

        if (groupList.length == 1) {
            navigation.navigate("Camera", { screen: "Cameras" });
            return;
        }
        navigation.navigate("Camera", { screen: "ChatGroup" });
    }

    function formattedDate(timestamp: number) {
        let formattedDate;
        const messageDate = moment(timestamp);

        const now = moment();

        if (messageDate.isSame(now, 'day')) {
            formattedDate = messageDate.format('HH:mm');
        } else if (messageDate.isSame(now.clone().subtract(1, 'days'), 'day')) {
            formattedDate = translate('yesterday_date', {
                Time: messageDate.format('HH:mm')
            });
        } else {
            formattedDate = messageDate.format('DD/MM/YYYY HH:mm');
        }

        return formattedDate;
    }


    function renderItem({ item }: ListRenderItemInfo<ChatMessage>) {
        return (
            <>
                <View style={item.isAuthor ? styles.fromMe : styles.forMe}>
                    <Text selectable={false} style={styles.messageAuthor}>{item.isAuthor ? null : item.userName}</Text>
                    <View style={styles.dateMessageView}>
                        <Text style={[styles.messageText, { color: item.isAuthor ? "#fff" : "#000" }]}>{item.message}</Text>
                        <View style={styles.dateView}>
                            <Text selectable={false} style={[styles.messageDate, { color: item.isAuthor ? "#D1D1D1" : "#909090" }]}>{formattedDate(item.timestamp)}</Text>
                            {item.isAuthor ?
                                messageLoader == true && messages[messages.length - 1]?.timestamp == item.timestamp ?
                                    <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_messageLoader.png' }} style={styles.messageLoader} /> :
                                    <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fmessage_sent.png' }} style={styles.messageSent} />
                                : null
                            }
                        </View>
                    </View>
                </View>
            </>
        );
    }

    return (
        <DefaultPageContainer customBackgroundColor='#dfdfdf'>
            <KeyboardAvoidingView
                keyboardVerticalOffset={50}
                behavior={'padding'}
                style={styles.containerAvoidView}
            >
            </KeyboardAvoidingView>
            <View style={styles.header}>
                <TouchableOpacity onPress={goBack}>
                    <List.Icon style={styles.backArrow} icon='arrow-left' color="#fff" />
                </TouchableOpacity>
                <View style={styles.titleHeaderView}>
                    <Text style={styles.titleHeader}>{groupName ? groupName : translate('chat_tab')}</Text>
                </View>
            </View>
            {loadingMessages ?
                <View style={[styles.chatView, { flex: 1, justifyContent: 'center', alignItems: 'center' }]}>
                    <ActivityIndicator size={50} style={{ padding: 10 }} />
                    <Text style={{ padding: 5 }}>{translate('loading')}...</Text>
                </View>
                :
                <>
                    {messages.length ?
                        <ScrollView
                            ref={scrollViewRef}
                            style={[styles.chatView, { height: 1 }]}
                            onContentSizeChange={() => scrollViewRef.current?.scrollToEnd({ animated: false })}
                        >
                            <FlatList
                                data={messages?.sort((a: ChatMessage, b: ChatMessage) => b.timestamp - a.timestamp)}
                                renderItem={renderItem}
                                inverted
                                style={{ marginVertical: 10 }}
                            />

                        </ScrollView>
                        :
                        <View style={[styles.chatView, { flex: 1, justifyContent: 'center', alignItems: 'center' }]}>
                            <Text style={{ color: "#939393" }} >{translate('chatEmpty')}</Text>
                        </View>
                    }
                    <View style={styles.inputView}>
                        <View style={styles.textInputView}>
                            <TextInput
                                value={text}
                                ref={textInputRef}
                                onChangeText={onChangeText}
                                style={styles.textEntry}
                                placeholder={translate('chatPlaceholder')}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={styles.sendButtonView}>
                            <TouchableOpacity disabled={canSend} style={styles.sendTouchable} onPress={sendMessage}>
                                <View style={styles.sendCircle}>
                                    <Image source={{ uri: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grtqyv6nwfc2/b/camerite/o/pwa%2fic_send.png' }} style={styles.sendIcon} />
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                </>
            }
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    containerAvoidView: {
        alignItems: 'center',
    },
    header: {
        height: headerSize,
        width: "100%",
        backgroundColor: '#0071bc',
        flexDirection: 'row',
        alignItems: 'center'
    },
    backArrow: {
        alignSelf: 'flex-start',
    },
    titleHeaderView: {
        justifyContent: 'center'
    },
    titleHeader: {
        alignSelf: 'flex-start',
        fontSize: 20,
        color: '#fff',
        marginLeft: 15,
        fontWeight: '600'
    },
    chatView: {
        paddingHorizontal: 15,
        backgroundColor: '#f7f7f7'
    },
    messageAuthor: {
        fontWeight: "500",
        fontSize: 10,
        paddingHorizontal: 6,
        color: "#373737"
    },
    dateMessageView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: "100%",
    },
    dateView: {
        paddingHorizontal: 5,
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row',
    },
    messageDate: {
        marginHorizontal: 5,
        alignSelf: 'flex-end',
        textAlign: 'center'
    },
    messageLoader: {
        width: 13,
        height: 13,
        alignSelf: 'flex-end',
        marginBottom: 1,
        tintColor: "#fff"
    },
    messageSent: {
        width: 20,
        height: 10,
        alignSelf: 'flex-end',
        marginBottom: 3,
        tintColor: "#fff"
    },
    messageText: {
        padding: 6,
        fontWeight: '600'
    },
    fromMe: {
        alignItems: 'flex-start',
        alignSelf: 'flex-end',
        backgroundColor: "#0071bc",
        padding: 5,
        marginVertical: 7,
        borderBottomLeftRadius: 8,
        borderTopLeftRadius: 8,
        borderBottomRightRadius: 8,
        shadowOffset: {
            width: 3,
            height: 3,
        },
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowRadius: 5,
    },
    forMe: {
        alignItems: 'flex-start',
        alignSelf: 'flex-start',
        backgroundColor: "#E1E1E1",
        padding: 5,
        marginVertical: 7,
        borderBottomRightRadius: 8,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        shadowOffset: {
            width: 3,
            height: 3,
        },
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowRadius: 5,

    },
    inputView: {
        height: footerSize,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowColor: "#000",
        shadowOpacity: 0.25,
        shadowRadius: 4,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textInputView: {
        flex: 1,
        paddingHorizontal: 15,
        height: '100%',
        justifyContent: 'center',
    },
    sendButtonView: {
        height: "100%",
        justifyContent: 'center',
        paddingHorizontal: 15
    },
    textEntry: {
        backgroundColor: "white",
        borderRadius: 10,
        padding: 10,
        textAlignVertical: 'center',
        fontSize: 20,
        width: '100%',
    },
    sendTouchable: {
        justifyContent: 'center',
        alignSelf: 'center'
    },
    sendCircle: {
        height: 50,
        width: 50,
        backgroundColor: '#072d3a',
        borderRadius: 30,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        tintColor: '#cecece'
    },
    sendIcon: {
        width: 30,
        height: 30
    }
});
