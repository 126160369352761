import React, { useState, useEffect } from "react";
import {
    UserData,
    platformAPI,
    invalidBearerToken,
    WhitelabelUser,
    forbiddenAccess,
    CrmGroup
} from "../../../services/platform-api";
import { translate } from '../../../services/service-translate';
import { ActivityIndicator, List } from "react-native-paper";
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import CheckBoxLists, { CheckAssociationItem } from "../../../components/CheckAssociation";
import DefaultPageContainer from "../../../components/DefaultPageContainer";

interface CrmPermissionLevelsUserAssociationComponentProps {
    navigation: Navigation;
    route: {
        params: {
            id: number;
        };
    };
    authenticatedUser?: UserData;
}

const headerSize = 60;

export default function CrmPermissionLevelsUserAssociation({ navigation, route, authenticatedUser }: CrmPermissionLevelsUserAssociationComponentProps) {
    const params = route.params;
    const [readOnly, setReadOnly] = useState(true);
    const [checkedUsers, setCheckedUsers] = useState<CrmGroup>();
    const [uncheckedUsers, setUncheckedUsers] = useState<WhitelabelUser[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.body.style.position = 'fixed';
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
    }, [navigation, route]);

    useEffect(() => {
        loadUsers();
        checkPermissions();
    }, [authenticatedUser]);

    useEffect(() => {
        if (!readOnly && checkedUsers) {
            loadWhitelabelUsers();
        }
    }, [readOnly, checkedUsers]);

    async function save() {
        try {
            if (!checkedUsers) return;

            const usersUnchecked = checkedUsers.GroupUser.filter((groupUser: WhitelabelUser) => !groupUser.checked);
            const usersChecked = uncheckedUsers.filter((groupUser: WhitelabelUser) => groupUser.checked);

            const groupToPut = {
                id: checkedUsers.id,
                users_to_add: usersChecked.map((user: WhitelabelUser) => user.id),
                users_to_rm: usersUnchecked.map((user: WhitelabelUser) => user.id),
                permissions: {}
            };

            if (groupToPut.users_to_add.length || groupToPut.users_to_rm.length) {
                await platformAPI.updateCrmPermissionsGroup(groupToPut);
            }

            goBack();
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    async function checkPermissions() {
        if (!authenticatedUser) return;
        try {
            if (!authenticatedUser.permissions.can_see_permission_levels_tab) {
                navigation.navigate("CrmPermissionLevels");
            } else if (authenticatedUser.permissions.can_edit_permission_levels) {
                setReadOnly(false);
            }
        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            console.error(err);
        }
    }

    async function loadWhitelabelUsers() {
        const whitelabelUsers = await platformAPI.getWhiteLabelUsers();
        setUncheckedUsers(whitelabelUsers.filter((user: WhitelabelUser) => !checkedUsers?.GroupUser.find((groupUser) => groupUser.id === user.id)));
    }

    async function loadUsers() {
        try {
            if (!authenticatedUser) return;

            setLoading(true);

            const groupRequest = await platformAPI.getCrmGroupPermissions(params.id);

            for (const user of groupRequest.GroupUser) {
                user.checked = true;
            }

            groupRequest.GroupUser.sort((a, b) => a.name > b.name ? 1 : -1);

            setCheckedUsers(groupRequest);

            setLoading(false);

        } catch (err) {
            if (err instanceof invalidBearerToken) {
                navigation.navigate("Login");
                return;
            }
            if (err instanceof forbiddenAccess) {
                navigation.navigate("CrmPermissionLevels");
                return;
            }
            console.error(err);
            setLoading(false);
        }
    }

    function goBack() {
        navigation.navigate("CrmPermissionLevels");
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <TouchableOpacity style={styles.titleHeaderView} onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    {!readOnly ?
                        <TouchableOpacity style={styles.titleHeaderView} onPress={save}>
                            <Text style={styles.titleHeader}>{translate("save")}</Text>
                        </TouchableOpacity>

                        : null}
                </View>
            </View>
            <View style={styles.scrollContainer}>
                <ScrollView style={styles.backgroundView}>
                    <CheckBoxLists
                        dataChecked={checkedUsers?.GroupUser as CheckAssociationItem[]}
                        dataUnchecked={uncheckedUsers as CheckAssociationItem[]}
                        readOnly={readOnly}
                    />
                </ScrollView>
            </View>
            <View style={styles.footer}>
                <ActivityIndicator
                    animating={loading}
                    style={[styles.loading, loading && { backgroundColor: '#FFFFFFF2' }]}
                    size="small"
                    color="#0071bc"
                />
            </View>
        </DefaultPageContainer>
    );
}

const styles = StyleSheet.create({
    scrollContainer: {
        backgroundColor: '#fff',
        alignItems: 'center',
    },
    backgroundView: {
        backgroundColor: "#fff",
        width: '100%',
        height: 'calc(100vh - 60px)',
    },
    loading: {
        paddingVertical: 5,
        width: '10%',
        borderRadius: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noResults: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    textDefaultGroup: {
        marginTop: "5px",
        color: "#0071bc",
        fontSize: 12,
    },
    titleHeaderView: {
        justifyContent: "center",
    },
    headerContent: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    header: {
        height: headerSize,
        width: '100%',
        backgroundColor: '#0071bc',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row'
    },
    backArrow: {
        alignSelf: "flex-start",
    },
    titleHeader: {
        fontSize: 20,
        color: '#fff',
        marginLeft: 15,
        marginRight: 15,
        alignSelf: 'center',
    },
});
