import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { platformAPI, UserData } from '../../services/platform-api';
import { translate } from '../../services/service-translate';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import { CheckBox } from 'react-native-elements';
import { List } from 'react-native-paper';
import { useLinkTo } from '@react-navigation/native';

interface AcceptPrivacyAndTermsProps {
    navigation: Navigation;
    authenticatedUser?: UserData;
}

export default function AcceptPrivacyAndTerms({ navigation, authenticatedUser}: AcceptPrivacyAndTermsProps) {
    const [checkTerms, setCheckTerms] = useState<boolean>(false);
    const [checkPolicy, setCheckPolicy] = useState<boolean>(false);
    const linkTo = useLinkTo();

    async function save() {
        try {
            if (!authenticatedUser) return;

            await platformAPI.userAcceptTerms();
            linkTo('/cameras');
        } catch (err) {
            console.error(err);
        }
    }


    useEffect(() => {
        document.body.style.position = 'fixed';
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0071bc');
    }, []);

    async function goBack() {
        await platformAPI.signOut();
        navigation.navigate('Login');
        return;
    }

    return (
        <DefaultPageContainer>
            <View style={styles.header}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onPress={goBack}>
                        <List.Icon
                            style={styles.backArrow}
                            icon="arrow-left"
                            color="#fff"
                        />
                    </TouchableOpacity>
                    <View style={styles.titleHeaderView}>
                        <Text style={styles.titleHeader}>{translate("BeforeGoing")}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.bodyContainer}>
                <View style={styles.contentContainer}>
                    <View>
                        <Text style={styles.textContent}>{translate("Hello")}</Text>
                        <Text style={styles.textContent}>{translate("LinkToUseTerms")}
                            <TouchableOpacity onPress={() => navigation.navigate('Terms')}>
                                <Text style={styles.textLinks}> {translate("listTerms")} </Text>
                            </TouchableOpacity>
                            {translate("And")}
                            <TouchableOpacity onPress={() => navigation.navigate('Policy')}>
                                <Text style={styles.textLinks}> {translate("policy")}</Text>
                            </TouchableOpacity>.
                        </Text>
                        <Text style={styles.textContent}>{translate("WeMakeTermsUpdate")}</Text>
                        <Text style={styles.textContent}>{translate("ToContinueUsing")}</Text>
                    </View>
                    <View style={styles.checksView}>
                        <TouchableOpacity style={styles.termsView} onPress={() => setCheckTerms(!checkTerms)}>
                            <CheckBox
                                disabled={authenticatedUser?.didAcceptUseTerms}
                                checked={checkTerms}
                                checkedColor={authenticatedUser?.didAcceptUseTerms ? "#999" : undefined}
                                containerStyle={styles.checkBox}
                                onPress={() => setCheckTerms(!checkTerms)}
                            />
                            <Text style={styles.textAgreeCheck}>{translate("IDoAcceptThe")}{translate("listTerms")}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.termsView} onPress={() => setCheckPolicy(!checkPolicy)}>
                            <CheckBox
                                disabled={authenticatedUser?.didAcceptUseTerms}
                                checked={checkPolicy}
                                checkedColor={authenticatedUser?.didAcceptUseTerms ? "#999" : undefined}
                                containerStyle={styles.checkBox}
                                onPress={() => setCheckPolicy(!checkPolicy)}
                            />
                            <Text style={styles.textAgreeCheck}>{translate("IDoAcceptThe")}{translate("policy")}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                {!authenticatedUser?.didAcceptUseTerms ? checkTerms && checkPolicy ?
                    <View style={styles.agreeView}>
                        <TouchableOpacity style={styles.textButtonTouch} onPress={() => save()}>
                            <Text style={styles.textButton}>{translate("Agree")}</Text>
                        </TouchableOpacity>
                    </View>
                    :
                    <View style={styles.agreeView}>
                        <TouchableOpacity style={styles.textButtonTouchDisable} >
                            <Text style={styles.textButtonDisable}>{translate("Agree")}</Text>
                        </TouchableOpacity>
                    </View>
                    : null
                }
            </View>
        </DefaultPageContainer>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#000"
    },
    bodyContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    contentContainer: {
        flex: 1,
        padding: 10,
        justifyContent: 'space-between',
    },
    header: {
        height: 60,
        width: "100%",
        backgroundColor: "#0071bc",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    backArrow: {
        alignSelf: 'flex-start',
    },
    titleHeaderView: {
        justifyContent: 'center'
    },
    titleHeader: {
        alignSelf: 'flex-start',
        fontSize: 20,
        color: '#fff',
        marginLeft: 15,
    },
    checkBox: {
        margin: 0,
        padding: 0,
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textContent: {
        fontSize: 16,
        textAlign: "justify",
        textAlignVertical: "center",
        marginBottom: 20
    },
    textLinks: {
        color: "#0071bc",
        fontWeight: "600"
    },
    termsView: {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        marginBottom: 40
    },
    checksView: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    textAgreeCheck: {
        fontSize: 16,
        textAlignVertical: "center",
        fontWeight: "600",
    },
    agreeView: {
        width: "100%",
        borderTopWidth: 1,
        borderTopColor: "#ddd",
        padding: 10,
        display: 'flex',
        alignItems: 'center',
    },
    textButton: {
        fontSize: 16,
        fontWeight: "600",
        textAlignVertical: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: "#fff",
    },
    textButtonDisable: {
        fontSize: 16,
        fontWeight: "600",
        textAlignVertical: "center",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: "#fff",
    },
    textButtonTouch: {
        paddingVertical: 10,
        paddingHorizontal: 15,
        backgroundColor: "#0071bc",
        borderRadius: 7
    },
    textButtonTouchDisable: {
        paddingVertical: 10,
        paddingHorizontal: 15,
        backgroundColor: "#81898f",
        borderRadius: 7
    }
});

